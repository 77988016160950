import React from 'react';
import {
  Input,
  InputGroup,
  Message,
  Grid,
  Row,
  Col,
  toaster,
  Whisper,
  Popover,
} from 'rsuite';
import { requester } from '../../../../../requesters';
import { withRouter } from 'react-router-dom';
import OperatorsHelper from '../OperatorsHelper';
class SearchBar extends React.Component {
  state = {
    query: '',
    filters: '',
  };

  handleChangeQuery = (s, e) => {
    this.setState({ query: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.doSearch();
    }
  };

  doSearch = () => {
    setTimeout(() => {
      let { query } = this.state;

      if (query === null || query === undefined) {
        query = document.getElementById(this.props.id).value;
        this.setState({ query });
      }

      if (query.length < 3) {
        toaster.push(
          <Message duration={4000} type="warning" showIcon closable>
            Sua busca deve ter pelo menos três caracteres
          </Message>,
        );
      } else if (this.state.query.toLowerCase().includes(' adj ')) {
        toaster.push(
          <Message
            position={'bottom'}
            header="O operador ADJ deve vir acompanhado de um número"
            duration={0}
            type="warning"
            showIcon
            closable
          >
            <div style={{ maxWidth: 320 }}>
              <Grid fluid>
                <Row>
                  <Col xs={24}>
                    <p>
                      <b>Exemplo</b>
                      <br />
                      <i>
                        multa <b>ADJ2</b> ofício
                      </i>{' '}
                      irá retornar os resultados em que{' '}
                      <u> ofício será no máximo a segunda palavra após multa</u>
                      .
                    </p>
                  </Col>
                  <Col xs={24}>
                    <blockquote>
                      [...] não pode ser exigida cumulativamente com a{' '}
                      <mark>multa</mark> de <mark>ofício</mark>, aplicável [...]
                    </blockquote>
                  </Col>
                </Row>
              </Grid>
            </div>
          </Message>,
        );
      } else {
        if (!this.props.filtersProps.filters) {
          this.props.filtersProps.loadFilters();
        } else {
          this.props.filtersProps.saveFilters(
            this.props.history,
            this.props.match,
            encodeURIComponent(this.state.query),
          );
          this.props.filtersProps.setQuery(encodeURIComponent(query));
        }
      }
    }, 100);
  };

  verifyAndGetFilters = () => {
    if (
      this.props.originalFilters &&
      Object.keys(this.props.originalFilters).length === 0
    ) {
      requester.cards
        .getFilters()
        .then((response) => {
          const filters = response;
          let defaultFilters = {};
          let entityFilter = {};
          filters.forEach((filter) => {
            if (filter.field_name === 'entity') {
              defaultFilters.entities = filter.default;
              entityFilter = { ...filter };
            } else {
              defaultFilters[filter.field_name] = filter.default;
            }
          });
          this.props.filtersProps.setOriginalFilters(filters);
          this.props.filtersProps.setDefaultFilters(defaultFilters);
          this.props.filtersProps.loadFilters();
          this.props.filtersProps.restoreOptionalFilters();
          this.setState({ filter: entityFilter });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (this.mounted) {
            this.setState({ requesting: false });
          }
        });
    }
  };

  addOperator = (operator) => {
    let { query } = this.state;
    if (operator === '$') {
      query = `${query}${operator.trim()}`.replace('  ', ' ');
    } else {
      query = `${query} ${operator} `.replace('  ', ' ');
    }

    this.setState({ query });
    document.getElementById('search-bar').focus();
  };

  componentDidMount() {
    this.verifyAndGetFilters();
    this.mounted = true;
    if (this.props.location.search !== '') {
      const params = new URLSearchParams(this.props.location.search);
      const query = params.get('query');
      this.setState({ query });
      this.props.filtersProps.setQuery(query);
    }

    this.props.history.listen((location) => {
      if (this.mounted) {
        if (
          location.pathname.includes('resultados') &&
          location.search !== ''
        ) {
          const params = new URLSearchParams(location.search);
          const query = params.get('query');
          this.setState({ query });
        }
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { style } = this.props;
    const { query } = this.state;
    return (
      <div id="search-bar-container">
        <div
          className="input-field search-input"
          style={{ display: 'flex', ...style, flexDirection: 'column' }}
        >
          <div style={{ display: 'flex' }}>
            <InputGroup
              inside
              id="search-card-query-col-result"
              size="lg"
              className="search-bar-result"
              style={{ width: '100%' }}
            >
              <Whisper
                ref={(ref) => (this.searchHistoryRef = ref)}
                placement="bottomStart"
                trigger="click"
                speaker={
                  <Popover
                    id={'search-history-popover'}
                    style={{
                      width: '75%',
                      height: '110px',
                      paddingBottom: 20,
                    }}
                  >
                    <OperatorsHelper addOperator={this.addOperator} />
                  </Popover>
                }
              >
                <Input
                  className="input-result"
                  type="text"
                  value={query ? query : ''}
                  placeholder="Insira o termo de pesquisa aqui"
                  onChange={this.handleChangeQuery}
                  onKeyDown={this.handleKeyDown}
                />
              </Whisper>
              <InputGroup.Button id="btn-search-result" onClick={this.doSearch}>
                Buscar
              </InputGroup.Button>
            </InputGroup>
            {/* <IconButton style={{ backgroundColor: TuriviusColors.secondary_dark, color: "white" }}
                        onClick={this.doSearch}
                        icon={<FontAwesomeIcon className="rs-icon" icon={faSearch} />}>
                    </IconButton> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchBar);
