import { Skeleton } from '../../../../../../ds-components';

const ResultContainerLoader = () => {
  return (
    <>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
      <div className="tv-card resultcard-loader mt-5">
        <div className="resultcard-loader_header">
          <Skeleton type="square" size="lg" />
          <div>
            <Skeleton type="default" size="lg" />
            <div className="d-flex">
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
              <Skeleton type="default" size="sm" className="mt-3 mr-4" />
            </div>
          </div>
          <Skeleton type="default" size="lg" />
        </div>
        <div className="resultcard-loader_content mt-4">
          <Skeleton size="md" className="title-loader" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
          <Skeleton size="md" className="mt-3" />
        </div>
      </div>
    </>
  );
};

export default ResultContainerLoader;
