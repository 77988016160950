import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  changeInteiroTeor: ['inteiroTeor'],
  changeDefaultOperator: ['defaultOperator'],
  changeSynonyms: ['synonyms'],
  setQuery: ['query'],
  setFilterStr: ['filterStr'],
  toggleExpanded: [],
  setTmpFilters: ['key', 'value'],
  saveFilters: ['history', 'match', 'query'],
  justSaveFilters: ['filterStr'],
  loadFilters: [],
  setDefaultFilters: ['defaultFilters'],
  clearFilters: ['permissions'],
  verifyTmpFiltersEntitiesPermission: ['permissions'],
  restoreOptionalFilters: [],
  setOriginalFilters: ['filters'],
  setEntities: ['entities'],
  setExternalFilter: ['filter', 'value'],
  setEntitiesCounter: ['entities_counter'],
});

const INITIAL_STATE = {
  query: '',
  firstSearch: true,
  expanded: false,
  tmpFilters: {},
  filters: {},
  defaultFilters: {},
  originalFilters: {},
  entities: {},
  filterStr: '',
  inteiroTeor: ['ementa', 'full_text'],
  defaultOperator: 'e',
  synonyms: true,
  externalFilters: {
    filter_by_relator: [],
  },
  entities_counter: {},
};

const changeInteiroTeor = (state = INITIAL_STATE, action) => {
  let inteiroTeor;
  if (typeof action.inteiroTeor === 'string') {
    try {
      inteiroTeor = JSON.parse(action.inteiroTeor);
    } catch (e) {
      inteiroTeor = action.inteiroTeor.split(',');
    }
  } else {
    inteiroTeor = action.inteiroTeor;
  }
  return {
    ...state,
    inteiroTeor,
  };
};

const changeDefaultOperator = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    defaultOperator: action.defaultOperator,
  };
};

const changeSynonyms = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    synonyms: action.synonyms,
  };
};

const setEntities = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    entities: { ...action.entities },
  };
};

const setQuery = (state = INITIAL_STATE, action) => {
  const searchbar = document.querySelectorAll('#search-bar')[0];
  if (searchbar && searchbar.classList) {
    searchbar.classList.remove('invalid');
  }
  return {
    ...state,
    query: action.query,
  };
};

const setFilterStr = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    filterStr: action.filterStr,
  };
};

const toggleExpanded = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    expanded: !state.expanded,
  };
};

const setTmpFilters = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    tmpFilters: {
      ...state.tmpFilters,
      [action.key]: action.value,
    },
  };
};

const setOriginalFilters = (state = INITIAL_STATE, action) => {
  let originalFilters = {};
  action.filters.forEach((filter) => {
    if (filter.field_name === 'entity') {
      originalFilters.entities = filter;
    } else {
      originalFilters[filter.field_name] = filter;
    }
  });
  return {
    ...state,
    originalFilters,
  };
};

const setDefaultFilters = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    defaultFilters: action.defaultFilters,
  };
};

const loadFilters = (state = INITIAL_STATE, action) => {
  const filters = JSON.parse(localStorage.getItem('filters'));
  let filtersToLoad = {};
  if (filters) {
    filtersToLoad = { ...filters };
  }
  Object.keys(state.defaultFilters).forEach((key) => {
    if (!filtersToLoad[key]) {
      filtersToLoad[key] = state.defaultFilters[key];
    }
  });
  return {
    ...state,
    filters: { ...filtersToLoad },
    tmpFilters: { ...filtersToLoad },
  };
};

const restoreOptionalFilters = (state = INITIAL_STATE, action) => {
  let newFilters = {};
  Object.keys(state.defaultFilters).forEach((key) => {
    if (!newFilters[key] && !(key === 'entity' || key === 'entities')) {
      newFilters[key] = state.defaultFilters[key];
    }
  });
  newFilters = {
    ...state.filters,
    ...newFilters,
  };
  localStorage.setItem('filters', JSON.stringify(newFilters));
  return {
    ...state,
    filters: { ...newFilters },
    tmpFilters: { ...newFilters },
    filterStr: buildFilterUrl(newFilters),
    entities_counter: {},
    inteiroTeor: ['ementa', 'full_text'],
    defaultOperator: 'e',
    synonyms: true,
  };
};

const clearFilters = (state = INITIAL_STATE, action) => {
  let newFilters = {};
  Object.keys(state.defaultFilters).forEach((key) => {
    if (!newFilters[key]) {
      newFilters[key] = state.defaultFilters[key];
    }
  });
  return {
    ...state,
    filters: { ...newFilters },
    tmpFilters: { ...newFilters },
    inteiroTeor: ['ementa', 'full_text'],
    defaultOperator: 'e',
    synonyms: true,
  };
};

const buildFilterUrl = (reduxFilters) => {
  const filters = {};
  Object.keys(reduxFilters).forEach((filterKey) => {
    if (filterKey === 'entities') {
      filters.entity = reduxFilters[filterKey]?.map((rFilter) =>
        typeof rFilter === 'object' ? rFilter.id : rFilter,
      );
    } else {
      if (reduxFilters[filterKey]) {
        filters[filterKey] =
          typeof reduxFilters[filterKey] === 'object'
            ? Array.isArray(reduxFilters[filterKey])
              ? reduxFilters[filterKey]?.map((rFilter) =>
                  typeof rFilter === 'object' ? rFilter.id : rFilter,
                )
              : reduxFilters[filterKey]
            : parseInt(reduxFilters[filterKey]);
      }
    }
  });
  return JSON.stringify(filters);
};

const justSaveFilters = (state = INITIAL_STATE, action) => {
  let filters = {
    ...JSON.parse(action.filterStr),
    entities: JSON.parse(action.filterStr).entity,
  };
  delete filters.entity;

  localStorage.setItem('filters', JSON.stringify(filters));
  return {
    ...state,
    filters: {
      ...filters,
    },
    tmpFilters: {
      ...filters,
    },
    filterStr: buildFilterUrl(filters),
  };
};

const saveFilters = (state = INITIAL_STATE, action) => {
  localStorage.setItem('filters', JSON.stringify(state.tmpFilters));
  const filtersString = buildFilterUrl(state.tmpFilters);

  if (action.history) {
    setTimeout(() => {
      const inteiroTeor = state.inteiroTeor
        ? typeof state.inteiroTeor === 'string'
          ? state.inteiroTeor
          : JSON.stringify(state.inteiroTeor)
        : null;
      const defaultOperator = state.defaultOperator;
      const synonyms = state.synonyms;

      if (action.query) {
        const urlString = `/pesquisa/resultados?query=${action.query}${
          inteiroTeor ? `&fields=${inteiroTeor}` : ''
        }${defaultOperator ? `&default_operator=${defaultOperator}` : ''}${
          synonyms !== null ? `&synonyms=${synonyms}` : ''
        }${
          filtersString !== '' && filtersString
            ? `&filters=${filtersString}`
            : ''
        }`;
        action.history.push(urlString);
        localStorage.setItem('lastSearch', urlString);
      } else {
        const urlString = `/pesquisa/resultados?query=${state.query}${
          inteiroTeor ? `&fields=${inteiroTeor}` : ''
        }${defaultOperator ? `&default_operator=${defaultOperator}` : ''}${
          synonyms !== null ? `&synonyms=${synonyms}` : ''
        }${
          filtersString !== '' && filtersString
            ? `&filters=${filtersString}`
            : ''
        }`;
        action.history.push(urlString);
        localStorage.setItem('lastSearch', urlString);
      }
    }, 100);
  }
  return {
    ...state,
    filters: {
      ...state.tmpFilters,
    },
    filterStr: filtersString,
  };
};

const setExternalFilter = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    externalFilters: {
      ...state.externalFilters,
      [action.filter]: action.value,
    },
  };
};

const setEntitiesCounter = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    entities_counter: {
      ...action.entities_counter,
    },
  };
};

const verifyTmpFiltersEntitiesPermission = (state = INITIAL_STATE, action) => {
  let { tmpFilters } = state;

  return {
    ...state,
    tmpFilters,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_INTEIRO_TEOR]: changeInteiroTeor,
  [Types.CHANGE_DEFAULT_OPERATOR]: changeDefaultOperator,
  [Types.CHANGE_SYNONYMS]: changeSynonyms,
  [Types.SET_QUERY]: setQuery,

  [Types.SET_FILTER_STR]: setFilterStr,
  [Types.TOGGLE_EXPANDED]: toggleExpanded,
  [Types.SET_TMP_FILTERS]: setTmpFilters,
  [Types.SAVE_FILTERS]: saveFilters,
  [Types.JUST_SAVE_FILTERS]: justSaveFilters,
  [Types.LOAD_FILTERS]: loadFilters,
  [Types.SET_DEFAULT_FILTERS]: setDefaultFilters,
  [Types.SET_ORIGINAL_FILTERS]: setOriginalFilters,
  [Types.CLEAR_FILTERS]: clearFilters,
  [Types.RESTORE_OPTIONAL_FILTERS]: restoreOptionalFilters,
  [Types.SET_ENTITIES]: setEntities,
  [Types.SET_EXTERNAL_FILTER]: setExternalFilter,
  [Types.SET_ENTITIES_COUNTER]: setEntitiesCounter,
  [Types.VERIFY_TMP_FILTERS_ENTITIES_PERMISSION]:
    verifyTmpFiltersEntitiesPermission,
});
