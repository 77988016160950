import { useEffect, useState } from 'react';
import { Col, Grid, Input, InputGroup, Row } from 'rsuite';
import {
  SearchNormal1,
  AddCircle,
  CloseCircle,
  Trash,
  Warning2,
} from 'iconsax-react';
import TuriviusPreloader from '../../../../../app/TuriviusPreloader';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import './style.scss';

const SearchTag = ({
  card,
  collectionId,
  reloadTag,
  createdTag,
  setTagName,
  onDeleteTag,
  loadingDelete,
}) => {
  const [tags, setTags] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [loadButton, setLoadButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existTag, setExistTag] = useState(false);
  const [searchTag, setSearchTag] = useState('');
  const [information, setInformation] = useState(false);
  const [savedTag, setSavedTag] = useState(false);

  const getTags = () => {
    const tagsFilter = [];
    let existCard = null;
    setLoading(true);

    requester.collections
      .getTagsByCollection(collectionId)
      .then((response) => {
        Object.keys(response)?.map((tag) => {
          existCard = response[tag].cards.includes(card.id);
          if (existCard) {
            setExistTag(true);
          }

          tagsFilter.push({
            id: response[tag].id,
            title: response[tag].title,
            active: existCard,
            appearance: existCard ? 'danger' : 'secondary',
            icon: existCard ? <CloseCircle /> : <AddCircle />,
          });
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTags(tagsFilter);
        setFilterTags(tagsFilter);
        setLoadButton(false);
        setLoading(false);
      });
  };

  function tagAction(tag) {
    setLoadButton(true);

    if (tag.active) {
      setExistTag(false);
      requester.collections
        .removeCardsToTag(tag.id, [card.id])
        .then((response) => {
          getTags();
          setTagName(null);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          reloadTag();
        });
    } else {
      requester.collections
        .addCardsToTag(tag.id, [card.id])
        .then((response) => {
          getTags();
          setSavedTag(true);
          setTimeout(() => {
            setSavedTag(false);
          }, 5000);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadButton(false);
          reloadTag();
        });
    }
  }

  function searchingTag(value) {
    setFilterTags(
      tags.filter((tag) => tag.title.toLowerCase().includes(value)),
    );
  }

  function onTryAction() {
    setInformation(true);

    setTimeout(() => {
      setInformation(false);
    }, 10000);
  }

  useEffect(() => {
    getTags();
  }, []);

  return (
    <div className="search-tag-container">
      <InputGroup inside>
        <InputGroup.Addon>
          <SearchNormal1 size="15" color="#98A2B3" />
        </InputGroup.Addon>
        <Input
          placeholder="Buscar marcador por nome"
          className="search-tag-container_search-input"
          value={searchTag}
          onChange={(value) => {
            searchingTag(value);
            setSearchTag(value);
          }}
        />
      </InputGroup>
      <div
        className={`search-tag-container_tags-list ${
          tags.length > 0 ? null : 'empty'
        }`}
      >
        {loading ? (
          <Grid fluid>
            <Col xs={24} sm={24} style={{ height: 50 }}>
              <TuriviusPreloader />
            </Col>
          </Grid>
        ) : tags.length > 0 ? (
          filterTags?.map((tag) => (
            <Grid
              fluid
              className="search-tag-container_tags-list_tag-container"
            >
              <Row gutter={5}>
                <Col sm={14}>
                  <p>{tag.title}</p>
                </Col>
                <Col sm={3}>
                  <Button
                    size="sm"
                    appearance="minimal"
                    disabled={tag.active}
                    onClick={
                      tag.active ? () => {} : () => onDeleteTag(tag.id, getTags)
                    }
                  >
                    {loadingDelete ? (
                      <SpinningLoader color="gray" size="sm" />
                    ) : (
                      <Trash color="#FDA29B" />
                    )}
                  </Button>
                </Col>
                <Col sm={7}>
                  <Button
                    fullWidth
                    onClick={
                      tag.active === false && existTag
                        ? () => onTryAction()
                        : () => tagAction(tag)
                    }
                    appearance={tag.appearance}
                    icon={tag.icon}
                    disabled={tag.active === false && existTag}
                    size="sm"
                  >
                    {loadButton ? (
                      <SpinningLoader color="gray" size="sm" />
                    ) : tag.active ? (
                      'Remover'
                    ) : (
                      'Adicionar'
                    )}
                  </Button>
                </Col>
              </Row>
            </Grid>
          ))
        ) : (
          <div className="search-tag-container_empty">
            <Warning2 size="32" color="#FDB022" variant="Outline" />
            <h6>Você não tem um marcador criado</h6>
          </div>
        )}
      </div>

      <div className="search-tag-container_informations">
        {information ? (
          <span className="info danger">
            Você pode adicionar apenas um marcador para cada decisão
          </span>
        ) : null}

        {createdTag ? (
          <span className="info success">Marcador criado com sucesso</span>
        ) : null}
        {savedTag ? (
          <span className="info success">Marcador salvo com sucesso</span>
        ) : null}
      </div>
    </div>
  );
};

export default SearchTag;
