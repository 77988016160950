import React from 'react';
import { Button, MaskedInput, InputGroup } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';

class DateFilter extends React.Component {
  state = {
    date_inicial: null,
    date_final: null,
    error_initial: false,
    error_final: false,
    reloadingEnd: false,
    reloadingBegin: false,
  };

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  handleChangeSelectionBegin = (value) => {
    const { filter, filters } = this.props;
    const dateInput = new Date(this.getMMDDYYYYDate(value));

    if (!this.isValidDate(dateInput)) {
      this.setState({ error_initial: true });
    } else {
      this.setState({ error_initial: false });
    }
    this.setState({ date_inicial: value });
    this.props.setTmpFilters(filter.field_name, {
      ...filters[filter.field_name],
      0: value,
    });
  };

  handleChangeSelectionEnd = (value) => {
    const { filter, filters } = this.props;
    const dateInput = new Date(this.getMMDDYYYYDate(value));

    if (!this.isValidDate(dateInput)) {
      this.setState({ error_final: true });
    } else {
      this.setState({ error_final: false });
    }
    this.setState({ date_final: value });
    this.props.setTmpFilters(filter.field_name, {
      ...filters[filter.field_name],
      1: value,
    });
  };

  handleSelectionEndBlur = (e) => {
    const { filter, filters } = this.props;
    const dateStringInput = e.target.value;
    const dateInput = new Date(this.getMMDDYYYYDate(dateStringInput));

    if (this.isValidDate(dateInput)) {
      if (
        dateInput <
        new Date(this.getMMDDYYYYDate(filters[filter.field_name]['0']))
      ) {
        this.props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: dateStringInput,
          1: filters[filter.field_name]['0'],
        });
      } else {
        this.props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          1: dateStringInput,
        });
      }

      if (this.props.context) {
        posthog.capture(this.props.context, {
          action: 'change_filter',
          id: filter.field_name,
        });
        postHubSpotEvent('app_changefilters', {
          app_changefilters: this.props.context,
        });
      }
    } else {
      this.setState({
        error_initial: true,
        error_final: true,
      });
    }
  };

  handleSelectionBeginBlur = (e) => {
    const { filter, filters } = this.props;
    const dateStringInput = e.target.value;
    const dateInput = new Date(this.getMMDDYYYYDate(dateStringInput));

    if (this.isValidDate(dateInput)) {
      if (
        dateInput >
        new Date(this.getMMDDYYYYDate(filters[filter.field_name]['1']))
      ) {
        this.props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: filters[filter.field_name]['1'],
          1: dateStringInput,
        });
      } else {
        this.props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: dateStringInput,
        });
      }
      if (this.props.context) {
        posthog.capture(this.props.context, {
          action: 'change_filter',
          id: filter.field_name,
        });
        postHubSpotEvent('app_changefilters', {
          app_changefilters: this.props.context,
        });
      }
    } else {
      this.setState({
        error_initial: true,
      });
      setTimeout(() => {
        this.setState({
          error_initial: false,
        });
      }, 200);
    }
  };

  getMMDDYYYYDate(str) {
    const date = str.split('/');
    return `${date[1]}/${date[0]}/${date[2]}`;
  }

  cleanDates() {
    const { filters, filter } = this.props;
    this.setState({
      date_inicial: null,
      date_final: null,
      error_initial: true,
      error_final: true,
    });
  }

  componentDidMount() {
    const { filters, filter } = this.props;
    this.setState({
      date_inicial: filters[filter.field_name]['0'],
      date_final: filters[filter.field_name]['1'],
    });
  }

  render() {
    const { error_initial, error_final, date_inicial, date_final } = this.state;
    const { filters, filter, label } = this.props;

    return filters && filters[filter.field_name] ? (
      <div className="single-filter-box without-border" id="filter-date">
        <div className="single-filter-box_container-title">
          <span className="single-filter-box_title">{label}</span>
          <Button size="xs" appearance="link" onClick={() => this.cleanDates()}>
            Limpar
          </Button>
        </div>

        <InputGroup style={{ marginTop: 12 }}>
          <MaskedInput
            id="initial-date-filter"
            className={error_initial ? 'rs-custom-input-error' : ''}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            guide={true}
            showMask={true}
            keepCharPositions={false}
            placeholder={'__/__/____'}
            value={date_inicial}
            onChange={this.handleChangeSelectionBegin}
            onBlur={this.handleSelectionBeginBlur}
          />
          <InputGroup.Addon>até</InputGroup.Addon>
          <MaskedInput
            id="final-date-filter"
            className={error_final ? 'rs-custom-input-error' : ''}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            guide={true}
            showMask={true}
            keepCharPositions={false}
            placeholder={'__/__/____'}
            value={date_final}
            onChange={this.handleChangeSelectionEnd}
            onBlur={this.handleSelectionEndBlur}
          />
        </InputGroup>
      </div>
    ) : null;
  }
}

export default DateFilter;
