import { useState } from 'react';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { ArrowLeft2, CloseCircle, Minus } from 'iconsax-react';
import { Message, toaster } from 'rsuite';
import { requester } from '../../../../../requesters';
import { useCollections } from '../../../../../store/ducks/theCollections';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from '../../../../../components/ui/dialog';
import { toast } from 'react-toastify';

const RemoveCardFromCollectionButton = ({ cards, context, collectionId }) => {
  const user = localStorage.getItem('user');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { fetch } = useCollections();

  const handleModal = () => setModal(!modal);

  const removeCardFromCollectionHandler = () => {
    setLoading(true);

    requester.collections
      .removeCardsFromCollection(
        user,
        collectionId,
        cards?.map((card) => card.id),
        context,
      )
      .then((response) => {
        fetch();
        toast.error(
          <div className="flex items-center justify-between">
            <span className="text-base leading-7 text-[#475467]">
              Decisão removida
            </span>
          </div>,
          { duration: 4000 },
        );

        setModal(false);
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message header="Ocorreu um erro" type="error" showIcon closable>
            Houve um problema de conexão, tente novamente
          </Message>,
          { duration: 4000 },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog className="tv-modal-default" open={modal} onClose={handleModal}>
      <DialogTrigger>
        <Button appearance="danger" onClick={handleModal}>
          {' '}
          Remover{' '}
        </Button>
      </DialogTrigger>
      <DialogContent className="translate-y-0 top-6">
        <DialogHeader closeButton={false}>
          <div style={{ textAlign: 'end' }}>
            <CloseCircle
              size="24"
              color="#98A2B3"
              onClick={handleModal}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <h6 className="tv-modal-default_header_title">
            Remover decisão da Coleção
          </h6>
          {cards.length === 1 ? (
            <p className="tv-modal-default_header_subtitle">
              Tem certeza que deseja remover a decisão <b>{cards[0]?.name}</b>{' '}
              da coleção? Essa ação não poderá ser desfeita.
            </p>
          ) : (
            <p className="tv-modal-default_header_subtitle">
              Tem certeza que deseja remover as decisões da coleção? Essa ação
              não poderá ser desfeita.
            </p>
          )}
        </DialogHeader>
        <DialogFooter>
          <Button
            size="sm"
            fullWidth
            icon={<ArrowLeft2 />}
            appearance="secondary"
            onClick={handleModal}
          >
            Cancelar
          </Button>
          <Button
            size="sm"
            fullWidth
            icon={<Minus />}
            onClick={removeCardFromCollectionHandler}
          >
            {loading ? (
              <SpinningLoader color="white" size="sm" />
            ) : (
              'Remover da coleção'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveCardFromCollectionButton;
