import { useState, useEffect } from 'react';
import { Modal, Message, toaster, CheckPicker, Avatar } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requester } from '../../../../../requesters';
import { Turivius } from '../../../../../ui';
import { Creators as ProfilesActions } from '../../../../../store/ducks/profiles';
import vars from '../../../../../vars.module.scss';
import './ManagePeopleCollectionButton.scss';
import { Add, ArrowLeft2, CloseCircle, Share } from 'iconsax-react';
import { Button } from '../../../../../ds-components';

const ManagePeopleCollectionButton = ({
  small,
  companiesProfiles,
  fetchingCompaniesProfiles,
  toggleFetchingCompanyProfiles,
  setCompanyProfiles,
  sharedWith,
  context,
  collectionId,
  onSearch,
}) => {
  const [state, setState] = useState({
    received: false,
    items: null,
    selectedItems: null,
    newSelectedItems: [],
    open: false,
  });

  const { received, items, selectedItems, newSelectedItems, open } = state;

  useEffect(() => {
    verifyAndGetCompanyProfiles();
  }, []);

  const verifyAndGetCompanyProfiles = () => {
    const company = localStorage.getItem('company');
    if (!companiesProfiles[company] && !fetchingCompaniesProfiles[company]) {
      toggleFetchingCompanyProfiles({ [company]: true });
      requester.profiles
        .getCompanyUserNames(company, '')
        .then((response) => {
          setCompanyProfiles({ [company]: response });
          response = [
            ...response?.map((profile) => ({
              ...profile,
              photo: profile.photo
                ? `data:image/*;base64, ${profile.photo}`
                : null,
            })),
          ];
          setState((prevState) => ({
            ...prevState,
            items: [...response],
            received: true,
          }));
          if (state.selectedItems === null) {
            const sharedWithIds = [
              ...sharedWith
                .filter((user) => user.id !== '' && user.id !== undefined)
                ?.map((user) => parseInt(user.id)),
            ];
            setState((prevState) => ({
              ...prevState,
              selectedItems: sharedWithIds,
              newSelectedItems: sharedWithIds,
            }));
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => toggleFetchingCompanyProfiles({ [company]: false }));
    }
  };

  const updateSharing = () => {
    let { newSelectedItems } = state;
    const { selectedItems } = state;

    const remove = selectedItems
      .filter((si) => newSelectedItems.filter((nsi) => si === nsi).length === 0)
      ?.map((nsi) => nsi);

    const add = newSelectedItems
      .filter((nsi) => selectedItems.filter((si) => si === nsi).length === 0)
      ?.map((nsi) => nsi);

    toaster.push(
      <Message duration={4000} type="info">
        Atualizando opções de compartilhamento...
      </Message>,
    );

    if (remove.length > 0) {
      requester.collections
        .removeUsersFromCollections(
          localStorage.getItem('user'),
          collectionId,
          remove,
          context,
        )
        .then((response) => {
          if (context === 'Collections') {
            onSearch();
          }
          toaster.push(
            <Message
              header="Dados removidos"
              duration={4000}
              type="success"
              showIcon
              closable
            >
              Usuários removidos da coleção!
            </Message>,
          );
        })
        .catch((err) => {
          console.error(err);
          toaster.push(
            <Message
              header="Ocorreu um erro"
              duration={4000}
              type="error"
              showIcon
              closable
            >
              Houve um problema de conexão, tente novamente
            </Message>,
          );
        });
    }

    if (add.length > 0) {
      requester.collections
        .addUsersToCollection(
          localStorage.getItem('user'),
          collectionId,
          add,
          context,
        )
        .then((response) => {
          if (context === 'Collections') {
            onSearch();
          }
          toaster.push(
            <Message
              header="Dados atualizados"
              duration={4000}
              type="success"
              showIcon
              closable
            >
              Usuários adicionados à coleção!
            </Message>,
          );
        })
        .catch((err) => {
          console.error(err);
          toaster.push(
            <Message
              header="Ocorreu um erro"
              duration={4000}
              type="error"
              showIcon
              closable
            >
              Houve um problema de conexão, tente novamente
            </Message>,
          );
        });
    }
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleOpen = () => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        open: true,
      }));
    }, 100);
  };

  useEffect(() => {
    const company = localStorage.getItem('company');
    if (companiesProfiles[company] && companiesProfiles[company].length > 0) {
      const sharedWithIds = sharedWith
        .filter((user) => user.id !== '' && user.id !== undefined)
        .map((user) => parseInt(user.id));
      setState((prevState) => ({
        ...prevState,
        selectedItems: sharedWithIds,
        newSelectedItems: sharedWithIds,
      }));
    }
  }, [companiesProfiles, sharedWith]);

  useEffect(() => {
    if (!received || selectedItems?.length !== sharedWith?.length) {
      const company = localStorage.getItem('company');
      if (
        companiesProfiles &&
        companiesProfiles[company] &&
        companiesProfiles[company].length > 0
      ) {
        if (items === null) {
          setState((prevState) => ({
            ...prevState,
            items: companiesProfiles[company].map((profile) => ({
              ...profile,
              photo: profile.photo
                ? `data:image/*;base64, ${profile.photo}`
                : null,
            })),
            received: true,
          }));
        }

        if (
          selectedItems === null ||
          selectedItems.length !==
            sharedWith.filter((sw) => sw.id !== '').length
        ) {
          const sharedWithIds = sharedWith
            .filter((user) => user.id !== '' && user.id !== undefined)
            .map((user) => parseInt(user.id));
          setState((prevState) => ({
            ...prevState,
            selectedItems: sharedWithIds,
            newSelectedItems: sharedWithIds,
          }));
        }
      }
    }
  }, [received, items, selectedItems, companiesProfiles, sharedWith]);

  return (
    <>
      <Button
        fullWidth
        size="sm"
        appearance="secondary"
        icon={<Share size={20} />}
        onClick={handleOpen}
      >
        {small ? '' : 'Compartilhar'}
      </Button>

      <Modal className="tv-modal-default" open={open} onClose={handleClose}>
        <Modal.Header closeButton={false} className="tv-modal-default_header">
          <div style={{ textAlign: 'end' }}>
            <CloseCircle
              size="24"
              color="#98A2B3"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <h6 className="tv-modal-default_header_title">
            Gerenciar Compartilhamento
          </h6>
        </Modal.Header>
        <Modal.Body className="tv-modal-default_body">
          <label>Selecione usuários para compartilhar a coleção</label>
          <CheckPicker
            data={
              items?.map((i) => ({ label: i.name, value: i.id, ...i })) || []
            }
            value={newSelectedItems}
            onChange={(newSelectedItems) => {
              setState((prevState) => ({
                ...prevState,
                newSelectedItems,
              }));
            }}
            placeholder="Selecione pessoas para compartilhar"
            block
            cleanable={false}
            renderMenuItem={(label, item) => {
              return (
                <div>
                  {item.photo ? (
                    <Avatar circle size="xs" src={`${item.photo}`} />
                  ) : (
                    <Avatar circle size="xs">
                      <FontAwesomeIcon
                        className="rs-icon"
                        icon={faUser}
                        style={{ color: vars.turivius }}
                      />
                    </Avatar>
                  )}{' '}
                  {label}
                </div>
              );
            }}
            renderMenu={(menu) => {
              if (items.length === 0) {
                return (
                  <p
                    style={{
                      padding: 4,
                      color: '#999',
                      textAlign: 'center',
                    }}
                  >
                    <Turivius.SimplePreloader /> &nbsp; Carregando...
                  </p>
                );
              }
              return menu;
            }}
          />
        </Modal.Body>
        <Modal.Footer className="tv-modal-default_footer">
          <Button
            size="sm"
            fullWidth
            icon={<ArrowLeft2 />}
            appearance="secondary"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size="sm" fullWidth={<Add />} onClick={updateSharing}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProfilesActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ManagePeopleCollectionButton));
