import React from 'react';
import { Breadcrumb, Panel, Message, toaster, Grid, Row, Col } from 'rsuite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { requester } from '../../../../requesters';
import { Turivius } from '../../../../ui';
import InfiniteScroll from 'react-infinite-scroller';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

//Redux
import { Creators as JurimetricsActions } from '../../../../store/ducks/jurimetrics';
import { Creators as CollectionsActions } from '../../../../store/ducks/collections';
import { Creators as ResultsActions } from '../../../../store/ducks/searchTributario';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';

//Components

import { SingleCardSkeleton } from '../../../../components/Skeleton';
import ExpandAllCardsButton from '../../components/results/Buttons/ExpandAllCardsButton';
import TuriviusPreloader from '../../../../app/TuriviusPreloader';
import ResultCard from '../../components/results/Cards/Result/ResultCard';

//Settings
// import "../../styles/Results.scss";

class ConsultCard extends React.Component {
  constructor(props) {
    super(props);
    this.resultsSection = React.createRef();
    this.loadingMore = false;
  }

  state = {
    requesting: true,
    hasMore: true,
    results: [],
    page: 0,
    data: {},
    anchorEl: null,
    votes: {},
  };

  verifyAndGetEntities() {
    let { entities } = this.props;
    if (Object.keys(entities).length === 0 && entities.constructor === Object) {
      requester.cards
        .getEntities()
        .then((response) => {
          entities = {};
          response.forEach((entity) => {
            entities[entity.id] = entity;
          });
          this.props.setEntities(entities);
        })
        .catch((err) => {
          console.error(err);
          toaster.push(
            <Message
              header="Ocorreu um erro"
              duration={4000}
              type="error"
              showIcon
              closable
            >
              Houve um problema de conexão, tente novamente
            </Message>,
          );
        });
    }
  }

  getBoardsPage = async (page) => {
    return requester.collections.getUserCollections(
      localStorage.getItem('user'),
      page,
      true,
    );
  };

  verifyAndGetCollections = async () => {
    if (!this.props.userCollections) {
      this.props.setRequestingUserCollections(true);

      let page = 1;
      let userCollections = {};
      let sharedCollections = {};
      let companyCollections = {};

      let user_boards = {};
      let shared_w_boards = {};
      let public_to_company_boards = {};

      do {
        let collections = await this.getBoardsPage(page);
        user_boards = collections.user_boards;
        shared_w_boards = collections.shared_w_boards;
        public_to_company_boards = collections.public_to_company_boards;

        userCollections[page] = user_boards.boards;
        sharedCollections[page] = shared_w_boards.boards;
        companyCollections[page] = public_to_company_boards.boards;

        page += 1;
      } while (
        user_boards.boards.length === 12 ||
        shared_w_boards.boards.length === 12 ||
        public_to_company_boards.boards.length === 12
      );

      let collections = [
        ...Object.values(userCollections).reduce(
          (acc, v) => [...acc, ...v],
          [],
        ),
        ...Object.values(sharedCollections).reduce(
          (acc, v) => [...acc, ...v],
          [],
        ),
        ...Object.values(companyCollections).reduce(
          (acc, v) => [...acc, ...v],
          [],
        ),
      ];
      collections = collections.filter(
        (collection, index, self) =>
          index === self.findIndex((c) => c.id === collection.id),
      );
      this.props.setUserCollections(collections);
      this.props.setRequestingUserCollections(false);
    }
  };

  getUserCollections() {
    requester.collections
      .getUserCollections(localStorage.getItem('user'))
      .then((response) =>
        this.props.setUserCollections([
          ...response.user_boards,
          ...response.shared_w_boards,
          ...response.public_to_company_boards,
        ]),
      )
      .catch((err) => console.error(err));
  }

  getVotes = (ids) => {
    requester.cards
      .getManyVotes(ids)
      .then((response) => {
        let votes = { ...this.state.votes };
        ids.forEach((id) => {
          votes[id] = null;
        });
        response.forEach((vote) => {
          votes[vote.card_id] = vote;
        });
        this.setState({ votes });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getVote = (cardId) => {
    requester.cards
      .getVotes(cardId)
      .then((response) => {
        const votes = { ...this.state.votes, [cardId]: response };
        this.setState({ votes });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getResults(cardIds) {
    this.setState({ requesting: true });
    window.scrollTo({ top: 0, behavior: 'smooth' });

    requester.cards
      .getCards(cardIds)
      .then((response) => {
        this.getVotes(cardIds);
        this.setState({ results: response.cards });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ results: [] });
      })
      .finally(() => {
        this.setState({ requesting: false });
      });
  }

  doSearch(location) {
    const cardId = location.pathname.split('/').pop();
    if (cardId === '') {
      const params = new URLSearchParams(location.search);
      const ids = JSON.parse(params.get('ids'));
      this.getResults(ids);
      this.verifyAndGetEntities();
    } else {
      this.getResults([cardId]);
      this.verifyAndGetEntities();
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    this.verifyAndGetCollections();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.doSearch(this.props.history.location);
  }

  render() {
    const { requesting, results, hasMore, anchorEl, votes } = this.state;
    const { entities } = this.props;
    const open = Boolean(anchorEl);
    return (
      <Turivius.TuriviusBasePageContent
        header={
          <Panel>
            <Grid fluid>
              <Row style={{ marginBottom: 0 }}>
                {window.detectMob() ? (
                  <>
                    <Col xs={20} sm={20}>
                      <h4>Busca Jurisprudencial</h4>
                      {/* <p>Termo: {query}</p> */}
                    </Col>
                    <Col xs={4} sm={4}>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        style={{ margin: 'auto', color: 'white' }}
                        onClick={this.handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        elevation={0}
                        getContentAnchorEl={null}
                        onClose={this.handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <MenuItem>
                          <ExpandAllCardsButton />
                        </MenuItem>
                      </Menu>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={16} sm={16}>
                      <h4>Busca Jurisprudencial</h4>
                      {/* <p>Termo: {query}</p> */}
                      {/* <p>{query ? `Termo: ${query}` : n_acordao ? `Nº do acórdão: ${n_acordao}` : n_processo ? `Nº do processo: ${n_processo}` : relator_ids?.length > 0 ? `Relatores: ${relator_ids.join(', ')}` : null}</p> */}
                    </Col>
                    <Col xs={8} sm={8}>
                      <ExpandAllCardsButton />
                    </Col>
                  </>
                )}
              </Row>
            </Grid>
          </Panel>
        }
        content={
          <section className="results-section" id="results-page-section">
            <div className="results-container">
              <div className="results-body">
                <Breadcrumb style={{ marginBottom: 0, marginLeft: 10 }}>
                  <Breadcrumb.Item onClick={() => this.props.history.push('/')}>
                    Jurisprudência
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Busca por ID do card</Breadcrumb.Item>
                </Breadcrumb>
                <Grid fluid>
                  <Row style={{ margin: 0 }}>
                    {requesting ? (
                      <SingleCardSkeleton />
                    ) : (
                      <>
                        <Col sm={results.length > 0 ? 24 : 16}>
                          <div ref={this.resultsSection}></div>
                          {results.length > 0 ? (
                            <InfiniteScroll
                              pageStart={0}
                              hasMore={hasMore}
                              threshold={1200}
                              useWindow={false}
                              getScrollParent={() =>
                                this.resultsSection.current
                              }
                              loader={<div></div>}
                            >
                              {results?.map((card) => {
                                return (
                                  <ResultCard
                                    key={card.id}
                                    card={card}
                                    entity={entities[card.entity]}
                                    context={'Results'}
                                    vote
                                    voteValue={votes[card.id]}
                                    getVote={this.getVote}
                                    noJurimetrics
                                    placementSave="bottomStart"
                                  />
                                );
                              })}
                            </InfiniteScroll>
                          ) : (
                            <div className="flex flex-col justify-center items-center">
                              <p>Esta busca não obteve resultados</p>
                              <p style={{ fontWeight: 700 }}>
                                Tente modificar os filtros ou refazer a busca
                                com termos mais amplos!
                              </p>
                              <img
                                alt="Sem resultados"
                                src="/no-results.png"
                                style={{
                                  width: 150,
                                  marginTop: 30,
                                  marginBottom: 30,
                                }}
                              />
                              <div
                                style={{
                                  border: '1px solid #666',
                                  padding: 20,
                                  cursor: 'pointer',
                                  margin: 'auto',
                                  width: 'fit-content',
                                  borderRadius: 5,
                                }}
                              >
                                <p>
                                  <b>
                                    Essa busca não retornou o resultado esperado{' '}
                                  </b>
                                </p>
                              </div>
                            </div>
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                </Grid>
              </div>
              {requesting ? <TuriviusPreloader backdrop /> : null}
            </div>
          </section>
        }
        contentId={'results-page-content'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  entities: state.searchTributario.entities,
  userCollections: state.collections.userCollections,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign(
      {},
      JurimetricsActions,
      CollectionsActions,
      ResultsActions,
      ProfilesActions,
    ),
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ConsultCard));
