import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { routes } from '../../config/routes-breadcrumb';
import { ArrowRight2, Home2 } from 'iconsax-react';
import ProfileImageButton from './UserMenu';
import KnowledgeHubButton from '../KnowledgeHub/KnowledgeHubButton';
import AccessValidity from './AccessValidity';
import './style.scss';

const FlagSystem = ({
  openSuccessChecklist,
  titleBreadCrumb,
  additionalRoute,
  segment,
  current,
  ...props
}) => {
  const link = process.env.REACT_APP_HIRE_TOOL;
  const [isMob, setIsMob] = useState(null);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [profile, setProfile] = useState({});

  // onScroll = () => {
  //     const scrollTop = document.getElementsByClassName('container-page')[0].scrollTop;
  //     const labelComponent = document.getElementsByClassName('flag-segment')[0];

  //     if (scrollTop <= 50) {
  //         labelComponent.classList.remove("hidden");
  //     } else if (scrollTop > 30) {
  //         labelComponent.classList.add("hidden");
  //     }
  // }

  // componentDidMount() {
  //     document.getElementsByClassName('container-page')[0].addEventListener('scroll', this.onScroll)
  // }

  const handleResize = () => {
    setIsMob(window.detectMob());
  };

  useEffect(() => {
    const profileT = JSON.parse(localStorage.getItem('profile'));

    setProfile(profileT);
    setBreadCrumb(routes.filter((a) => a.nameRoute === current)[0]);
    // getProfilePhoto();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [current]);

  return (
    <div className={`flag-segment ${isMob ? 'mobile' : null}`}>
      <div className="breadcrumb">
        <div className="breadcrumb_routes">
          <Home2 size="20" color="#667085" />

          <ArrowRight2 size="20" color="#667085" />

          {breadCrumb?.history
            ? breadCrumb?.history?.map((item) => (
                <>
                  <div
                    key={item.nameRoute}
                    className="breadcrumb_item"
                    onClick={() => props.history.push(item.click)}
                  >
                    {item.title}
                  </div>
                  <ArrowRight2 size="20" color="#667085" />
                </>
              ))
            : null}

          <div
            className="breadcrumb_item"
            onClick={() => props.history.push(breadCrumb?.click)}
            key={breadCrumb?.nameRoute}
          >
            {breadCrumb?.route || titleBreadCrumb}
          </div>
        </div>

        {/* <h6 className="breadcrumb_title">
          {breadCrumb?.title ?
            breadCrumb?.title :
            titleBreadCrumb ?
              <label>
                Coleção: <b>{titleBreadCrumb}</b>
              </label>
              : <Loader />
          }
        </h6> */}
      </div>

      {isMob ? (
        <div className="nav-options">
          <ProfileImageButton
            id="avatar"
            className="btn-action profile"
            src={profile?.photo}
          />
        </div>
      ) : (
        <div className="nav-options">
          {/* <AccessValidity
            text='• X dias restantes de teste '
            onClick={() => window.open(link, "_blank")}
          /> */}

          <KnowledgeHubButton
            onClick={() => alert('Ainda não tem nada')}
            session={current}
            openSuccessChecklist={openSuccessChecklist}
          />

          <ProfileImageButton id="avatar" src={profile?.photo} />
        </div>
      )}
    </div>
  );
};

export default withRouter(FlagSystem);
