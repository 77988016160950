import { useEffect } from 'react';
import {
  AddCircle,
  CloseCircle,
  TickCircle,
  ArrowLeft2,
  Add,
} from 'iconsax-react';
import { Modal } from 'rsuite';
import { useState } from 'react';

import SearchTag from './SearchTag';
import { CreateNewTag } from './CreateNewTag';
import { requester } from '../../../../../requesters';
import { Button, SpinningLoader } from '../../../../../ds-components';
import './style.scss';
import CustomNav from '../../../../account/admin/components/CustomNav';

const tabMenu = [
  {
    eventKey: 'search-tag',
    title: 'Buscar marcador',
  },
  {
    eventKey: 'create-tag',
    title: 'Criar marcador',
  },
];

const AddTagDecision = ({ card, collectionId, reloadExportStatus }) => {
  const profile = JSON.parse(localStorage.getItem('profile'));

  const [modal, setModal] = useState(false);
  const [tabActive, setTabActive] = useState('search-tag');

  const [tagName, setTagName] = useState(null);
  const [editTag, setEditTag] = useState('');
  const [createdTag, setCreatedTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  function onCreateNewTag() {
    setLoadingCreate(true);
    requester.collections
      .createTag(editTag, collectionId, profile.id)
      .then((res) => {
        setCreatedTag(true);
        reloadExportStatus();
        setTabActive('search-tag');
        setEditTag('');
        setTimeout(() => {
          setCreatedTag(false);
        }, 5000);

        if (!tagName) {
          onAddTag(res.id, card.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingCreate(false));
  }
  function onPressToCreateTag(e) {
    if (e.key === 'Enter') {
      onCreateNewTag();
    }
  }

  function onDeleteTag(tagId, action) {
    // console.log(tagId);
    const endpoint = process.env.REACT_APP_COLLECTIONS;
    const options = { method: 'DELETE' };

    setLoadingDelete(true);

    fetch(`${endpoint}api/tags/${tagId}`, options)
      .then((response) => {
        action();
        reloadExportStatus();
      })
      .catch((err) => console.error(err))
      .finally(() => setLoadingDelete(false));
  }
  function onAddTag(tagId, cardId) {
    requester.collections
      .addCardsToTag(tagId, [cardId])
      .then((response) => {
        getTag();
        reloadExportStatus();
      })
      .catch((err) => console.log(err));
  }

  function getTag() {
    0;
    setLoading(true);
    requester.collections
      .getTagsByCollection(collectionId)
      .then((response) => {
        if (response.length === 0) {
          setTabActive('create-tag');
        } else {
          setTabActive('search-tag');
        }
        Object.keys(response)?.map((item) => {
          if (response[item].cards.includes(card.id)) {
            setTagName(response[item].title);
          }
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  const handleModal = () => {
    setModal(!modal);
    getTag();
  };

  useEffect(() => {
    getTag();
  }, []);

  return (
    <>
      <Button
        className="add-tag-container"
        appearance="secondary"
        onClick={handleModal}
        size="sm"
        icon={
          tagName ? (
            <TickCircle size="16" color="#84CAFF" />
          ) : (
            <AddCircle size="16" color="#84CAFF" />
          )
        }
      >
        {loading ? (
          <SpinningLoader color="gray" size="sm" />
        ) : tagName ? (
          <p> {tagName} </p>
        ) : (
          <p> Adicionar marcador</p>
        )}
      </Button>

      <Modal open={modal} className="modal-tag-container">
        <Modal.Header
          closeButton={false}
          className="modal-tag-container_header"
        >
          <div style={{ textAlign: 'end' }}>
            <CloseCircle
              size="24"
              color="#98A2B3"
              onClick={handleModal}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <h6 className="modal-tag-container_header_title">Salvar marcador</h6>
          <p className="modal-tag-container_header_subtitle">
            Escolha um marcador para salvar, ou crie um novo para agrupar
            decisões com base em seus interesses.
          </p>
        </Modal.Header>

        <Modal.Body className="modal-tag-container_body">
          <div className="modal-tag-container_body_tab-menu">
            <CustomNav
              items={tabMenu}
              appearance="subtle"
              active={tabActive}
              onSelect={setTabActive}
            />
          </div>

          <div className="modal-tag-container_body_content">
            {tabActive === 'search-tag' ? (
              <SearchTag
                card={card}
                collectionId={collectionId}
                reloadTag={getTag}
                setTabActive={setTabActive}
                setTagName={setTagName}
                onDeleteTag={onDeleteTag}
                loadingDelete={loadingDelete}
                createdTag={createdTag}
                reloadExportStatus={reloadExportStatus}
              />
            ) : (
              <CreateNewTag
                card={card}
                collectionId={collectionId}
                editTag={editTag}
                loading={loadingCreate}
                setEditTag={setEditTag}
                onPressToCreateTag={onPressToCreateTag}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className={`modal-tag-container_footer ${tabActive}`}>
          {tabActive === 'search-tag' ? (
            <>
              <Button
                size="sm"
                fullWidth
                icon={<ArrowLeft2 />}
                appearance="secondary"
                onClick={handleModal}
              >
                Fechar
              </Button>
              <Button size="sm" fullWidth icon={<Add />} onClick={handleModal}>
                Salvar marcador
              </Button>
            </>
          ) : (
            <>
              <Button
                size="sm"
                fullWidth
                icon={<ArrowLeft2 />}
                appearance="secondary"
                onClick={handleModal}
              >
                Fechar
              </Button>
              <Button
                size="sm"
                fullWidth
                icon={<Add />}
                onClick={onCreateNewTag}
              >
                Salvar marcador
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTagDecision;
