import { Turivius } from '../../../ui';
import { Col, Message, Pagination, Row, toaster } from 'rsuite';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '../../../components/ui/sheet';

import { useState, useMemo, useEffect } from 'react';
import { requester } from '../../../requesters';
import ResultCardsContainer from '../../jurisprudential-search/components/results/Cards/Result/ResultCardsContainer';
import MonitoringCard from './MonitoringCard/MonitoringCard';
import MonitoringEmpty from './MonitoringEmpty';
import { Button } from '../../../ds-components';
import { useCollections } from '../../../store/ducks/theCollections';
import { MICROSERVICES } from '../../../data';

const MonitoringCardsContainer = ({
  whereSearch,
  loading,
  terms,
  shared,
  monitoringCount,
  pageMonitoring,
  entities,
  changePage,
  onDeleteMonitoring,
  onSharedWith,
  onEditMonitoring,
  onCreateMonitoring,
  getMonitoring,
}) => {
  const [open, setOpen] = useState(false);
  const [resultsUrl, setResultsUrl] = useState('');
  const [cards, setCards] = useState([]);
  const [query, setQuery] = useState('');
  const [period, setPeriod] = useState('');
  const [requesting, setRequesting] = useState(false);

  const [
    theMatchResultsThatHaveCollections,
    setTheMatchResultsThatHaveCollections,
  ] = useState([]);
  const { collections: theCollections, fetch: fetchCollections } =
    useCollections();

  const dateFormatted = (date) => {
    if (date) {
      const dt = date.split('-');
      return `${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      return ``;
    }
  };

  const openDrawerLatest = (monitoring, result) => {
    try {
      setRequesting(true);

      const url = process.env.REACT_APP_CARDS + result.query_url;
      const filters = {};
      const entity = [];
      const synonyms = JSON.stringify(monitoring.filters.synonyms);
      const default_operator = monitoring.filters.default_operator;
      const fields = JSON.stringify(monitoring.filters.fields);

      Object.keys(entities).forEach((e, i) => {
        entity.push(entities[i].id);
      });

      const allFilters = {
        filter_by_favorability: monitoring.filters.filter_by_favorability,
        sort: monitoring.filters.sort,
        date_range: {
          0: dateFormatted(result.initial_date),
          1: dateFormatted(result.final_date),
        },
        entity: monitoring.all_entities ? entity : monitoring.filters.entity,
        filter_by_orgao_julgador_stf:
          monitoring.filters.filter_by_orgao_julgador_stf,
        filter_by_secao_carf: monitoring.filters.filter_by_secao_carf,
        filter_by_vote: monitoring.filters.filter_by_vote,
      };

      Object.keys(allFilters).forEach((item, index) => {
        if (allFilters[item]?.length > 0 || allFilters[item] > 0) {
          filters[item] = allFilters[item];
        } else if (item === 'date_range') {
          filters[item] = allFilters[item];
        }
      });

      requester.general
        .get(url)
        .then((res) => {
          setCards(res.cards);
        })
        .catch(console.log)
        .finally(() => {
          // console.log(cards)
          setRequesting(false);
        });

      setOpen(!open);
      setQuery(monitoring.query);
      setPeriod(
        `${dateFormatted(result.initial_date)} à ${dateFormatted(
          result.final_date,
        )}`,
      );
      setResultsUrl(
        `/resultados?query=${encodeURIComponent(
          monitoring.query,
        )}&fields=${fields}&synonyms=${synonyms}&default_operator=${default_operator}&filters=${JSON.stringify(
          filters,
        )}`,
      );
    } catch (error) {
      toaster.push(
        <Message
          showIcon
          type="error"
          duration={4000}
          header="Erro ao carregar resultados"
        >
          Ocorreu um erro ao carregar os resultados desse monitoramento.
        </Message>,
      );
      console.error(error);
    }
  };

  const openDrawerLast = (monitoring) => {
    let valueDefaults = {
      default_operator: 'e',
      synonyms: true,
      fields: ['ementa', 'full_text'],
      sort: 1,
    };

    try {
      setRequesting(true);

      const url_base = MICROSERVICES.cards;

      const queryUrl = () => {
        const queryUrl = monitoring.search_results[0].query_url;
        return queryUrl.includes('/search') ? queryUrl.substring(1) : queryUrl;
      };

      const url = url_base + queryUrl();

      const entity = [];
      const filters = {};

      const synonyms =
        JSON.stringify(monitoring.filters.synonyms) ||
        JSON.stringify(valueDefaults.synonyms);
      const default_operator =
        monitoring.filters.default_operator || valueDefaults.default_operator;

      const fields =
        JSON.stringify(monitoring.filters.fields) ||
        JSON.stringify(valueDefaults.fields);

      Object.keys(entities).forEach((e, i) => {
        entity.push(entities[i].id);
      });

      const allFilters = {
        filter_by_favorability: monitoring.filters.filter_by_favorability,
        sort: monitoring.filters.sort || valueDefaults.sort,
        date_range: {
          0: dateFormatted(monitoring.search_results[0].initial_date),
          1: dateFormatted(monitoring.search_results[0].final_date),
        },
        entity: monitoring.all_entities ? entity : monitoring.filters.entity,
        filter_by_orgao_julgador_stf:
          monitoring.filters.filter_by_orgao_julgador_stf,
        filter_by_secao_carf: monitoring.filters.filter_by_secao_carf,
        filter_by_vote: monitoring.filters.filter_by_vote,
      };

      Object.keys(allFilters).forEach((item, index) => {
        if (allFilters[item]?.length > 0 || allFilters[item] > 0) {
          filters[item] = allFilters[item];
        } else if (item === 'date_range') {
          filters[item] = allFilters[item];
        }
      });

      setOpen(true);
      setQuery(monitoring.query);
      setPeriod(
        `${dateFormatted(
          monitoring.search_results[0].initial_date,
        )} à ${dateFormatted(monitoring.search_results[0].final_date)} `,
      );
      setResultsUrl(
        `/resultados?query=${encodeURIComponent(
          monitoring.query,
        )}&fields=${fields}&synonyms=${synonyms}&default_operator=${default_operator}&filters=${JSON.stringify(
          filters,
        )}`,
      );

      requester.general
        .get(url)
        .then((res) => {
          setCards(res.cards);
        })
        .catch(console.log)
        .finally(() => {
          setRequesting(false);
        });
    } catch (err) {
      toaster.push(
        <Message
          showIcon
          type="error"
          duration={4000}
          header="Erro ao carregar resultados"
        >
          Ocorreu um erro ao carregar os resultados desse monitoramento.
        </Message>,
      );
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  useMemo(async () => {
    if (!cards.length) return;
    const cardIds = cards?.map((card) => card.id);
    const response =
      await requester.collections.matchResultsThatHaveCollections(
        encodeURIComponent(JSON.stringify(cardIds)),
      );
    const theResponse = [...new Set(response)];
    setTheMatchResultsThatHaveCollections(theResponse);
  }, [theCollections, cards]);

  if (loading)
    return (
      <Col xs={24} sm={24} style={{ height: 150 }}>
        <Turivius.TuriviusPreloader />
      </Col>
    );

  if (terms.length === 0)
    return (
      <MonitoringEmpty
        shared={shared}
        entities={entities}
        onCreateMonitoring={onCreateMonitoring}
        getMonitoring={getMonitoring}
      />
    );

  return (
    <div>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent className="flex flex-col min-w-full max-w-full px-0 lg:min-w-[1000px]">
          <SheetHeader className="flex-row px-8">
            <SheetClose className="relative inset-0 pr-4" />
            <SheetTitle className="flex-1">
              {query ? (
                <div>
                  <h4 style={{ whiteSpace: 'pre-wrap' }}>{query}</h4>
                  <span>Última atualização: {period}</span>
                </div>
              ) : (
                'Carregando...'
              )}
            </SheetTitle>
            <Button
              appearance="primary"
              size="sm"
              onClick={() => window.open(resultsUrl, '_blank')}
            >
              Abrir Resultados
            </Button>
            <Button
              onClick={() => setOpen(false)}
              appearance="minimal"
              size="sm"
            >
              Fechar
            </Button>
          </SheetHeader>
          <hr />
          <ResultCardsContainer
            monitoring
            query={query}
            results={cards}
            entities={entities}
            loading={requesting}
            theCollections={theCollections}
            theMatchResultsThatHaveCollections={
              theMatchResultsThatHaveCollections
            }
          />
        </SheetContent>
      </Sheet>

      <div className="gap-5 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3">
        {terms?.map((monitoring, index) => (
          <div key={index}>
            <MonitoringCard
              key={monitoring.id}
              shared={shared}
              monitoring={monitoring}
              entities={entities}
              getMonitoring={getMonitoring}
              onDeleteMonitoring={onDeleteMonitoring}
              onSharedWith={onSharedWith}
              onEditMonitoring={onEditMonitoring}
              onLastResults={openDrawerLast}
              onLatestResults={openDrawerLatest}
            />
          </div>
        ))}
      </div>
      <Row>
        <Pagination
          prev
          next
          first
          last
          total={monitoringCount}
          activePage={pageMonitoring}
          onChangePage={(page) =>
            changePage({ where: whereSearch, page: page })
          }
          limit={6}
          maxButtons={5}
        />
      </Row>
    </div>
  );
};

export default MonitoringCardsContainer;
