import { useEffect } from 'react';
import { Toggle } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';

const SynonymsFilter = ({ context, filtersProps }) => {
  function onChangeFilter(option) {
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'synonyms',
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }
    filtersProps?.changeSynonyms(option);
  }

  useEffect(() => {
    if (!filtersProps?.defaultOperator) {
      filtersProps?.changeSynonyms(true);
    }
  });

  return (
    <div className="single-filter-box without-border">
      <label className="single-filter-box_title">
        Incluir Termos Semelhantes:
      </label>
      <div className="single-filter-box_option">
        <span>Sim</span>
        <Toggle
          loading={filtersProps ? false : true}
          onChange={onChangeFilter}
          checked={filtersProps?.synonyms}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default SynonymsFilter;
