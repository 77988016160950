import { useEffect } from 'react';
import { useState } from 'react';
import { Button as ButtonTuring } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import InteiroTeorFilter from './Filters/InteiroTeorFilter';
import SynonymsFilter from './Filters/SynonymsFilter';
import FilterWrapper from './FilterWrapper';
import { FiltersSkeleton } from '../../../../../components/Skeleton';

export const FiltersList = ({
  headerTitle,
  tmpFilters,
  filtersProps,
  entities,
  ...props
}) => {
  const [filters, setFilters] = useState(null);
  const [requesting, setResquesting] = useState(true);

  function getFilters() {
    if (!props.filters) {
      setResquesting(true);
      requester.cards
        .getAllFilters()
        .then((response) => {
          const filtersResp = response;
          let defaultFilters = {};

          setFilters(filtersResp);

          filtersResp.forEach((filter) => {
            if (filter.field_name === 'entity') {
              defaultFilters.entities = filter.default;
            } else {
              defaultFilters[filter.field_name] = filter.default;
            }
          });
          filtersProps.setOriginalFilters(filtersResp);
          filtersProps.setDefaultFilters(defaultFilters);
          filtersProps.loadFilters();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setResquesting(false);
        });
    }
  }

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <div className="tv-filters_list">
      <h6 className=" tv-filters_title font-bold pb-3 text-color_black">
        {headerTitle}
      </h6>
      {requesting ? (
        <FiltersSkeleton />
      ) : (
        // <>
        <div className="tv-filters_body">
          <InteiroTeorFilter context="Results" filtersProps={filtersProps} />

          <SynonymsFilter context="Results" filtersProps={filtersProps} />

          <form className="tv-filters_content">
            {filters
              ? filters?.map((filter, index) => {
                  return (
                    <FilterWrapper
                      key={index}
                      context="Results"
                      filter={filter}
                      tmpFilters={tmpFilters}
                      entities={entities}
                      filtersProps={filtersProps}
                    />
                  );
                })
              : null}
          </form>
        </div>
      )}

      {/* </> */}
      <div className="tv-filters_footer">
        <ButtonTuring
          size="md"
          fullWidth
          onClick={() =>
            filtersProps.saveFilters(
              filtersProps.history,
              filtersProps.match,
              encodeURIComponent(filtersProps.query),
            )
          }
        >
          Filtrar
        </ButtonTuring>
      </div>
    </div>
  );
};

export default FiltersList;
