import { Radio, RadioGroup } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';

const InteiroTeorFilter = ({ context, filtersProps }) => {
  function OnChangeFilter(option) {
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'ementa_inteiro_teor',
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }

    filtersProps.changeInteiroTeor(option);
  }

  return (
    <div className="single-filter-box">
      <label className="single-filter-box_title">Buscar em:</label>
      <div className="single-filter-box_option">
        <RadioGroup
          inline
          name="radioList"
          onChange={(option) => OnChangeFilter(option)}
          value={JSON.stringify(filtersProps.inteiroTeor)}
        >
          <Radio value='["ementa","full_text"]'>Todos</Radio>
          <Radio value='["ementa"]' className="radio-input-ementa">
            Ementa
          </Radio>
          <Radio value='["full_text"]'>Inteiro Teor</Radio>
        </RadioGroup>
      </div>
    </div>
  );
};

export default InteiroTeorFilter;
