import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faLock,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { Buildings, Folder, Lock, Share } from 'iconsax-react';
import './style.scss';

const AvatarFolder = ({ data }) => {
  return (
    <div className="avatar-folder">
      <div className="avatar-folder-due">
        {/* <FontAwesomeIcon color='#010c28' size='2x' icon={faFolder} /> */}
        <Folder variant="Bold" color="#010c28" size={40} />
        <div
          className="avatar-status"
          style={{
            backgroundColor: data.public_to_company
              ? '#0d91fe'
              : data.shared_with.length > 0
                ? '#053ac8'
                : '#0069f5',
          }}
        >
          {data.public_to_company ? (
            <Buildings variant="Bold" color="#fff" size={20} />
          ) : // <FontAwesomeIcon size="xs" color='#fff' icon={faBuilding} />
          data.shared_with.length > 0 ? (
            <Share variant="Bold" color="#fff" size={25} />
          ) : (
            // <FontAwesomeIcon size="xs" color='#fff' icon={faShareAlt} />
            <Lock variant="Bold" color="#fff" size={20} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AvatarFolder;
