import { useState } from 'react';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { ArrowLeft2, CloseCircle, Minus, Trash } from 'iconsax-react';
import { Message, toaster, Modal } from 'rsuite';
import { requester } from '../../../../../requesters';
import { withRouter } from 'react-router-dom';

import './RemoveCollectionButton.scss';

const RemoveCollectionButton = ({
  small,
  requesting,
  collection,
  onSearch,
  collectionId,
  context,
}) => {
  const user = localStorage.getItem('user');
  const [modal, setModal] = useState(false);

  const handleModal = () => setModal(!modal);

  const removeCollection = () => {
    toaster.push(<Message type="info">Removendo coleção...</Message>);

    requester.collections
      .deleteCollection(user, collectionId, context)
      .then(() => {
        toaster.clear();
        onSearch();

        toaster.push(
          <Message
            header="Dados atualizados"
            duration={4000}
            type="success"
            showIcon
            closable
          >
            Coleção removida com sucesso
          </Message>,
        );

        handleModal();
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });
  };

  return (
    <>
      <Button
        fullWidth
        style={{ borderColor: 'red !important' }}
        size="sm"
        appearance="secondary"
        icon={<Trash size={20} />}
        onClick={handleModal}
      >
        {small ? '' : 'Deletar'}
      </Button>

      <Modal className="tv-modal-default" open={modal} onClose={handleModal}>
        <Modal.Header closeButton={false} className="tv-modal-default_header">
          <div style={{ textAlign: 'end' }}>
            <CloseCircle
              size="24"
              color="#98A2B3"
              onClick={handleModal}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <h6 className="tv-modal-default_header_title">Remover Coleção</h6>
          <p className="tv-modal-default_header_subtitle">
            Tem certeza que deseja remover a coleção <b>{collection?.name}</b>?
            Essa ação não poderá ser desfeita.
          </p>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer className="tv-modal-default_footer">
          <Button
            size="sm"
            fullWidth
            icon={<ArrowLeft2 />}
            appearance="secondary"
            onClick={handleModal}
          >
            Cancelar
          </Button>
          <Button
            size="sm"
            fullWidth
            icon={<Minus />}
            onClick={removeCollection}
          >
            {requesting ? (
              <SpinningLoader color="white" size="sm" />
            ) : (
              'Remover coleção'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(RemoveCollectionButton);
