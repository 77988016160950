export const routes = [
  {
    route: 'Jurimetria Trabalhista',
    nameRoute: 'jurimetrics-trabalhist',
    title: 'Tome decisões estratégicas com dados estatísticos',
    active: true,
    click: '/jurimetria-trabalhista',
    history: [],
  },
  {
    route: 'Dashboard Empresarial',
    nameRoute: 'dashboard-empresarial',
    title: 'Ferramenta de Inteligência Jurídica Trabalhista',
    active: true,
    click: '/dashboard-empresarial',
    history: [],
  },
  {
    route: 'Jurisprudência',
    nameRoute: 'jurisprudence',
    active: true,
    title: 'Ferramenta de Inteligência Jurídica',
    click: '/pesquisa',
    history: [],
  },
  {
    route: 'Busca por CNPJ',
    nameRoute: 'jurimetrics-cnpj',
    active: true,
    title: 'Jurimetria Trabalhista: CNPJ',
    click: '/jurimetria-trabalhista',
    history: [
      {
        title: 'Jurimetria Trabalhista',
        click: '/jurimetria-trabalhista',
      },
    ],
  },
  {
    route: 'Busca por Assunto',
    nameRoute: 'jurimetrics-assunto',
    active: true,
    title: 'Jurimetria Trabalhista: Assunto',
    click: '/jurimetria-trabalhista',
    history: [
      {
        title: 'Jurimetria Trabalhista',
        click: '/jurimetria-trabalhista',
      },
    ],
  },
  {
    route: 'Busca por Estado',
    nameRoute: 'jurimetrics-estado',
    active: true,
    title: 'Jurimetria Trabalhista: Estado',
    click: '/jurimetria-trabalhista',
    history: [
      {
        title: 'Jurimetria Trabalhista',
        click: '/jurimetria-trabalhista',
      },
    ],
  },
  {
    route: 'Monitoramento',
    nameRoute: 'monitoring',
    active: true,
    title: 'Monitoramento',
    click: '/monitoramento',
    history: [],
  },
  {
    route: 'Coleções',
    nameRoute: 'collections',
    active: true,
    title: 'Coleções',
    click: '/colecoes',
    history: [],
  },
  {
    route: '',
    nameRoute: 'collection',
    active: true,
    title: '',
    click: '/colecoes',
    history: [
      {
        title: 'Coleções',
        click: '/colecoes',
      },
    ],
  },
];
