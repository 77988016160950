import React from 'react';
import { Button, Col, Dropdown, Tooltip, Whisper } from 'rsuite';
import { Turivius } from '../../../../../ui';
import { Textfit } from 'react-textfit';

import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import { Buildings, Document, Lock, More, Profile2User } from 'iconsax-react';

import EditCollectionButton from '../../Collection/EditCollectionButton/EditCollectionButton';
import RemoveCollectionButton from '../../Collection/RemoveCollectionButton/RemoveCollectionButton';
import ManagePeopleCollectionButton from '../../Collection/ManagePeopleCollectionButton/ManagePeopleCollectionButton';
import ShareCollectionWithCompanyButton from '../../Collection/ShareCollectionWithCompanyButton/ShareCollectionWithCompanyButton';
import AvatarFolder from '../AvatarFolder';

import 'moment/locale/pt-br';
import './CollectionFolder.scss';

const renderToggleMenu = (props, ref) => {
  return (
    <Button {...props} ref={ref} className="btn-menu-option">
      <More size="22" />
    </Button>
  );
};

class CollectionFolder extends React.Component {
  state = {
    requesting: false,
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getOwnerName(name) {
    const splitName = name.split(' ');
    if (splitName.length <= 1) {
      return name;
    } else {
      return `${splitName[0][0]}. ${splitName.pop()}`;
    }
  }

  openCollection(collectionId) {
    this.props.history.push(`/colecoes/${collectionId}`);
  }

  render() {
    const { collection, shared, onSearch, id } =
      this.props;

    const { anchorEl } = this.state;

    return (
      <Col xs={24} sm={24} md={12} lg={8} xl={6}>
        <div className="collection-folder_content" id={id}>
          <div className="collection-folder_content_header">
            <AvatarFolder data={collection} />

            <div
              className="title-folder"
              onClick={() => this.openCollection(collection.id)}
            >
              <Turivius.TuriviusTooltip
                trigger="hover"
                placement="top"
                message={`${collection.name} por ${collection.owner.name}`}
              >
                <span>{collection.name}</span>
              </Turivius.TuriviusTooltip>
            </div>

            <Dropdown
              placement="leftStart"
              renderToggle={renderToggleMenu}
              id="nav-menu-options"
            >
              <Dropdown.Item eventKey={1}>
                <ShareCollectionWithCompanyButton
                  collection={collection}
                  collectionId={collection.id}
                  collectionTitle={collection.name}
                  publicToCompany={collection.public_to_company}
                  onSearch={onSearch}
                  context={'Collections'}
                />
              </Dropdown.Item>
              <Dropdown.Item eventKey={2}>
                <ManagePeopleCollectionButton
                  collection={collection}
                  collectionId={collection.id}
                  sharedWith={collection.shared_with}
                  context={'Collections'}
                  onSearch={onSearch}
                />
              </Dropdown.Item>

              {shared ? (
                <Dropdown.Item eventKey={2}>
                  <EditCollectionButton
                    collectionId={collection.id}
                    collectionTitle={collection.name}
                    collectionDescription={collection.description}
                    onSearch={onSearch}
                    context={'Collections'}
                  />
                </Dropdown.Item>
              ) : null}

              {shared ? (
                <Dropdown.Item onClick={this.handleClose}>
                  <RemoveCollectionButton
                    collection={collection}
                    collectionId={collection.id}
                    context={'Collections'}
                    onSearch={onSearch}
                  />
                </Dropdown.Item>
              ) : null}
            </Dropdown>
          </div>

          <div
            className="collection-folder_content_body"
            onClick={() => this.openCollection(collection.id)}
          >
            <div className="info-icon">
              <Document
                variant="Bold"
                color="#0069f5"
                size={16}
                style={{ marginRight: 3 }}
              />
              <span style={{ fontSize: 12 }}>
                {collection?.qt_cards} decisões
              </span>
            </div>

            {collection.public_to_company ? (
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Todos do escritório</Tooltip>}
              >
                <div
                  className="info-icon"
                  style={{
                    width: '100px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Buildings
                    variant="Bold"
                    color="#0069f5"
                    size={16}
                    style={{ marginRight: 3 }}
                  />
                  <span style={{ fontSize: 12 }}>Escritório</span>
                </div>
              </Whisper>
            ) : collection.shared_with.length > 0 ? (
              <div className="info-icon">
                <Profile2User
                  variant="Bold"
                  color="#0069f5"
                  size={16}
                  style={{ marginRight: 3 }}
                />
                <span style={{ fontSize: 12 }}>
                  {collection.shared_with.length} usuário(s)
                </span>
              </div>
            ) : (
              <div className="info-icon">
                <Lock
                  variant="Bold"
                  color="#0069f5"
                  size={16}
                  style={{ marginRight: 3 }}
                />
                <span style={{ fontSize: 12 }}>Somente eu</span>
              </div>
            )}
          </div>

          <div
            className="collection-folder_content_footer"
            onClick={() => this.openCollection(collection.id)}
          >
            <Textfit mode="single" max={13}>
              Atualizado&thinsp;
              <Moment
                fromNow
                locale="pt-br"
                subtract={{ hours: 3 }}
                parse="YYYY-MM-DD hh:mm:ss"
              >
                {collection.last_update}
              </Moment>
            </Textfit>
          </div>
        </div>
      </Col>
    );
  }
}

export default withRouter(CollectionFolder);
