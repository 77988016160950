import React from 'react';
import { Button, Loader, TagPicker } from 'rsuite';
import { postHubSpotEvent } from '../../../../../services/hubspot';
class MultiSelect extends React.Component {
  state = {
    requesting: false,
    items: null,
    autocompleteFilter: [],
    value: [],
  };

  cleanDates = () => {
    const { filter } = this.props;
    const { setTmpFilters } = this.props.filtersProps;

    this.setState({ value: [] });

    setTmpFilters(filter.field_name, []);
  };

  componentDidUpdate() {
    const { filter } = this.props;

    const externalFilter =
      this.props.filtersProps.externalFilters &&
      filter &&
      this.props.filtersProps.externalFilters[filter.field_name];
    const { items, value } = this.state;

    if (
      this.mounted &&
      externalFilter &&
      items &&
      externalFilter.length !== items.length
    ) {
      this.setState({
        items: externalFilter?.map((filter) => {
          return {
            ...filter,
            id: filter.key,
          };
        }),
      });
    }
  }

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      this.setState({
        items: this.props.filtersProps.externalFilters[
          this.props.filter.field_name
        ]?.map((filter) => {
          return {
            ...filter,
            id: filter.key,
          };
        }),
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { items, requesting, value } = this.state;
    const { filter, labelKey, placeholder } = this.props;
    const { setTmpFilters, externalFilters } = this.props.filtersProps;
    return (
      <div className="single-filter-box" id={filter.field_name}>
        <div className="single-filter-box_container-title">
          <span className="single-filter-box_title">{filter.public_name}</span>
          <Button size="xs" appearance="link" onClick={() => this.cleanDates()}>
            Limpar
          </Button>
        </div>

        {items !== undefined && items !== null && !requesting ? (
          <TagPicker
            virtualized
            block
            id={`rs-select-${filter.field_name}`}
            labelKey={'key'}
            valueKey={'related_names'}
            data={items}
            placeholder={placeholder}
            cleanable={false}
            placement={'top'}
            value={value?.map((v) => (typeof v === 'string' ? v : v['key']))}
            onChange={(valor, event) => {
              this.setState({ value: valor });

              if (valor.length > 0) {
                setTmpFilters(filter.field_name, [
                  ...valor.join(',').split(','),
                ]);
              } else {
                setTmpFilters(filter.field_name, [...valor]);
              }
              if (this.props.context) {
                // posthog.capture(this.props.context, {
                //   "action": "change_filter",
                //   "id": filter.field_name,
                // });
                postHubSpotEvent('app_changefilters', {
                  app_changefilters: this.props.context,
                });
              }
            }}
            container={() => {
              const container = document.getElementById('label-relator');
              return container;
            }}
            renderMenuItem={(label, item) => {
              if (items.length > 0) {
                return (
                  // <div>
                  <div style={{ textTransform: 'capitalize' }}>
                    {label} ({item.doc_count})
                  </div>
                );
              } else {
                <Loader />;
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default MultiSelect;

// <div className="tv-select-multi">
//   <p className="tv-select-multi_label pb-1 font-medium">{label}</p>
//   <div className="tv-select-multi_select">
//     <div
//       className="tv-select-multi_select-container"
//       onClick={(e) => handleInputClick(e)}
//     >
//       <p>{placeholder}</p>
//       {openMenu ? (
//         <ArrowUp2 size={16} color="#667085" />
//       ) : (
//         <ArrowDown2 size={16} color="#667085" />
//       )}
//     </div>
//     {openMenu ? (
//       <div className="tv-select-multi_select-menu">
//         {data?.map((option) => {
//           return (
//             <div
//               key={option.value}
//               className="select-menu_item"
