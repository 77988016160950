import { useState, useEffect } from 'react';
import { Grid, Col, Row } from 'rsuite';
import CommentsCard from '../Collection/Comments/CommentsCard';
import { ResultCard } from '../../../jurisprudential-search/components/results/Cards/Result/ResultCard';
import '../style.scss';

const CollectionsResultCard = ({
  card,
  collectionId,
  context,
  collection,
  entities,
  requestingComments,
  comments,
  addComment,
  removeComment,
  reloadExportStatus,
  theCollections,
  theMatchResultsThatHaveCollections,
}) => {
  const [isMobile, setIsMobile] = useState(window.detectMob());

  const updateDimensions = () => {
    setIsMobile(window.detectMob());
  };

  const getEntityById = (entityId) => {
    const entitiesArr = Object.values(entities);
    const entity = entitiesArr.find((entity) => entity.id === entityId);
    return entity?.initials;
  };

  const formatCardsValue = () => {
    return {
      ...card,
      id: card.id,
      entity: {
        id: card.entity,
        name: getEntityById(Number(card.entity)),
      },
      name: card.nome,
      relator: {
        id: card.relator_id,
        name: card.relator,
      },
      dataPub: card.data_pub,
      dataJul: card.data_jul,
      orgaoJulgador: card.orgao_julgador,
      content: card.content,
      url: card.url,
      chips: card.chips.length === 0 ? null : card.chips,
      timeline: card.timeline,
    };
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className="collections-result-card">
      <Grid fluid>
        <Row>
          <Col xs={24} sm={24} md={isMobile ? 24 : 18}>
            <ResultCard
              card={formatCardsValue()}
              key={card.id}
              collectionId={collectionId}
              context={context}
              reloadExportStatus={reloadExportStatus}
              theCollections={theCollections}
              theMatchResultsThatHaveCollections={
                theMatchResultsThatHaveCollections
              }
            />
          </Col>
          <Col xs={0} sm={0} md={isMobile ? 0 : 6} style={{ padding: 0 }}>
            <CommentsCard
              collectionId={collectionId}
              collection={collection}
              card={card}
              isMobile={isMobile}
              requestingComments={requestingComments}
              comments={comments}
              addComment={addComment}
              removeComment={removeComment}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default CollectionsResultCard;
