import { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidenav from '../../Sidenav/Sidenav';
import {
  SearchNormal1,
  Chart1,
  FolderOpen,
  GlobalSearch,
  Briefcase,
  Discover,
  LogoutCurve,
  Message,
  Subtitle,
  InfoCircle,
} from 'iconsax-react';
import { useKnowledgeStore } from '../../../store/ducks/knowledge';

const PATHS = {
  principal: '0',
  home: '1',
  home_tributario: '10',
  home_trabalhista: '11',
  colecoes: '2',
  dashboards: '3',
  monitoramento: '4',
  notificacao: '5',
  admin: '6',
  perfil: '7',
  logout: '8',
  docs: '9',
  jurimetrics_trabalhista: '12',
};

const TuriviusSidenav = ({ history }) => {
  const { pathname } = useLocation();
  const { open: openKnowledge } = useKnowledgeStore();

  const [activeKey, setActiveKey] = useState(null);
  const [showNavbar, setShowNavbar] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const profile = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    if (!profile) setShowNavbar(false);
    const handleResize = () => {
      setShowNavbar(
        profile && !['/trial', '/login', '/sso'].includes(pathname),
      );
    };

    window.addEventListener('resize', handleResize);

    return () => {
      setShowNavbar(
        profile && !['/trial', '/login', '/sso'].includes(pathname),
      );
      window.removeEventListener('resize', handleResize);
    };
  }, [history, profile]);

  const header = {
    img: '/new-logo-white.svg',
    onClick: () => history.push('/pesquisa'),
  };

  const navItems = [
    {
      icon: 'ri-search-line',
      children: '',
      empty: {
        name: 'Clique aqui para solicitar!',
        onClick: () => history.push('/solicitar-tributario'),
        show: false,
      },
      products: [
        {
          id: 'menu-jurisprudence',
          icon: <SearchNormal1 size="16" />,
          name: 'Jurisprudência',
          route: '/pesquisa',
          onClick: () => history.push('/pesquisa'),
          show: true,
        },
        {
          id: 'menu-bi-tribut',
          name: 'BI Tributário',
          icon: <Chart1 size="16" />,
          onClick: () =>
            (window.location.href = `${
              process.env.REACT_APP_BI_URI
            }redirect?user=${localStorage.getItem(
              'user',
            )}&hash=${localStorage.getItem('token')}&redirect=/`),
          show: profile?.dashboard,
        },
        {
          id: 'menu-collections',
          name: 'Coleções',
          icon: <FolderOpen size="16" />,
          route: '/colecoes',
          onClick: () => history.push('/colecoes'),
          show: true,
        },
        {
          id: 'menu-monitoring',
          name: 'Monitoramento',
          icon: <GlobalSearch size="16" />,
          route: '/monitoramento',
          onClick: () => history.push('/monitoramento'),
          show: true,
        },
        {
          id: 'menu-jurimetrics-trab',
          name: 'Jurimetria Trabalhista',
          icon: <Briefcase size="16" />,
          route: '/jurimetria-trabalhista',
          onClick: () => history.push('/jurimetria-trabalhista'),
          show: permissions?.trabalhista?.plano_alias,
        },
        {
          id: 'menu-business-dash',
          name: 'Área Empresarial',
          icon: <Discover size="16" />,
          route: '/dashboard-empresarial',
          onClick: () => history.push('/dashboard-empresarial'),
          show: permissions?.trabalhista?.business_dashboard?.area_empresarial,
        },
      ],
    },
  ];

  const footer = [
    {
      icon: <Subtitle size="16" />,
      children: 'Artigos',
      onClick: () =>
        window.open(
          'https://www.turivius.com/portal/',
          '_blank',
          'noopener,noreferrer',
        ),
      show: true,
    },
    {
      icon: <InfoCircle size="16" />,
      children: 'Faq',
      onClick: () => openKnowledge(),
      show: true,
    },
    {
      icon: <Message size="16" />,
      children: 'Suporte',
      onClick: () =>
        window.open(`${window.location.href}#hs-chat-open`, '_self'),
      show: true,
    },
    {
      icon: <LogoutCurve size="16" />,
      children: 'Sair',
      onClick: () => history.push('/logout'),
      show: true,
    },
  ];

  useEffect(() => {
    if (history.location.pathname) {
      if (
        history.location.pathname.includes('tributario') ||
        history.location.pathname.includes('trabalhista') ||
        history.location.pathname.includes('resultados')
      ) {
        setActiveKey(PATHS['home']);
      } else if (history.location.pathname.replace('/', '') === '') {
        setActiveKey(PATHS['principal']);
      } else if (history.location.pathname.includes('colecoes')) {
        setActiveKey(PATHS['colecoes']);
      } else if (history.location.pathname.includes('monitoramento')) {
        setActiveKey(PATHS['monitoramento']);
      } else if (history.location.pathname.includes('admin')) {
        setActiveKey(PATHS['admin']);
      } else if (history.location.pathname.includes('docs')) {
        setActiveKey(PATHS['docs']);
      } else {
        setActiveKey(PATHS[history.location.pathname.replace('/', '')]);
      }
    }
  }, [history]);

  return showNavbar ? (
    <Sidenav
      footer={footer}
      navItems={navItems}
      defaultActiveKey={activeKey}
      header={header}
      permissions={permissions}
    />
  ) : null;
};

const mapStateToProps = (state) => ({
  profile: state.profiles.profile,
  permissions: state.profiles.permissions,
});

export default connect(mapStateToProps, {})(withRouter(TuriviusSidenav));
