export const hotTopics = [
  {
    id: 1,
    name: 'IRPJ E "taxa selic"',
    url: 'https://app.turivius.com/pesquisa/resultados?query=IRPJ%20E%20%22taxa%20selic%22&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2207/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 2,
    name: 'Cofins E contrato adj2 segur$',
    url: 'https://app.turivius.com/pesquisa/resultados?query=Cofins%20E%20contrato%20adj2%20segur$&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2212/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 3,
    name: '"repetição de indébito" E SELIC',
    url: 'https://app.turivius.com/pesquisa/resultados?query=%22repeti%C3%A7%C3%A3o%20de%20ind%C3%A9bito%22%20E%20SELIC&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2212/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 4,
    name: '"Atividade preponderante" E RAT OU SAT',
    url: 'https://app.turivius.com/pesquisa/resultados?query=%22Atividade%20preponderante%22%20E%20RAT%20OU%20SAT&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2207/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 5,
    name: '"ex tarifario" E Importação',
    url: 'https://app.turivius.com/pesquisa/resultados?query=%22ex%20tarifario%22%20E%20Importa%C3%A7%C3%A3o&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2207/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 6,
    name: 'Declaração E Doação NÃO IRPJ',
    url: 'https://app.turivius.com/pesquisa/resultados?query=Declara%C3%A7%C3%A3o%20E%20Doa%C3%A7%C3%A3o%20N%C3%83O%20IRPJ&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2207/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 7,
    name: 'dedutibilidade E amortização ADJ2 Ágio E CSLL',
    url: 'https://app.turivius.com/pesquisa/resultados?query=dedutibilidade%20E%20amortiza%C3%A7%C3%A3o%20ADJ2%20%C3%81gio%20E%20CSLL&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2212/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 8,
    name: 'ICMS E "base de cálculo" E presumido E PIS OU COFINS',
    url: 'https://app.turivius.com/pesquisa/resultados?query=ICMS%20E%20%22base%20de%20c%C3%A1lculo%22%20E%20presumido%20E%20PIS%20OU%20COFINS&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2208/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 9,
    name: 'RAT OU SAT E Incidência',
    url: 'https://app.turivius.com/pesquisa/resultados?query=RAT%20OU%20SAT%20E%20Incid%C3%AAncia&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2207/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
  {
    id: 10,
    name: 'FUNRURAL E "base de cálculo" E Cofins',
    url: 'https://app.turivius.com/pesquisa/resultados?query=FUNRURAL%20E%20%22base%20de%20c%C3%A1lculo%22%20E%20Cofins&fields=[%22ementa%22,%22full_text%22]&default_operator=e&synonyms=true&filters={%22sort%22:1,%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2212/06/2023%22},%22filter_by_favorability%22:[0,1,2,3,4],%22entity%22:[0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,51,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16,57,60,39,66,56,49,45,72,65,73,74,38,64,63,69,55,75,68,71,9,78,52,21,70,54,22,91,58,93,53,90,20,82,85,83,86,80,79,87,48,94,50,89,77]}',
  },
];
