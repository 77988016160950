import { useState, useEffect } from 'react';
import { Input, Message, toaster, InputGroup } from 'rsuite';
import { requester } from '../../../../../requesters';
import { AddCircle, TickCircle } from 'iconsax-react';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { updateCheckllist } from '../../../../../services/checklist';
import { useCollections } from '../../../../../store/ducks/theCollections';

const CreateAndAddToCollection = ({ query, context, cards }) => {
  const { fetch } = useCollections();
  const [collectionName, setCollectionName] = useState(query);
  const [fetching, setFetching] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);

  const handleChangeCollectionName = (value) => {
    setCollectionName(value);
  };

  const createCollectionAndAddCard = async () => {
    setFetching(true);
    let collection = null;
    try {
      const response = await requester.collections.createCollection(
        localStorage.getItem('user'),
        collectionName,
        '',
        context,
      );
      toaster.push(
        <Message header="Dados criados" type="success" showIcon closable>
          Coleção criada com sucesso.
        </Message>,
        { duration: 4000 },
      );
      collection = response;
      await requester.collections.addCardsToCollection(
        localStorage.getItem('user'),
        collection.id,
        cards?.map((card) => card.id),
        context,
      );
      setFetching(false);
      setSuccessCreate(true);
      fetch();
      toaster.push(
        <Message header="Dados criados" type="success" showIcon closable>
          Resultado adicionado com sucesso.
        </Message>,
        { duration: 4000 },
      );
    } catch (err) {
      console.error(err);
      toaster.push(
        <Message header="Ocorreu um erro" type="error" showIcon closable>
          Houve um problema de conexão, tente novamente.
        </Message>,
        { duration: 4000 },
      );
      setFetching(false);
    }
  };

  useEffect(() => {
    setCollectionName(query);
  }, [query]);

  return (
    <div className="create-collection p-3">
      <InputGroup inside>
        <InputGroup.Button>
          <AddCircle size={12} color="#98A2B3" />
        </InputGroup.Button>
        <Input
          id={`create-and-add-save-to-collection-${Math.random()}`}
          value={collectionName}
          label="Insira o nome da coleção"
          variant="outlined"
          onChange={handleChangeCollectionName}
          size={'md'}
          placeholder="Nova coleção aqui"
        />
      </InputGroup>

      {successCreate ? (
        <Button
          size="sm"
          appearance="success"
          data-testid="create-collection-button-success"
          icon={<TickCircle size={14} color="#FFFFFF" />}
          block
        >
          Adicionado
        </Button>
      ) : fetching ? (
        <Button appearance="primary" size="sm" block>
          <SpinningLoader color="white" size="sm" />
        </Button>
      ) : (
        <Button
          appearance="primary"
          size="sm"
          data-testid="create-collection-button-action"
          onClick={createCollectionAndAddCard}
          icon={<AddCircle size={18} color="#FFFFFF" />}
        >
          Criar coleção
        </Button>
      )}
    </div>
  );
};

export default CreateAndAddToCollection;
