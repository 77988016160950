import React from 'react';
import SpinningLoader from '../Loaders/SpinningLoader';
import { Tooltip, Whisper } from 'rsuite';

export type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  children?: string | React.ReactElement;
  appearance:
    | 'primary'
    | 'secondary'
    | 'minimal'
    | 'white'
    | 'success'
    | 'danger';
  outline?: boolean;
  size: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  block?: boolean;
  loading?: boolean;
  onClick?: () => void;
  ref?: string;
  className?: string;
  tooltipText?: string;
};

export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  children,
  icon,
  iconPosition = 'left',
  size = 'md',
  appearance = 'primary',
  outline,
  disabled,
  block,
  loading,
  onClick,
  ref,
  className,
  fullWidth,
  tooltipText,
  ...props
}) => {
  return (
    <>
      {loading ? (
        <button
          className={`tv-btn tv-btn-${size}${
            fullWidth ? '_full' : ''
          } tv-btn-${appearance}${outline ? '_outline' : ''} font-medium ${
            disabled ? 'tv-btn-disabled' : ''
          } ${block ? 'tv-btn-block' : ''} ${className ? className : ''}`}
          disabled={disabled}
          {...props}
        >
          <SpinningLoader
            color={
              (appearance === 'primary' || 'success' || 'error') && !outline
                ? 'white'
                : appearance
            }
            size="sm"
          />
        </button>
      ) : (
        <>
          {tooltipText ? (
            <>
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>{tooltipText}</Tooltip>}
              >
                <button
                  onClick={onClick}
                  ref={ref}
                  type={type}
                  disabled={disabled}
                  className={`tv-btn tv-btn-${size}${
                    fullWidth ? '_full' : ''
                  } tv-btn-${appearance}${outline ? '_outline' : ''} font-medium ${
                    disabled ? 'tv-btn-disabled' : ''
                  } ${block ? 'tv-btn-block' : ''} ${className ? className : ''}`}
                  {...props}
                >
                  {iconPosition === 'left' && icon}
                  {children && <span>{children}</span>}
                  {iconPosition === 'right' && icon}
                </button>
              </Whisper>
            </>
          ) : (
            <button
              onClick={onClick}
              ref={ref}
              type={type}
              disabled={disabled}
              className={`tv-btn tv-btn-${size}${
                fullWidth ? '_full' : ''
              } tv-btn-${appearance}${outline ? '_outline' : ''} font-medium ${
                disabled ? 'tv-btn-disabled' : ''
              } ${block ? 'tv-btn-block' : ''} ${className ? className : ''}`}
              {...props}
            >
              {iconPosition === 'left' && icon}
              {children && <span>{children}</span>}
              {iconPosition === 'right' && icon}
            </button>
          )}
        </>
      )}
    </>
  );
};
export default Button;
