import { AddCircle } from 'iconsax-react';
import React from 'react';
import { Input, InputGroup } from 'rsuite';
import './style.scss';

export const CreateNewTag = ({
  editTag,
  loading,
  setEditTag,
  onPressToCreateTag,
}) => {
  return (
    <div className="search-tag-container">
      <InputGroup inside>
        <InputGroup.Addon>
          <AddCircle size="20" color="#98A2B3" />
        </InputGroup.Addon>
        <Input
          placeholder="Insira aqui o nome do novo marcador"
          className="search-tag-container_search-input"
          onKeyUp={(e) => onPressToCreateTag(e)}
          value={editTag}
          onChange={setEditTag}
          disabled={loading}
        />
      </InputGroup>
    </div>
  );
};
