import { useState, useRef } from 'react';
import { Input, InputGroup } from 'rsuite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Nav, SpinningLoader } from '../../../../../ds-components';

import {
  Add,
  ArchiveAdd,
  ArchiveTick,
  ArrowLeft2,
  SearchNormal,
} from 'iconsax-react';

import { Creators as ProfilesActions } from '../../../../../store/ducks/profiles';
import { Creators as CollectionsActions } from '../../../../../store/ducks/collections';
import RemoveCardFromCollectionButton from './RemoveCardFromCollectionButton';
import AddCardToCollectionButton from './AddCardToCollectionButton';
import CreateAndAddToCollection from './CreateAndAddToCollection';
import TuriviusPreloader from '../../../../../ui/loaders/TuriviusPreloader';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../components/ui/dialog';

const SaveCardToCollectionButton = ({
  cards,
  query,
  isItInACollection,
  theCollections,
  loadingTheCollections,
  isInMonitoring,
  getCollection,
}) => {
  const [searchString, setSearchString] = useState('');
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  const handleChangeNavigation = (index) => {
    setActiveIndex(index);
  };

  const checkIfAllCardIdsHaveInACollection = (cards, collection) => {
    const cardIds = cards?.map((card) => card.id);
    const collectionCardIds = collection.cards;
    return cardIds.every((cardId) => collectionCardIds.includes(cardId));
  };

  const theCollectionsFiltered = (collections) => {
    if (searchString === '') return collections;

    let collectionFilters = collections.filter((collection) =>
      collection.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          searchString
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    );

    const mapFromCollections = new Map(
      collectionFilters.map((collection) => [collection.id, collection]),
    );
    const uniqueCollections = [...mapFromCollections.values()];

    return uniqueCollections;
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) =>
        setOpen(isOpen ? isOpen : false, setSearchString(''))
      }
    >
      <DialogTrigger asChild>
        <Button
          className="save-collection-tour"
          appearance="secondary"
          size="sm"
          data-testid={isItInACollection}
          tooltipText={
            isItInACollection ? 'Decisão salva' : 'Salva a decisão na coleção'
          }
          icon={
            isItInACollection ? (
              <div>
                <ArchiveTick color="#32D583" size="19" variant="Bold" />
              </div>
            ) : (
              <div
                style={{ padding: 0 }}
                data-testid="card-not-saved-in-a-collection"
              >
                <ArchiveAdd color="#667085" size="19" />
              </div>
            )
          }
        >
          {isInMonitoring ? '' : 'Salvar'}
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col max-w-[600px] max-h-[calc(100%-48px)] translate-y-0 top-6">
        <DialogHeader>
          <DialogTitle className="save-collection_header font-semibold">
            Salvar em coleção
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col overflow-y-auto pr-3">
          <span>Escolha uma coleção para salvar, ou crie uma nova.</span>
          <Nav
            navItems={[
              { id: 1, title: `Buscar coleção` },
              {
                id: 2,
                title: 'Criar coleção',
                'data-testid': 'modal-create-collection-tab',
              },
            ]}
            activeTab={activeIndex}
            handleChange={handleChangeNavigation}
          />

          {activeIndex === 1 && (
            <div className="gap-6 flex flex-col">
              <InputGroup inside>
                <InputGroup.Button>
                  <SearchNormal size={12} color="#98A2B3" />
                </InputGroup.Button>
                <Input
                  autoFocus
                  placeholder="Buscar coleção"
                  size="sm"
                  value={searchString}
                  onChange={(event) => setSearchString(event)}
                />
              </InputGroup>
              <div className="flex flex-col min-h-28">
                {loadingTheCollections && (
                  <div className="absolute" style={{ left: '46%', top: '54%' }}>
                    <TuriviusPreloader />
                  </div>
                )}

                {!loadingTheCollections &&
                  theCollectionsFiltered(theCollections).length === 0 && (
                    <span>Você não tem coleções</span>
                  )}
                {!loadingTheCollections &&
                  theCollectionsFiltered(theCollections)?.map(
                    (collection, key) => (
                      <div
                        key={collection.id + ' ' + key}
                        className="save-collection_content-table-row"
                      >
                        <span>{collection.name}</span>
                        {checkIfAllCardIdsHaveInACollection(
                          cards,
                          collection,
                        ) ? (
                          <RemoveCardFromCollectionButton
                            noconfirm
                            cards={cards}
                            collectionId={collection.id}
                            context="Results"
                          />
                        ) : (
                          <AddCardToCollectionButton
                            cards={cards}
                            collection={collection}
                            context="Results"
                          />
                        )}
                      </div>
                    ),
                  )}
              </div>
            </div>
          )}

          {activeIndex === 2 && (
            <div className="mt-5">
              <CreateAndAddToCollection
                cards={cards}
                context="Results"
                query={query}
              />
            </div>
          )}
        </div>

        <DialogFooter className="">
          <Button
            appearance="secondary"
            size="md"
            fullWidth
            onClick={() => setOpen(false)}
            iconPosition="left"
            icon={<ArrowLeft2 size={16} color="#475467" />}
          >
            Fechar
          </Button>
          {loadingTheCollections ? (
            <Button
              appearance="primary"
              size="md"
              fullWidth
              block
              icon={<SpinningLoader color="white" size="sm" />}
            />
          ) : (
            <Button
              appearance="primary"
              size="md"
              fullWidth
              onClick={() => setOpen(false)}
              icon={<Add size={20} color="#FFFFFF" />}
            >
              Salvar
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  userCollections: state.collections.userCollections,
  requestingUserCollections: state.collections.requestingUserCollections,
  defaultCollectionSave: state.collections.defaultCollectionSave,
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, CollectionsActions, ProfilesActions),
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveCardToCollectionButton);
