import React from 'react';
import { Collapse } from 'react-collapse';
import { Turivius } from '../../../../../ui';
import { posthog } from '../../../../../requesters';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { Button, Tag } from '../../../../../ds-components';
import './collapsableSelect.scss';
class CollapsableSelect extends React.Component {
  state = {
    requesting: false,
    items: null,
    filters: null,
    selected: null,
    selectedAll: false,
    selectedEntities: [],
    collapse: false,
    isOpened: [],
    permissions: [],
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  unifyEntitiesPermissions(permissions) {
    const entities = {};

    let entitiesTributario = permissions['tributario']?.entidades;
    let entitiesTrabalhista = permissions['trabalhista']?.entidades;

    if (entitiesTributario) {
      Object.entries(entitiesTributario).forEach(([key, value]) => {
        entities[key] = value;
      });
    }

    if (entitiesTrabalhista) {
      Object.entries(entitiesTrabalhista).forEach(([key, value]) => {
        entities[key] = value;
      });
    }

    return entities;
  }

  componentDidMount() {
    const { options, filterReference, filtersProps, placeholder } = this.props;
    const permissions = this.unifyEntitiesPermissions(
      JSON.parse(localStorage.getItem('permissions')),
    );

    const thePermissions = permissions
      ? Object.keys(permissions)?.map((key) => Number(key))
      : permissions;

    this.setState({ permissions: thePermissions });

    this.mounted = true;

    this.getOptions('');
    if (this.mounted) {
      if (filterReference === 'entities') {
        this.setState({
          filters: filtersProps.filters[filterReference],
          selected: filtersProps.filters[filterReference]?.filter(
            (permission) => thePermissions.includes(permission),
          ),
          placeholder: placeholder,
        });
      } else {
        this.setState({
          filters: filtersProps.filters[filterReference],
          selected: filtersProps.filters[filterReference]
            ? filtersProps.filters[filterReference]
            : [],
          placeholder: placeholder,
        });
      }

      /*Verifica ao iniciar a pagina se estão todos selecionados*/
      if (filtersProps.filters[filterReference]?.length === options?.length) {
        this.setState({ selectedAll: true });
      }
    }
  }

  componentDidUpdate() {
    const propsFilters =
      this.props.filtersProps.filters &&
      this.props.filtersProps.filters[this.props.filterReference];
    const stateFilters = this.state && this.state.filters;
    if (!stateFilters && propsFilters && this.mounted) {
      this.setState({
        filters: [...propsFilters],
        selected: [...propsFilters],
      });
    } else if (
      stateFilters &&
      propsFilters &&
      stateFilters.length !== propsFilters.length &&
      this.mounted
    ) {
      this.setState({
        filters: null,
        selected: null,
      });
      setTimeout(() => {
        this.setState({
          filters: [...propsFilters],
          selected: [...propsFilters],
        });
      }, 100);
    } else if (
      Array.isArray(propsFilters) &&
      Array.isArray(stateFilters) &&
      this.mounted
    ) {
      let doUpdate = false;
      propsFilters.forEach((propFilter) => {
        const found = stateFilters.filter((stateFilter) => {
          return (
            (typeof stateFilter === 'object'
              ? parseInt(stateFilter.id)
              : parseInt(stateFilter)) ===
            (typeof propFilter === 'object'
              ? parseInt(propFilter.id)
              : parseInt(propFilter))
          );
        }).length;
        if (found === 0) {
          doUpdate = true;
        }
      });
      if (doUpdate && this.mounted) {
        this.setState({
          filters: null,
          selected: null,
        });
        setTimeout(() => {
          this.setState({
            filters: [...propsFilters],
            selected: [...propsFilters],
          });
        }, 100);
      }
    } else if (
      (propsFilters === undefined || propsFilters === null) &&
      (stateFilters === undefined ||
        stateFilters === null ||
        (Array.isArray(stateFilters) && stateFilters.length !== 0))
    ) {
      this.setState({
        filters: [],
        selected: [],
      });
    }
  }
  verifyOpitions = () => {
    const optionsSelectedProps =
      this.props.filtersProps.filters[this.props.filterReference];
  };
  getOptions = (str) => {
    if (this.mounted) {
      if (this.props.filterReference === 'entities') {
        this.setState({
          items: this.groupArray(this.props.filter?.options, 'class'),
        });
      } else {
        this.setState({ items: this.props.filter?.options });
      }
    }
  };
  groupArray = (arr, groupBy) => {
    /*SEGMENTATION */
    // const allowedEntities = this.props.permissions
    let groupedArray = [];
    let categories = {};

    arr.forEach((elem) => {
      categories[elem[groupBy]] = '';
    });
    Object.keys(categories).forEach((category, i) => {
      groupedArray.push([]);
      arr.forEach((elem) => {
        /*SEGMENTATION */
        // elem.enabled = allowedEntities[elem.id] ?? false
        // elem.enabled = true
        elem[groupBy] === category ? groupedArray[i].push(elem) : null;
      });
    });
    return groupedArray;
  };

  selectionChange = (e) => {
    const { filterReference } = this.props;
    let { selected } = this.state;
    let selection = e.target.value;
    let findSelection = selected.indexOf(parseInt(selection));

    if (findSelection === -1) {
      selected = [...selected, parseInt(selection)];
    } else {
      selected.splice(findSelection, 1);
    }
    this.setState({ selected: [...selected] });
    this.props.filtersProps.setTmpFilters(filterReference, [...selected]);

    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: filterReference,
      });
    }

    /*Verifica se todas as opções estão selecionadas ou não 
    para alterar o status do botão a cada opção selecionada*/
    if (selected?.length != this.props.options?.length) {
      this.setState({ selectedAll: false });
    } else {
      this.setState({ selectedAll: true });
    }
  };

  globalSelection = (action) => {
    let { selected, items, permissions } = this.state;
    const { filterReference } = this.props;

    if (action === 'remove') {
      selected = [];
    } else {
      selected = items?.map((item) => item.id);
    }

    if (filterReference === 'entities') {
      this.setState({
        /*SEGMENTATION */
        selected: [...selected].filter((permission) =>
          permissions.includes(permission),
        ),
      });
    } else {
      this.setState({ selected: [...selected] });
    }

    this.props.filtersProps.setTmpFilters(filterReference, [...selected]);
    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: filterReference,
      });
    }
  };

  selectionGroupEntities = (group, action) => {
    let { selected, selectedEntities, filterReference, permissions } =
      this.state;

    if (action === 'remove') {
      group?.map((item) => {
        let findSelection = selected.indexOf(item.id);
        if (findSelection === -1) {
          selected.push(item.id);
        }
      });
    } else {
      group?.map((item) => {
        let findSelection = selected.indexOf(item.id);

        if (findSelection !== -1) {
          selected.splice(findSelection, 1);
        }
      });

      this.setState({
        selected: [...selected].filter((permission) =>
          permissions.includes(permission),
        ),
      });

      this.props.filtersProps.setTmpFilters('entities', [...selected]);
    }

    this.setState({ selected: [...selected] });

    this.props.filtersProps.setTmpFilters(filterReference, [...selected]);
  };

  changeSelectionGroup = (group, action) => {
    let { selected, permissions } = this.state;

    for (let option of group) {
      let findSelection = selected.indexOf(option.id);
      if (findSelection === -1 && action === 'add') {
        selected.push(option.id);
      } else if (findSelection !== -1 && action === 'remove') {
        selected.splice(findSelection, 1);
        this.setState({ selectedAll: false });
      }
    }

    this.setState({
      selected: [...selected].filter((permission) =>
        permissions.includes(permission),
      ),
    });

    this.props.filtersProps.setTmpFilters('entities', [...selected]);

    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: 'entities',
      });
    }
  };

  verifyGroupSelection = (group) => {
    let { selected, permissions } = this.state;
    let allSelected = true;
    let allDisabled = true;

    const theSelected = selected.filter((permission) =>
      permissions.includes(permission),
    );

    for (let option of group.filter((permission) => {
      return permissions.includes(permission.id);
    })) {
      let verifySelection = theSelected.indexOf(option.id);

      if (verifySelection === -1) {
        allSelected = false;
        break;
      }
    }
    return allSelected
      ? {
          type: 'remove',
          label: 'Remover todos',
        }
      : {
          type: 'add',
          label: 'Selecionar todos',
        };
  };

  /**Ação do botão Selecionar/Remover todos */
  selectOption = () => {
    const { selectedAll, permissions } = this.state;
    const { options, filterReference } = this.props;

    let selectedsAll = [];

    if (selectedAll === false) {
      Object.keys(options)?.map((option) => {
        selectedsAll.push(options[option].id);
      });

      if (filterReference === 'entities') {
        this.setState({
          selectedAll: true,
          /*SEGMENTATION */
          selected: [...selectedsAll].filter((permission) =>
            permissions.includes(permission),
          ),
        });

        /*SEGMENTATION */
        this.props.filtersProps.setTmpFilters(
          filterReference,
          [...selectedsAll].filter((permission) =>
            permissions.includes(permission),
          ),
        );
      } else {
        this.setState({ selected: [...selectedsAll], selectedAll: true });
        this.props.filtersProps.setTmpFilters(filterReference, [
          ...selectedsAll,
        ]);
      }
    } else {
      this.setState({ selected: [], selectedAll: false });
      this.props.filtersProps.setTmpFilters(filterReference, []);
    }
  };

  checkIsOpened(group) {
    return !!this.state.isOpened.find((_group) => _group.id === group.id);
  }

  onOpened(group) {
    const isFinded = this.state.isOpened.find(
      (_group) => _group.id === group.id,
    );
    if (isFinded)
      return this.setState({
        isOpened: this.state.isOpened.filter(
          (_group) => _group.id !== group.id,
        ),
      });
    return this.setState({
      isOpened: [...this.state.isOpened, group],
    });
  }

  render() {
    const {
      items,
      requesting,
      selected,
      collapse,
      selectedAll,
      permissions: thePermissions,
    } = this.state;
    const { label, filterReference, filtersProps } = this.props;
    const { entities_counter } = filtersProps;

    return (
      <div className="tv-select_collapsable">
        <div className="tv-select_collapsable-container">
          <span className="font-medium">{label}</span>
          <Button
            size="sm"
            appearance="minimal"
            onClick={() => this.setState({ collapse: !collapse })}
          >
            {collapse ? (
              <ArrowDown2 color="#848FAC" size={16} />
            ) : (
              <ArrowUp2 color="#848FAC" size={16} />
            )}
          </Button>
        </div>

        {
          requesting ? (
            <Turivius.SimplePreloader />
          ) : items && selected ? (
            // !collapse ?
            <div
              className={
                filterReference === 'entities'
                  ? collapse
                    ? 'entities close'
                    : ''
                  : `tv-select_collapsable-options ${collapse ? 'close' : ''}`
              }
            >
              <button
                type="button"
                className="tv-select_select-all-btn"
                onClick={() => this.selectOption(filterReference)}
              >
                {selectedAll ? 'Remover todos' : 'Selecionar todos'}
              </button>

              {filterReference === 'entities'
                ? items?.map((group, i) => {
                    return (
                      <div
                        className="checkbox-group-category-container-row-block"
                        key={group[i].id}
                      >
                        <div
                          className="header"
                          onClick={() => this.onOpened(group[i])}
                        >
                          <span>{group[i].class}</span>

                          {this.checkIsOpened(group[i]) ? (
                            <ArrowUp2 color="#848FAC" size={12} />
                          ) : (
                            <ArrowDown2 color="#848FAC" size={12} />
                          )}
                        </div>
                        <Collapse isOpened={this.checkIsOpened(group[i])}>
                          <a
                            onClick={() =>
                              this.changeSelectionGroup(
                                group,
                                this.verifyGroupSelection(group).type,
                              )
                            }
                            type="button"
                            className="tv-select_select-all-link"
                          >
                            {this.verifyGroupSelection(group).label}
                          </a>

                          {group?.map((elem, j) => (
                            <div
                              key={`${j}${elem.id}_${elem.name}`}
                              className="item-collapse-entities"
                              id={elem.id}
                            >
                              <input
                                type="checkbox"
                                name={`Checkbox_${filterReference}_${elem.id}`}
                                id={`Checkbox_${filterReference}_${elem.id}`}
                                key={`Checkbox_${filterReference}_${elem.id}`}
                                value={`${elem.id}`}
                                disabled={!thePermissions.includes(elem.id)}
                                onChange={this.selectionChange}
                                checked={
                                  selected
                                    ? selected.filter((option) =>
                                        typeof option === 'object'
                                          ? option.id === elem.id
                                          : option === elem.id,
                                      ).length > 0
                                    : false
                                }
                              />

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <label
                                  style={{
                                    cursor: 'pointer',
                                    height: 'fit-content',
                                  }}
                                  htmlFor={`Checkbox_${filterReference}_${elem.id}`}
                                >
                                  {filterReference === 'entities'
                                    ? elem.initials
                                    : elem.name}
                                </label>
                                <p className="tv-select_counter-entity">
                                  {entities_counter && entities_counter[elem.id]
                                    ? `${entities_counter[elem.id]} resultados`
                                    : ''}
                                </p>
                              </div>

                              <div className="item-collapse-entities_flags">
                                {elem.is_new ? (
                                  <Turivius.TuriviusTooltip
                                    trigger={'hover'}
                                    message={'Base de dados nova!'}
                                    placement={'right'}
                                  >
                                    {/* <Tag type="default" color="primary" size="sm">Novo</Tag> */}
                                    <em className="item-collapse-entities_flags new">
                                      Novo
                                    </em>
                                  </Turivius.TuriviusTooltip>
                                ) : null}

                                {thePermissions.includes(elem.id) ? null : (
                                  <Turivius.TuriviusTooltip
                                    trigger={'hover'}
                                    message={
                                      'Clique em fazer upgrade do seu plano e entre em contato com nosso atendimento'
                                    }
                                    placement={'right'}
                                  >
                                    <em
                                      onClick={() =>
                                        window.open(
                                          `${window.location.href}#hs-chat-open`,
                                          '_self',
                                        )
                                      }
                                      className="item-collapse-entities_flags upgrade"
                                    >
                                      Upgrade
                                    </em>
                                    {/* <Tag type="default" color="orange" size="sm">Upgrade</Tag> */}
                                  </Turivius.TuriviusTooltip>
                                )}
                              </div>
                            </div>
                          ))}
                        </Collapse>
                      </div>
                    );
                  })
                : items?.map((elem, j) => {
                    return (
                      <div key={elem.id}>
                        <input
                          type="checkbox"
                          name={`Checkbox_${filterReference}_${elem.id}`}
                          id={`Checkbox_${filterReference}_${elem.id}`}
                          key={`Checkbox_${filterReference}_${elem.id}`}
                          value={`${elem.id}`}
                          onChange={this.selectionChange}
                          checked={
                            selected
                              ? selected.filter((option) =>
                                  typeof option === 'object'
                                    ? option.id === elem.id
                                    : option === elem.id,
                                ).length > 0
                              : false
                          }
                        ></input>
                        <label
                          htmlFor={`Checkbox_${filterReference}_${elem.id}`}
                        >
                          {elem.name}
                        </label>
                      </div>
                    );
                  })}
            </div>
          ) : null
          // : null
        }
      </div>
    );
  }
}

export default CollapsableSelect;
