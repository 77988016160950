import React from 'react';
import { Provider } from 'react-redux';
import { posthog, requester } from '../requesters';
import { postHubSpotEvent } from '../services/hubspot';
import ptBR from 'rsuite/locales/pt_BR';

import {
  Switch,
  Route,
  withRouter,
  Redirect as RouteRedirect,
} from 'react-router-dom';

import store from '../store';
import disconnect_steps from '../config/disconnect-steps';
import Collection from '../modules/collections/pages/Collection';
import Collections from '../modules/collections/pages/Collections';
import Login from '../modules/account/loginv6/pages/Login';
import ResetPassword from '../modules/account/loginv6/pages/ResetPassword';
import Logout from '../modules/account/login/pages/Logout';
import NotFound from '../components/pages/404';
import ResultPage from '../modules/jurisprudential-search/pages/results/ResultPage';
import Redirect from '../components/pages/Redirect';
import RedirectFirstLogin from '../components/pages/RedirectFirstLogin';
import UnsubscribeMonitoring from '../modules/monitoring/pages/UnsubscribeMonitoring';
import Search from '../modules/jurisprudential-search/pages/search/Search';
import Admin from '../modules/account/admin/pages/Administration';
import SSO from '../modules/account/login/pages/SSO';

import DevicesCard from '../components/DevicesCard/DevicesCard';
import JurimetriaTrabalhista from '../modules/jurimetric-trabalhista/pages/JurimetriaTrabalhista';
import DashJurimetriaTrabalhista from '../modules/jurimetric-trabalhista/pages/DashJurimetriaTrabalhista';
import NotificationPopUp from '../modules/account/notifications/components/Notifications/NotificationPopUp';
import PrivateRoute from './PrivateRoute';
import TuriviusBasePage from '../components/organisms/TuriviusBasePage';
import TrialPage from '../components/pages/TrialPage';

import './App.scss';
import '../modules/account/notifications/components/Notifications/style.scss';

import { CustomProvider, Modal, Button, Input } from 'rsuite';

import DashEmpresarial from '../modules/business-dashboard/pages/DashEmpresarial';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Profile from '../modules/account/profile/pages/Profile';
import Monitoring from '../modules/monitoring/pages/Monitoring';
import ConsultCard from '../modules/jurisprudential-search/pages/search/ConsultCard';
import Setup from '../modules/setup/pages/Setup';
import OverviewDash from '../modules/business-dashboard/pages/overview/OverviewDash';
import SubjectModal from '../modules/business-dashboard/pages/subject/SubjectModal';
import SubjectDash from '../modules/business-dashboard/pages/subject/SubjectDash';
import { QueryProvider } from '../providers/query.provider';
import { ToastContainer } from 'react-toastify';
import FirstLogin from '../modules/account/loginv6/pages/FirstLogin';
import { MicrosoftSocialLoginProvider } from '../providers/microsoft-social-login.provider';

export const TURIVIUS_VERSION = '4.3.3';

let finger = '';
let os = '';
try {
  const fpPromise = FingerprintJS.load();
  (async () => {
    const fp = await fpPromise;
    const result = await fp.get();
    finger = result.visitorId;
    os = result.components.platform.value;
    requester.setUserFinger(finger);
  })();
} catch {
  console.log('erro');
}

requester.init(localStorage.getItem('token'), {
  webservice: process.env.REACT_APP_WEBSERVICE,
  cards: process.env.REACT_APP_CARDS,
  collections: process.env.REACT_APP_COLLECTIONS,
  docs: process.env.REACT_APP_DOCS,
  jurimetrics: process.env.REACT_APP_JURIMETRICS,
  jurimetrics_trabalhista: process.env.REACT_APP_JURIMETRICS_TRABALHISTA,
  profiles: process.env.REACT_APP_PROFILES,
  help: process.env.REACT_APP_HELP,
  notifications: process.env.REACT_APP_NOTIFICATIONS,
  notifications_ws: process.env.REACT_APP_NOTIFICATIONS_WS,
  search_notifications: process.env.REACT_APP_SEARCH_NOTIFICATIONS,
  monitoring: process.env.REACT_APP_MONITORING,
  rdstation: process.env.REACT_APP_RDSTATION,
  segmentation: process.env.REACT_APP_SEGMENTATION,
  reports: process.env.REACT_APP_REPORTS,
  sso: process.env.REACT_APP_SSO,
  sla_jurimetric: process.env.REACT_APP_SLA_JURIMETRIC,
  block: process.env.REACT_APP_BLOCK,
  nestle: process.env.REACT_APP_NESTLE,
});

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY);

class App extends React.Component {
  state = {
    open: false,
    open_sessions: false,
    sessions: [],
    disconnect_device: '',
    disconnect_step: 0,
    disconnect_code: '',
    valid_code: true,
  };

  browserVersion = () => {
    const { userAgent } = navigator;

    if (userAgent.includes('Firefox')) {
      return 'Firefox';
    } else if (userAgent.includes('Edg/')) {
      return 'Edge';
    } else if (userAgent.includes('Chrome/')) {
      return 'Chrome';
    } else if (userAgent.includes('Safari/')) {
      return 'Safari';
    }
  };

  osVersion = (os) => {
    if (os.includes('Win')) {
      return 'Windows';
    } else if (os.includes('Mac')) {
      return 'macOS';
    } else if (os.includes('Linux')) {
      return 'Linux';
    } else {
      return os;
    }
  };

  UNSAFE_componentWillMount() {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (!profile) return;
    if (profile?.company.id !== 2) {
      this.unlisten = this.props.history.listen((l, a) => {
        requester.block.valid(finger).then((response) => {});
        requester.block
          .sessionStore(finger, this.osVersion(os), this.browserVersion())
          .then()
          .catch((err) => {
            posthog.capture('multiple_users');
            postHubSpotEvent('app_multipleusers', {
              app_multipleusers: 'app_multipleusers',
            });

            err.status === 403 ? this.setState({ open_sessions: true }) : null;

            requester.block.sessionManager().then((response) => {
              this.setState({ sessions: response });
            });
          });
        requester.block
          .valid(finger)
          .then((response) => {})
          .catch((err) =>
            err.status === 403 ? this.setState({ open: true }) : null,
          );
      });
    }
  }

  componentWillUnmount() {
    if (
      !this.props.location.pathname.includes('/login') &&
      !this.props.location.pathname.includes('/sso') &&
      !this.props.location.pathname.includes('/logout') &&
      !this.props.location.pathname.includes('/')
    ) {
      this.unlisten();
    }
  }

  close = () => {
    this.setState({ open: false });
    window.location.href = '/logout';
  };

  getFirstName = (data) => {
    if (data !== undefined) {
      return data.split(' ')[0];
    } else {
      return '';
    }
  };

  componentDidMount = async () => {
    const theSessionStorageClear = localStorage.getItem(
      'theSessionStorageClear',
    );
    if (!theSessionStorageClear) {
      sessionStorage.clear();
      localStorage.setItem('theSessionStorageClear', true);
    }

    if (
      this.props.location.pathname.includes('/login') ||
      this.props.location.pathname.includes('/sso') ||
      this.props.location.pathname.includes('/redirect') ||
      this.props.location.pathname.includes('/index.html')
    ) {
      localStorage.setItem('TURIVIUS_VERSION', String(TURIVIUS_VERSION));
    } else if (
      String(localStorage.TURIVIUS_VERSION) !== String(TURIVIUS_VERSION)
    ) {
      this.props.history.push('/logout');
    }

    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : null;

    const permissions = localStorage.getItem('permissions')
      ? JSON.parse(localStorage.getItem('permissions'))
      : null;

    if (profile) {
      var url_asaas = true;

      if (!profile.company.asaas_url) {
        url_asaas = false;
      }

      if (window.satismeter) {
        window.satismeter({
          writeKey: 'BOPCaSo62Birj8Id',
          userId: profile.id,
          traits: {
            name: profile.name,
            email: profile.email,
            createdAt: profile.created,
            company: profile.company.id,
            companyName: profile.company.name,
            active: profile.active,
          },
        });
      }
    }
  };

  disconnect = (fingerprint, email) => {
    this.setState({ disconnect_device: fingerprint });
    this.setState({ disconnect_step: 1 });
    requester.block.sessionDeletionCode(fingerprint, email).then();
  };

  confirmDisconnect = (fingerprint, code) => {
    requester.block
      .sessionDeletion(fingerprint, code)
      .then((response) => {
        this.setState({ disconnect_step: 2 });
        setTimeout(() => {
          this.setState({ open_sessions: false });
        }, 3000);
      })
      .catch((err) =>
        err.status === 403 ? this.setState({ valid_code: false }) : null,
      );
  };

  maskEmail = (email) => {
    const [name, domain] = email.split('@');
    const maskedName = name.substring(0, 3) + '****' + name[name.length - 1];
    const maskedEmail = maskedName + '@' + domain;
    return maskedEmail;
  };

  checkAction = (action) => {
    switch (action) {
      case 'back':
        return this.close();
      case 'disconnect':
        return this.confirmDisconnect(
          this.state.disconnect_device,
          this.state.disconnect_code,
        );
      case 'close':
        return this.setState({ open_sessions: false });
    }
  };

  render() {
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : null;
    const permissions = localStorage.getItem('permissions')
      ? JSON.parse(localStorage.getItem('permissions'))
      : null;

    return (
      <QueryProvider>
        <Provider store={store}>
          <CustomProvider locale={ptBR}>
            <div className="App">
              <Modal
                size="lg"
                overflow={false}
                keyboard={false}
                open={this.state.open_sessions}
                className="modal-sessions"
              >
                <Modal.Header
                  className={
                    'modal-header ' +
                    disconnect_steps[this.state.disconnect_step].headerColor
                  }
                  closeButton={false}
                >
                  <h6>
                    <i class="ri-alert-fill ri-lg"></i>{' '}
                    {disconnect_steps[this.state.disconnect_step].title}
                  </h6>
                </Modal.Header>
                <Modal.Body>
                  <div className="modal-content">
                    <h5 className="modal-title">
                      Desconecte sua conta de um dos dispositivos abaixo para
                      continuar.
                    </h5>
                    {this.state.disconnect_step != 1 ? (
                      <div>
                        <p className="modal-text">
                          Verificamos que o seu usuário foi conectado a mais
                          dispositivos do que o permitido. Desconecte um dos
                          dispositivos para acessar sua conta.
                        </p>
                        <p className="modal-text">
                          Você tentou conectar com o email do usuário{' '}
                          <b>{profile?.email}</b>.{' '}
                          <span className="blue" onClick={() => this.close()}>
                            Faça o login com um usuário diferente aqui.
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p>
                          Para confirmar, digite o código de validação enviado
                          para o email <b>{profile?.email}</b>
                        </p>

                        <div className="code-input">
                          <b>Cole o código aqui</b>
                          <Input
                            className="rs-custom-input-code"
                            onChange={(value) =>
                              this.setState({ disconnect_code: value })
                            }
                            size="lg"
                            style={{ width: '30%' }}
                          />
                          {this.state.valid_code ? null : (
                            <span style={{ color: '#F97066' }}>
                              Insira um código válido!
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="sessionCards">
                      <DevicesCard />
                      {this.state.sessions?.map((session) => (
                        <DevicesCard
                          key={session.fingerprint}
                          session={session}
                          onDisconnect={() =>
                            this.disconnect(
                              session.fingerprint,
                              profile?.company.email,
                            )
                          }
                        />
                      ))}
                    </div>
                    <div className="actions">
                      <p>
                        Ficou com alguma dúvida? Entre em contato conosco pelo
                        email <b>suporte@turivius.com</b>
                      </p>
                      <Button
                        onClick={() =>
                          this.checkAction(
                            disconnect_steps[this.state.disconnect_step].action,
                          )
                        }
                        className="btn-login entendi"
                      >
                        {disconnect_steps[this.state.disconnect_step].button}{' '}
                        <i
                          class={
                            disconnect_steps[this.state.disconnect_step].icon +
                            ' ri-lg'
                          }
                        ></i>
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {this.props.location.pathname !== '/login' &&
              this.props.location.pathname !== '/reload' &&
              this.props.location.pathname !== '/index.html' &&
              this.props.location.pathname !== '/pagamento' &&
              this.props.location.pathname !== '/redirect' ? (
                <>
                  <NotificationPopUp />
                </>
              ) : null}
              <TuriviusBasePage>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/sso" component={SSO} />
                  <Route exact path="/nova-senha" component={ResetPassword} />
                  <Route exact path="/primeiro-acesso" component={FirstLogin} />
                  <Route path="/reload" component={null} key="reload" />
                  <Route path="/index.html" component={RedirectFirstLogin} />
                  <Route path="/redirect" component={Redirect} />
                  <Route path="/trial" component={TrialPage} />

                  <PrivateRoute
                    exact
                    path="/"
                    component={({ location }) => {
                      return <RouteRedirect to={`/pesquisa`} />;
                    }}
                  />
                  <PrivateRoute
                    exact
                    path="/home"
                    component={({ location }) => {
                      return <RouteRedirect to={`/pesquisa`} />;
                    }}
                  />
                  <PrivateRoute
                    index
                    exact
                    path="/pesquisa"
                    component={Search}
                  />
                  <PrivateRoute exact path="/resultados">
                    {({ location }) => {
                      return (
                        <RouteRedirect
                          to={`/pesquisa/resultados${location.search}`}
                        />
                      );
                    }}
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/pesquisa/resultados"
                    component={ResultPage}
                  />

                  <PrivateRoute
                    path="/resultados/card/"
                    component={ConsultCard}
                  />

                  <PrivateRoute
                    exact
                    path="/admin"
                    component={profile?.is_admin ? Admin : NotFound}
                  />
                  <PrivateRoute exact path="/setup" component={Setup} />
                  <PrivateRoute exact path="/perfil" component={Profile} />

                  <PrivateRoute
                    exact
                    path="/colecoes"
                    component={Collections}
                  />

                  <PrivateRoute
                    exact
                    path="/colecoes/:collectionId"
                    component={Collection}
                  />

                  <PrivateRoute
                    exact
                    path="/monitoramento"
                    component={Monitoring}
                  />

                  <PrivateRoute
                    exact
                    path="/dashboard-empresarial"
                    component={DashEmpresarial}
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard-empresarial/visao-geral"
                    component={OverviewDash}
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard-empresarial/visao-assunto"
                    component={SubjectModal}
                  />

                  <PrivateRoute
                    // exact
                    path="/dashboard-empresarial/visao-assunto/:assunto"
                    component={SubjectDash}
                  />

                  <PrivateRoute exact path="/jurimetria-trabalhista">
                    {permissions?.trabalhista?.plano_alias ? (
                      <JurimetriaTrabalhista />
                    ) : (
                      <RouteRedirect to="/teste-gratuito-trabalhista" />
                    )}
                  </PrivateRoute>

                  <PrivateRoute exact path="/jurimetria-trabalhista/busca">
                    {permissions?.trabalhista?.plano_alias ? (
                      <DashJurimetriaTrabalhista />
                    ) : (
                      <RouteRedirect to="/teste-gratuito-trabalhista" />
                    )}
                  </PrivateRoute>

                  <PrivateRoute exact path="/busca">
                    {({ location }) => {
                      return (
                        <RouteRedirect
                          to={`/jurimetria-trabalhista/busca${location.search}`}
                        />
                      );
                    }}
                  </PrivateRoute>

                  <Route exact path="/logout" component={Logout} />
                  <Route
                    exact
                    path="/unsubscribe-monitoring"
                    component={UnsubscribeMonitoring}
                  />
                  <Route component={NotFound} />
                </Switch>
              </TuriviusBasePage>
            </div>
            <ToastContainer position="bottom-right" />
          </CustomProvider>
        </Provider>
      </QueryProvider>
    );
  }
}

export default withRouter(App);
