import { useEffect, useState } from 'react';
import DatePickerFilter from './Filters/DatePickerFilter';
import CollapsableSelect from '../Inputs/CollapsableSelect';
import MultiSelect from '../Inputs/MultiSelect';

const FilterWrapper = ({
  filter,
  tmpFilters,
  context,
  filtersProps,
  entities,
}) => {
  const renderFilterType = (filterType) => {
    switch (filterType) {
      case 'multiple_autocomplete':
        return (
          <MultiSelect
            label={filter.public_name}
            filter={filter}
            filterReference={filter.field_name}
            filtersProps={filtersProps}
            placeholder={'Filtre por relatores'}
            context={context}
            name={'relator'}
          />
        );
      case 'multiple_select':
        return (
          <CollapsableSelect
            label={filter.public_name}
            filtersProps={filtersProps}
            context={context}
            filter={filter}
            filterReference={filter.field_name}
            labelKey="name"
            options={filter.options}
          />
        );
      case 'date':
        return (
          <DatePickerFilter
            setTmpFilters={filtersProps.setTmpFilters}
            filters={tmpFilters}
            context={context}
            filter={filter}
            variant="outlined"
            label={filter.public_name}
          />
        );
      case 'multiple':
        return (
          <CollapsableSelect
            label={filter.public_name}
            filtersProps={filtersProps}
            context={context}
            filter={filter}
            filterReference={'entities'}
            labelKey="entities"
            options={entities}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    renderFilterType(filter.type);
  }, []);

  return renderFilterType(filter.type) ? (
    <div className="tv-filter_group">{renderFilterType(filter.type)}</div>
  ) : null;
};

export default FilterWrapper;
