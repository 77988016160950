import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { posthog } from '../../../../../requesters';
import { Button } from '../../../../../ds-components';
import { postHubSpotEvent } from '../../../../../services/hubspot';

const ReadMoreOrLessButton = ({ showAllText, setShowAllText, cardId }) => {
  return (
    <Button
      appearance="primary"
      size="sm"
      icon={
        showAllText ? (
          <ArrowUp2 size={16} color="#FFFFFF" />
        ) : (
          <ArrowDown2 size={16} color="#FFFFFF" />
        )
      }
      iconPosition="right"
      onClick={() => {
        setShowAllText(!showAllText);
        posthog.capture('open_details', {
          action: 'open_details',
          id: cardId,
        });
        postHubSpotEvent('app_opendetails', { app_opendetails: cardId });
      }}
    >
      {showAllText ? 'Ler menos' : 'Ler mais'}
    </Button>
  );
};

export default ReadMoreOrLessButton;
