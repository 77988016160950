import { useEffect, useRef, useState, useMemo } from 'react';
import { Grid, Row, Message, toaster, FlexboxGrid, Pagination } from 'rsuite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { posthog, requester } from '../../../requesters';

import { Creators as JurimetricsActions } from '../../../store/ducks/jurimetrics';
import { Creators as ResultsActions } from '../../../store/ducks/searchTributario';

import { CollectionSkeleton } from '../../../components/Skeleton';
import CollectionHeader from '../components/Collection/CollectionHeader/CollectionHeader';
import TuriviusPreloader from '../../../app/TuriviusPreloader';
import CollectionsResultCard from '../components/Collections/CollectionsResultCard';
import FlagSystem from '../../../components/FlagSystem';

import './Collection.scss';
import EmptyCollection from '../components/Collection/EmptyCollection/EmptyCollection';
import { useCollections } from '../../../store/ducks/theCollections';

const Collection = ({ ...props }) => {
  const LIMIT = 10;
  const { collections: theCollections, fetch: fetchCollections } =
    useCollections();

  const reference = useRef(null);
  const triggerSuccessChecklist = useRef(null);

  const [collection, setCollection] = useState({});
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [activePage, setActivePage] = useState(1);

  const [requestingComments, setRequestingComments] = useState(true);
  const [comments, setComments] = useState(null);
  const [countTags, setCountTags] = useState(0);
  const [
    theMatchResultsThatHaveCollections,
    setTheMatchResultsThatHaveCollections,
  ] = useState([]);

  const openSuccessChecklist = () => triggerSuccessChecklist.current?.open();

  const getEntities = async () => {
    let entity = [];

    await requester.cards
      .getEntities()
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch(console.error);

    await requester.cards
      .getEntities('trabalhista')
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch(console.error);

    setEntities(entity);
  };

  const getTag = () => {
    const collectionIdURL = props.location.pathname.split('/').pop();

    requester.collections
      .getTagsByCollection(collectionIdURL)

      .then((response) => {
        setCountTags(response.length);
      })
      .catch((err) => console.log(err));
  };

  const verifyAndGetEntities = async () => {
    let entity = [];

    await requester.cards
      .getEntities()
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });

    await requester.cards
      .getEntities('trabalhista')
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });

    setEntities(entity);
    props.setEntities(entity);
  };

  const getCollection = (page = 1, offSetPage = LIMIT) => {
    setLoading(true);

    const collectionIdURL = props.location.pathname.split('/').pop();
    let cardsGroup = [],
      sizePages = 0;

    requester.collections
      .getCollection(localStorage.getItem('user'), collectionIdURL)
      .then((response) => {
        setCollection(response);

        sizePages = Math.ceil(response.cards.length / offSetPage, -1);
        cardsGroup = response.cards.slice(
          offSetPage * page - offSetPage,
          offSetPage * page,
        );

        requester.cards
          .getCards(cardsGroup)
          .then((response_cards) => {
            response.cards.forEach((card, idx) => {
              response_cards.cards.forEach((rcard) => {
                if (parseInt(rcard.id) === parseInt(card)) {
                  rcard.idx = idx;
                }
              });
            });

            setCards(response_cards.cards);
          })
          .catch((err) => {
            console.error(err);
            toaster.push(
              <Message
                header="Ocorreu um erro"
                duration={4000}
                type="error"
                showIcon
                closable
              >
                Houve um problema de conexão, tente novamente
              </Message>,
            );
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });
  };

  const getCollectionComments = (collectionId) => {
    setRequestingComments(true);
    requester.collections
      .getCollectionComments(collectionId)
      .then((response) => {
        setComments(response);
      })
      .catch(console.error)
      .finally(() => setRequestingComments(false));
  };

  const loadComponent = () => {
    const collectionIdURL = props.location.pathname.split('/').pop();
    setLoading(true);

    verifyAndGetEntities();
    getCollection();
    getCollectionComments(collectionIdURL);
  };

  const addComment = (cardId, comment) => {
    setComments({
      ...comments,
      [cardId]: comments[cardId] ? [...comments[cardId], comment] : [comment],
    });
  };

  const removeComment = (cardId, commentId) => {
    setComments({
      ...comments,
      [cardId]: comments[cardId].filter((comment) => comment.id !== commentId),
    });
  };

  const loadMore = (page) => {
    setActivePage(page);
    getCollection(page, LIMIT);
  };

  useMemo(async () => {
    if (!cards.length) return;
    const cardIds = cards?.map((card) => card.id);
    const response =
      await requester.collections.matchResultsThatHaveCollections(
        encodeURIComponent(JSON.stringify(cardIds)),
      );
    const theResponse = [...new Set(response)];
    setTheMatchResultsThatHaveCollections(theResponse);
  }, [theCollections, cards]);

  useEffect(() => {
    posthog.capture('$pageview');

    const collectionIdURL = props.location.pathname.split('/').pop();
    document.title = `Coleção #${collectionIdURL}`;

    getEntities();
    getTag();
    loadComponent();
    fetchCollections();
  }, []);

  return (
    <div>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item colspan={24}>
          <FlagSystem
            current="collection"
            titleBreadCrumb={collection.name}
            additionalRoute={`${collection.id} - ${collection.name} `}
            openSuccessChecklist={openSuccessChecklist}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={24} id="collection-cont">
          {loading ? (
            <div className="collection-section">
              <div className="collection-container">
                <TuriviusPreloader backdrop />
                <div style={{ padding: 20 }}>
                  <Grid fluid>
                    <Row>
                      <CollectionSkeleton />
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          ) : (
            <Grid fluid style={{ padding: 20 }}>
              <Row style={{ paddingBottom: 10 }}>
                <CollectionHeader
                  collection={collection}
                  countTags={countTags}
                  reloadAction={loadComponent}
                />
              </Row>

              <Row>
                {collection.cards.length > 0 ? (
                  <div
                    id="collection-infinite-scroll"
                    ref={reference}
                    style={{
                      minHeight: 400,
                      overflow: 'auto',
                    }}
                  >
                    {cards &&
                      cards?.map((card) => (
                        <CollectionsResultCard
                          key={card.id}
                          card={card}
                          reloadExportStatus={getTag}
                          collectionId={props.location.pathname
                            .split('/')
                            .pop()} //Validar para refazer isso, não está da melhor maneira possivel
                          collection={collection}
                          entities={entities}
                          entity={
                            entities.filter(
                              (item) => item.id === card.entity,
                            )[0]
                          }
                          requestingComments={requestingComments}
                          comments={
                            comments
                              ? comments[card.id]
                                ? comments[card.id]
                                : []
                              : comments
                          }
                          addComment={addComment}
                          removeComment={removeComment}
                          context={'Single Collection'}
                          theCollections={theCollections}
                          theMatchResultsThatHaveCollections={
                            theMatchResultsThatHaveCollections
                          }
                        />
                      ))}

                    <Pagination
                      prev
                      next
                      first
                      last
                      total={collection.cards.length}
                      activePage={activePage}
                      onChangePage={(page) => loadMore(page)}
                      limit={LIMIT}
                      maxButtons={10}
                    />
                  </div>
                ) : (
                  <EmptyCollection />
                )}
              </Row>
            </Grid>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {/*<SuccessChecklistButton openSuccessChecklist={triggerSuccessChecklist} />*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.profiles.permissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, JurimetricsActions, ResultsActions),
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
