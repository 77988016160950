import TuriviusSidenav from '../TuriviusSidenav';
import { Container, Content } from 'rsuite';
import '../../../ds-components/styles/index.jsx.css';
import 'rsuite/dist/rsuite.min.css';
import './style.scss';

const TuriviusBasePage = ({ children }) => {
  return (
    <Container style={{ minHeight: '100vh' }}>
      <TuriviusSidenav />
      <Content>{children}</Content>
    </Container>
  );
};

export default TuriviusBasePage;
