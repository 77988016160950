import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import {
  Button,
  Modal,
  Message,
  Grid,
  Col,
  toaster,
  Steps,
  Toggle,
} from 'rsuite';
import { requester } from '../../../../requesters';
import { Tag } from '../../../../ds-components';
import { Star1 } from 'iconsax-react';

import TermLabelMonitoring from '../../components/MonitoringConfigs/TermLabelMonitoring';
import SelectFrequencyMonitoring from '../../components/MonitoringConfigs/SelectFrequencyMonitoring';
import ToggleEmailMonitoring from '../../components/MonitoringConfigs/ToggleEmailMonitoring';
import ToggleSendNotificationMonitoring from '../../components/MonitoringConfigs/ToggleSendNotificationMonitoring';
import SelectEntitiesMonitoring from '../../components/MonitoringConfigs/SelectEntitiesMonitoring';
import DefaultOperatorFilter from '../../../jurisprudential-search/components/results/FilterList/Filters/DefaultOperatorFilter';
import SingleFilter from '../../../jurisprudential-search/components/results/FilterList/Filters/SingleFilter';
import SelectSharePeopleMonitoring from '../../components/MonitoringConfigs/SelectSharePeopleMonitoring';
import InteiroTeorFilter from '../MonitoringConfigs/MonitoringFilters/InteiroTeorFilter';
import SynonymsFilter from '../MonitoringConfigs/MonitoringFilters/SynonymsFilter';
import { updateCheckllist } from '../../../../services/checklist';

const ToggleMonitoring = ({ entities, ...props }) => {
  const { search } = useLocation();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [stepStatus, setStepStatus] = useState('process');
  const [monitoringId, setMonitoringId] = useState('');

  const [term, setTerm] = useState('');
  const [termError, setTermError] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [sendNotification, setSendNotification] = useState(true);
  const [allEntities, setAllEntities] = useState(true);
  const [frequency, setFrequency] = useState('D');
  const [monitoringWeekDay, setMonitoringWeekDay] = useState(null);
  const [monitoringMonthDay, setMonitoringMonthDay] = useState(null);
  const [selectedEntities, setSelectedEntities] = useState([]);

  const [defaultOperator, setDefaultOperator] = useState('e');
  const [synonymsFilter, setSynonymsFilter] = useState(true);
  const [inteiroTeorFilter, setInteiroTeorFilter] = useState([
    'ementa',
    'full_text',
  ]);
  const [singleFilter, setSingleFilter] = useState(1);
  const [resultDecisionFilter, setResultDecisionFilter] = useState([
    0, 1, 2, 3, 4,
  ]);
  const [filterByVote, setFilterByVote] = useState([]);
  const [filterBySecaoCARF, setFilterBySecaoCARF] = useState([]);
  const [filterByOrgaoJulgadorSTF, setFilterByOrgaoJulgadorSTF] = useState([]);

  const [sharedWith, setSharedWith] = useState([]);

  const handleModal = () => setModal(!modal);

  const createMonitoring = () => {
    setLoading(true);

    let userId = localStorage.getItem('user'),
      companyId = localStorage.getItem('company');

    let filters = {
      entity: selectedEntities,
      sort: singleFilter,
      filter_by_favorability: resultDecisionFilter,
      default_operator: defaultOperator,
      fields: inteiroTeorFilter,
      synonyms: synonymsFilter,
      filter_by_vote: filterByVote,
      filter_by_secao_carf: filterBySecaoCARF,
      filter_by_orgao_julgador_stf: filterByOrgaoJulgadorSTF,
    };

    requester.monitoring
      .addMonitoringTerm(
        term,
        frequency,
        sendEmail,
        sendNotification,
        allEntities,
        filters,
        sharedWith,
        monitoringWeekDay,
        monitoringMonthDay,
        userId,
        companyId,
        'Result',
      )
      .then((response) => {
        updTour();
        toaster.push(
          <Message
            showIcon
            closable
            header="Monitoramento Criado"
            type="success"
            duration={4000}
          >
            {`Termo ${term} monitorado com sucesso!`}
          </Message>,
        );

        handleModal(false);
        setSettingsDefault();
        verifyTermMonitoring();
      })
      .catch((err) => {
        let message = {
          header: '',
          type: '',
          message: '',
        };

        if (err.status === 400) {
          message.header = 'Atenção';
          message.type = 'warning';
          message.header = `O termo ${term} já está sendo monitorado.`;
        } else {
          message.header = 'Erro ao criar monitoramento';
          message.type = 'error';
          message.header = 'Houve um problema de conexão, tente novamente';
        }

        toaster.push(
          <Message
            showIcon
            closable
            header={message.header}
            type={message.type}
            duration={4000}
          >
            {message.message}
          </Message>,
        );
      })
      .finally(() => setLoading(false));
  };

  const stopMonitoring = () => {
    setLoading(true);
    requester.monitoring
      .removeMonitoringTerm(monitoringId, 'Result')
      .then((response) => {
        setMonitoringId(null);
        toaster.push(
          <Message
            closable
            showIcon
            header="Monitoramento Desabilitado"
            type="success"
            duration={4000}
          >
            Monitoramento desabilitado com sucesso!
          </Message>,
        );
      })
      .catch(() => {
        toaster.push(
          <Message
            closable
            showIcon
            header="Ocorreu um erro"
            type="error"
            duration={4000}
          >
            Erro ao desabilitar monitoramento
          </Message>,
        );
      })
      .finally(() => setLoading(false));
  };

  function setSettingsDefault() {
    setStep(0);
    setTerm(''),
      setFrequency('D'),
      setSendEmail(true),
      setSendNotification(true),
      setAllEntities(true),
      setSharedWith([]),
      setMonitoringWeekDay(null),
      setMonitoringMonthDay(null);
  }

  function groupArray(arr = [], groupBy) {
    if (!arr.length || !groupBy) return [];
    let groupedArray = [];
    let categories = {};

    arr?.forEach((elem) => {
      categories[elem[groupBy]] = {};
      categories[elem[groupBy]].label = elem[groupBy];
      categories[elem[groupBy]].value = elem[groupBy];
    });

    Object.keys(categories).forEach((category, i) => {
      groupedArray.push([]);
      arr.forEach((elem) =>
        elem[groupBy] === category
          ? groupedArray[i].push({ value: elem.id, label: elem.initials })
          : null,
      );
      categories[category].children = groupedArray[i];
    });
    return Object.values(categories);
  }

  const changeValue = (value = []) => {
    let entitiesFilter = [];
    if (value) {
      value.forEach((e) => {
        if (typeof e === 'string' && entities) {
          entities.forEach((entity) => {
            if (entity.class === e) {
              entitiesFilter.push(entity.id);
            }
          });
        } else {
          entitiesFilter.push(e);
        }
      });
      setSelectedEntities([...entitiesFilter]);
    }
  };

  function getDataMonitoring(monitor) {
    setTerm(monitor.query);
    setFrequency(monitor.frequency);
    setSendEmail(monitor.send_email);
    setSendNotification(monitor.send_notification);
    setAllEntities(monitor.all_entities);
    setSharedWith(monitor.shared_with);
    setSelectedEntities(monitor.filters?.entity);
    setMonitoringWeekDay(monitor.monitoring_week_day);
    setMonitoringMonthDay(monitor.monitoring_month_day);
    setDefaultOperator(monitor.filters.default_operator);
    setSynonymsFilter(monitor.filters.synonyms);
    setInteiroTeorFilter(monitor.filters.fields);
    setSingleFilter(monitor.filters.sort);
    setResultDecisionFilter(monitor.filters.filter_by_favorability);
    setFilterByVote(monitor.filters.filter_by_vote);
    setFilterBySecaoCARF(monitor.filters.filter_by_secao_carf);
    setFilterByOrgaoJulgadorSTF(monitor.filters.filter_by_orgao_julgador_stf);
  }

  function getDataMonitoringByURL() {
    const params = new URLSearchParams(search);
    const queryURL = params.get('query');
    const filters = JSON.parse(params.get('filters'));
    const fields = JSON.parse(params.get('fields'));
    const default_operator = params.get('default_operator');
    const synonyms = JSON.parse(params.get('synonyms'));

    setTerm(queryURL);
    setAllEntities(
      filters &&
        filters.entity &&
        filters.entity.length &&
        entities &&
        entities.length
        ? filters.entity.length === entities.length
        : false,
    );
    setSelectedEntities(
      filters &&
        filters.entity &&
        filters.entity.length &&
        entities &&
        entities.length
        ? filters.entity.length === entities.length
          ? null
          : filters.entity
        : [],
    );
    setInteiroTeorFilter(fields);
    setDefaultOperator(default_operator);
    setSynonymsFilter(synonyms);
    setSingleFilter(filters?.sort);
    setResultDecisionFilter(
      filters?.filter_by_favorability || resultDecisionFilter,
    );
    setFilterByVote(filters?.filter_by_vote || filterByVote);
    setFilterBySecaoCARF(filters?.filter_by_secao_carf || filterBySecaoCARF);
    setFilterByOrgaoJulgadorSTF(
      filters?.filter_by_orgao_julgador_stf || filterByOrgaoJulgadorSTF,
    );
  }

  const updTour = async () => {
    const check = JSON.parse(localStorage.getItem('checklist'));
    if (check.trail.monitoring) {
      return;
    } else {
      await updateCheckllist(check.id, 'monitoring');
    }
  };

  function verifyTermMonitoring() {
    const params = new URLSearchParams(search);
    const query = params.get('query');

    setLoading(true);

    requester.monitoring
      .verifyMonitoring(query)
      .then((response) => {
        setMonitoringId(response.id);
        if (response.id) {
          getMonitoringById(response.id);
        } else {
          getDataMonitoringByURL();
        }
      })
      .catch((error) => {
        console.error(error);
        getDataMonitoringByURL();
      })
      .finally(() => setLoading(false));
  }

  function getMonitoringById(monitoringId) {
    requester.monitoring
      .getMonitoringById(monitoringId)
      .then((response) => {
        getDataMonitoring(response);
      })
      .catch(console.log);
  }

  const onNext = () => {
    if (term.length < 3) {
      setStepStatus('error');
      setTermError(true);
      toaster.push(
        <Message
          header="Atenção"
          duration={4000}
          type="warning"
          showIcon
          closable
        >
          Seu monitoramento deve ter pelo menos três caracteres
        </Message>,
      );
    } else if (!allEntities && selectedEntities.length === 0) {
      toaster.push(
        <Message
          header="Atenção"
          duration={5000}
          type="warning"
          showIcon
          closable
        >
          Você deve selecionar ao menos um orgão
        </Message>,
      );
    } else {
      setStepStatus(stepStatus === 'error' ? null : '');
      setTermError(false);
      setStep(step + 1);
    }
  };

  const onPrevious = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    verifyTermMonitoring();
  }, [search]);

  return (
    <div className="flex items-center">
      <span className="mt-1 pr-2">Monitorar tema </span>
      <Toggle
        loading={loading}
        disabled={props.disabled}
        checked={Boolean(monitoringId)}
        onChange={Boolean(monitoringId) ? stopMonitoring : handleModal}
        className="mt-1 pr-3"
      />

      <Tag color={'primary'} size="sm" type="default" iconPosition="left">
        <Star1 size="14" color="#0069f5" />
        <span className="text-xs ml-2">Disponível para os TRTs</span>
      </Tag>

      <Modal size={'sm'} open={modal} onClose={() => handleModal(false)}>
        <Modal.Title
          style={{ marginBottom: 15, fontWeight: 700, fontSize: 20 }}
        >
          {Boolean(monitoringId)
            ? `Editar monitoramento:`
            : 'Novo monitoramento'}
        </Modal.Title>

        <Modal.Header>
          <Steps current={step}>
            <Steps.Item title="Configurações" />
            <Steps.Item title="Filtros" />
            <Steps.Item title="Compartilhar" />
          </Steps>
        </Modal.Header>

        <Modal.Body>
          {step === 0 ? (
            <div className="form-monitoring">
              <TermLabelMonitoring
                term={term}
                setTerm={setTerm}
                termError={termError}
              />

              <SelectFrequencyMonitoring
                frequency={frequency}
                monitoringWeekDay={monitoringWeekDay}
                monitoringMonthDay={monitoringMonthDay}
                setFrequency={setFrequency}
                setMonitoringWeekDay={setMonitoringWeekDay}
                setMonitoringMonthDay={setMonitoringMonthDay}
              />

              <ToggleEmailMonitoring
                sendEmail={sendEmail}
                setSendEmail={setSendEmail}
              />

              <ToggleSendNotificationMonitoring
                sendNotification={sendNotification}
                setSendNotification={setSendNotification}
              />

              <SelectEntitiesMonitoring
                setSelectedEntities={setSelectedEntities}
                setAllEntities={setAllEntities}
                allEntities={allEntities}
                groupArray={groupArray}
                selectedEntities={selectedEntities}
                changeValue={changeValue}
                entities={entities}
              />
            </div>
          ) : step === 1 ? (
            <Grid fluid>
              <Col sm={24} className="search-operators">
                <DefaultOperatorFilter
                  context={'Monitoring'}
                  defaultOperator={defaultOperator}
                  setDefaultOperator={setDefaultOperator}
                />

                <SynonymsFilter
                  context={'Monitoring'}
                  synonymsFilter={synonymsFilter}
                  setSynonymsFilter={setSynonymsFilter}
                />

                <InteiroTeorFilter
                  context={'Monitoring'}
                  inteiroTeorFilter={inteiroTeorFilter}
                  setInteiroTeorFilter={setInteiroTeorFilter}
                />

                <SingleFilter
                  context={'Monitoring'}
                  singleFilter={singleFilter}
                  setSingleFilter={setSingleFilter}
                  label="Ordenação de Resultados"
                  labelKey="name"
                  placeholder="Ordenação de Resultados"
                />
              </Col>
            </Grid>
          ) : (
            <SelectSharePeopleMonitoring
              sharePeople={sharedWith}
              setSharePeople={setSharedWith}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="create-monitoring-modal_footer">
            <Button
              className="create-monitoring-modal_btn-footer-back"
              onClick={onPrevious}
              disabled={step === 0}
            >
              Anterior
            </Button>
            {step > 1 ? (
              Boolean(monitoringId) ? null : (
                <Button loading={loading} onClick={createMonitoring}>
                  Criar Monitoramento
                </Button>
              )
            ) : (
              <Button
                className="create-monitoring-modal_btn-footer-next"
                onClick={onNext}
                disabled={step === 2}
              >
                Próximo
                <i className="ri-arrow-right-line btn-icon" />
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withRouter(ToggleMonitoring);
