import React from 'react';

const EmptyCollection = () => {
  return (
    <div className="empty-simgle-collection">
      <img
        alt="Coleção vazia"
        style={{ width: 300 }}
        src="/empty_collection.png"
      />
      <p>Você ainda não adicionou nenhum resultado à sua Coleção</p>
      <p style={{ fontWeight: 700 }}>
        Que tal fazer uma nova pesquisa sobre esse tema?
      </p>
    </div>
  );
};

export default EmptyCollection;
