import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Nav, Sidebar } from 'rsuite';
import { Sidenav as RSSidenav } from 'rsuite';
import './style.scss';
import { TURIVIUS_VERSION } from '../../app/App';
import { ArrowLeft2, ArrowRight2, ArrowCircleLeft } from 'iconsax-react';
import { Turivius } from '../../ui';

const Sidenav = ({
  defaultOpenKeys,
  handleSelectNavItem,
  header,
  footer,
  navItems,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [routeActive, setRouteActive] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.location.pathname === '/pesquisa/resultados') {
      setIsOpen(false);
    }
    setRouteActive(pathname);
  }, [pathname]);

  useEffect(() => {
    localStorage.setItem('menu_open', isOpen);
    window.dispatchEvent(new Event('menuOpen'));
  }, [isOpen]);

  return (
    <>
      {isOpen ? (
        <div className="contente-sidebar">
          <Sidebar className="turivius-sidebar">
            <RSSidenav
              defaultOpenKeys={defaultOpenKeys}
              expanded={true}
              onSelect={handleSelectNavItem}
              className="turivius-sidenav"
            >
              <Button
                className="hidden-menu"
                onClick={() => setIsOpen(!isOpen)}
              >
                <ArrowLeft2 color="#053AC8" />
              </Button>
              <RSSidenav.Header>
                <Button
                  onClick={() => header.onClick()}
                  className="sidenav-header_logo"
                >
                  <img src={header.img} alt="Logo Turivius" />
                </Button>
              </RSSidenav.Header>
              <RSSidenav.Body>
                <div className="sidenav-body">
                  {navItems?.map((item, index) => {
                    return (
                      <div key={index}>
                        <Nav>
                          {item.products?.map((i, index) => {
                            return i.show ? (
                              <Nav.Item
                                id={i.id}
                                key={index}
                                onClick={() => {
                                  i.onClick();
                                }}
                                className={
                                  i?.route === routeActive
                                    ? 'turivius-menu_option flex'
                                    : 'flex'
                                }
                              >
                                {i.icon} {i.name}
                              </Nav.Item>
                            ) : null;
                          })}
                        </Nav>
                      </div>
                    );
                  })}
                </div>
                <div className="sidenav-footer">
                  <Nav className="turivius-module">
                    {footer?.map((item, index) => {
                      return (
                        <Nav.Item
                          key={index}
                          onClick={() => {
                            item.onClick();
                          }}
                          className="nav-item_footer"
                        >
                          <p className="turivius-module_name flex items-center">
                            {item.icon}
                            {item.children}
                          </p>
                        </Nav.Item>
                      );
                    })}
                  </Nav>

                  <div className="text-white p-1 text-xs pt-5">
                    <a
                      className="flex items-center text-xs text-white"
                      href="https://old.turivius.com"
                    >
                      <ArrowCircleLeft
                        size="12"
                        color="#fff"
                        style={{ marginRight: '3px' }}
                      />
                      Voltar para a versão antiga
                    </a>{' '}
                    <br />v{TURIVIUS_VERSION}
                  </div>
                </div>
              </RSSidenav.Body>
            </RSSidenav>
          </Sidebar>
        </div>
      ) : (
        <div className="contente-sidebar close">
          <Sidebar className="turivius-sidebar">
            <RSSidenav
              defaultOpenKeys={defaultOpenKeys}
              expanded={true}
              onSelect={handleSelectNavItem}
              className="turivius-sidenav"
            >
              <Button
                className="hidden-menu-close"
                onClick={() => setIsOpen(!isOpen)}
              >
                <ArrowRight2 color="#053AC8" />
              </Button>
              <RSSidenav.Header className="close">
                <Button
                  onClick={() => header.onClick()}
                  className="sidenav-header_logo_close"
                >
                  <img src="/new_icon.png" alt="Logo Turivius" />
                </Button>
              </RSSidenav.Header>
              <RSSidenav.Body>
                <div className="sidenav-body">
                  {navItems?.map((item, index) => {
                    return (
                      <div key={index}>
                        <Nav>
                          {item.products?.map((i, index) => {
                            return i.show ? (
                              <Nav.Item
                                id={i.id}
                                key={index}
                                onClick={() => {
                                  i.onClick();
                                }}
                                className={
                                  i?.route === routeActive
                                    ? 'turivius-menu_option_close'
                                    : ''
                                }
                              >
                                <Turivius.TuriviusTooltip
                                  trigger={'hover'}
                                  message={i.name}
                                  placement={'right'}
                                >
                                  {i.icon}
                                </Turivius.TuriviusTooltip>
                              </Nav.Item>
                            ) : null;
                          })}
                        </Nav>
                      </div>
                    );
                  })}
                </div>
                <div className="sidenav-footer">
                  <Nav className="turivius-module">
                    {footer?.map((item, index) => {
                      return (
                        <Nav.Item
                          key={index}
                          onClick={() => {
                            item.onClick();
                          }}
                          className="nav-item_footer"
                        >
                          <Turivius.TuriviusTooltip
                            trigger={'hover'}
                            message={item.children}
                            placement={'right'}
                          >
                            <p className="turivius-module_name">{item.icon}</p>
                          </Turivius.TuriviusTooltip>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </div>
              </RSSidenav.Body>
            </RSSidenav>
          </Sidebar>
        </div>
      )}
    </>
  );
};
export default Sidenav;
