import { useEffect, useMemo, useState } from 'react';
import { ArrowCircleDown2, TrendUp } from 'iconsax-react';
import { toaster, Message } from 'rsuite';

import { withRouter } from 'react-router-dom';
import { posthog, requester } from '../../../../requesters';
import { Nav, Button } from '../../../../ds-components';

import SearchBarResults from '../../components/search/SearchBar/SearchBarResults';
import FiltersList from '../../components/results/FilterList/FilterList';
import ResultCardsContainer from '../../components/results/Cards/Result/ResultCardsContainer';
import ResultContainerLoader from '../../components/results/Cards/Result/ResultContainerLoader';
import OrderResults from '../../components/results/FilterList/Filters/OrderResults';
import SLAJurimetric from '../../../../components/organisms/ModalSLAJurimetric/SLAJurimetric';
import { updateCheckllist } from '../../../../services/checklist';
import NoResultsMessage from '../../components/results/NoResultsMessage';
import ToggleMonitoring from '../../../monitoring/components/Buttons/ToggleMonitoring';
import SuccessChecklistButton from '../../../../components/SuccessChecklist/SuccessChecklistButton';
import { useLocation } from 'react-router-dom';
import JurimetricsCenter from '../../components/results/SearchJurimetrics/JurimetricsCenter';
import { NotificationInteiroTeor } from '../../components/results/Cards/Result/NotificationInteiroTeor';
import { useCollections } from '../../../../store/ducks/theCollections';

const Results = (props) => {
  const [query, setQuery] = useState('');
  const [dataQuery, setDataQuery] = useState(null);
  const [requesting, setRequesting] = useState(true);
  useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [results, setResults] = useState([]);
  const [resultsTime, setResultsTime] = useState(null);
  const [page, setPage] = useState(0);
  const [data, setData] = useState({});
  const [requestingSearchJurimetrics, setRequestingSearchJurimetrics] =
    useState(false);
  const [searchPlots, setSearchPlots] = useState([]);
  const [entitiesMonitoring, setEntitiesMonitoring] = useState([]);
  const [noJurimetricEntities, setNoJurimetricEntities] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [bases, setBases] = useState(null);
  const [classified, setClassified] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [loadingJurimetria, setLoadingJurimetria] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isMob, setIsMob] = useState(false);
  const [
    theMatchResultsThatHaveCollections,
    setTheMatchResultsThatHaveCollections,
  ] = useState([]);

  const { collections: theCollections, fetch: fetchCollections } =
    useCollections();

  const location = useLocation();

  const verifyAndGetEntities = async () => {
    let { entities } = props;

    if (Object.keys(entities).length === 0 && entities.constructor === Object) {
      await requester.cards
        .getAllEntities()
        .then((response) => {
          entities = {};
          response.forEach((entity) => {
            entities[entity.id] = entity;
          });
          props.setEntities(entities);
        })
        .catch((err) => {
          console.error(err);
          toaster.push(
            <Message
              header="Ocorreu um erro"
              duration={4000}
              type="error"
              showIcon
              closable
            >
              Houve um problema de conexão, tente novamente
            </Message>,
          );
        });
    }
  };

  const searchPlotsFunc = async (data_query) => {
    let filters = JSON.parse(data_query.filters);
    if (filters.entity.length > 0) {
      setRequestingSearchJurimetrics(true);
      setTimeout(() => {
        props
          .getPlots(data_query, filters)
          .then((response) => {
            setSearchPlots(response);
          })
          .catch((err) => console.error(err))
          .finally(() => {
            setRequestingSearchJurimetrics(false);
          });
      }, 500);
    } else {
      setNoJurimetricEntities(true);
    }
  };

  const getResults = async (
    count,
    query,
    filterStr,
    inteiro_teor,
    default_operator,
    synonyms,
    n_acordao,
    n_processo,
    relator_ids,
    full_text,
  ) => {
    setRequesting(true);
    props.setEntitiesCounter({});
    props.setExternalFilter('filter_by_relator', []);

    let filtersFiltered = {};
    filtersFiltered = JSON.parse(filterStr);

    if (
      n_acordao ||
      n_processo ||
      (relator_ids && relator_ids.length > 0) ||
      full_text
    ) {
      const data = {
        query: '',
        page_n: page,
        filters: JSON.stringify(filtersFiltered),
        n_acordao,
        n_processo,
        relator: relator_ids,
        full_text,
      };

      let data_query = {};
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined) {
          data_query[key] = data[key];
        }
      });

      if (count) {
        props.getCountResults(data_query);
      }

      setData({ ...data_query });

      let startTime = new Date();

      props
        .search(data_query)
        .then((response) => {
          setResults(response.cards);
          setResultsTime(((new Date() - startTime) / 1000).toFixed(2));

          props.getVotes(response.cards?.map((c) => c.id));
          if (response.cards.length < 10) {
            setHasMore(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setResults([]);
        })
        .finally(() => {
          setRequesting(false);
        });
    } else if (query && query.length >= 3) {
      if (!filterStr || filterStr === 'null') {
        props.loadFilters();
        props.saveFilters(this.props.history, this.props.match);
      } else {
        props.justSaveFilters(JSON.stringify(filtersFiltered));

        const data_query = {
          query,
          page_n: page,
          fields: inteiro_teor,
          filters: JSON.stringify(filtersFiltered),
          default_operator,
          synonyms,
        };

        setDataQuery(data_query);

        if (count) {
          props.getCountResults(data_query);
        }

        setData({ ...data_query });

        let startTime = new Date();
        const relator_filter = [];

        props
          .search(data_query)
          .then(async (response) => {
            setResultsTime(((new Date() - startTime) / 1000).toFixed(2));

            posthog.capture('result_time', {
              result_time: ((new Date() - startTime) / 1000).toFixed(2),
            });

            if (response.cards.length < 10) {
              setHasMore(false);
            }

            props.getVotes(response.cards?.map((c) => c.id));
            setResults(response.cards);

            Object.keys(response.relatores)?.map((a, b) =>
              relator_filter.push({
                key: a,
                doc_count: response.relatores[a].doc_count,
                related_names: response.relatores[a].related_names.join(','),
              }),
            );
            await props.setExternalFilter('filter_by_relator', relator_filter);
          })
          .catch((err) => {
            console.error(err);
            setResults([]);
          })
          .finally(() => setRequesting(false));
      }
    } else {
      props.setEntitiesCounter({});
      props.setExternalFilter('filter_by_relator', []);
      setRequesting(false);
      setResults([]);
    }
  };

  const getLoadMoreResults = (data_query) => {
    setLoadingMore(true);

    if (!requesting) {
      props
        .loadMoreResults(data_query)
        .then((response) => {
          if (response.cards.length < 10) {
            setHasMore(false);
          }
          setResults([...results, ...response.cards]);
          setPage(data_query.page_n);
          setLoadingMore(false);
          props.getVotes(response.cards?.map((c) => c.id));
        })
        .catch((err) => {
          console.error(err);
          setTimeout(() => {
            getLoadMoreResults(data_query);
          }, 1000);
        });
    }
  };

  const loadMore = () => {
    let data_query = data;
    data_query.page_n = page + 1;
    getLoadMoreResults(data_query);
  };

  const doSearch = async (location) => {
    if (location.search !== '') {
      const params = new URLSearchParams(location.search);
      const query = params.get('query');
      const filters = params.get('filters');
      const inteiro_teor = params.get('fields');
      const default_operator = params.get('default_operator');
      const synonyms = params.get('synonyms');
      const n_acordao = params.get('n_acordao');
      const n_processo = params.get('n_processo');
      const relator_ids = params.getAll('relator');
      const full_text = params.get('full_text');

      setQuery(query);
      setHasMore(true);
      setPage(0);
      setSearchPlots([]);

      props.setQuery(query);
      props.setFilterStr(filters);
      if (!n_acordao && !n_processo && !relator_ids) {
        props.justSaveFilters(filters);
      }
      props.changeInteiroTeor(
        inteiro_teor !== 'null' ? inteiro_teor : ['ementa'],
      );
      props.changeDefaultOperator(
        default_operator !== 'null' && default_operator !== null
          ? default_operator
          : 'ou',
      );
      props.changeSynonyms(
        synonyms !== 'null' && synonyms !== null
          ? synonyms === 'true'
            ? true
            : false
          : false,
      );

      getResults(
        true,
        encodeURIComponent(query),
        filters,
        inteiro_teor !== 'null' ? inteiro_teor : ['ementa'],
        default_operator !== 'null' && default_operator !== null
          ? default_operator
          : 'ou',
        synonyms !== 'null' && synonyms !== null
          ? synonyms === 'true'
            ? true
            : false
          : false,
        n_acordao,
        n_processo,
        relator_ids,
        full_text,
      );
    }
  };

  const handleChangeNavigation = (index) => {
    setActiveIndex(index);
  };

  const getEntitiesToMonitoring = () => {
    requester.cards
      .getEntities()
      .then((res) => {
        setEntitiesMonitoring(res);
      })
      .catch((err) => {
        setEntitiesMonitoring([]);
      });
  };

  const handleResize = () => {
    const isMob2 = window.detectMob();
    if (isMob2 !== isMob) {
      setIsMob(isMob2);
    }
  };

  const fetchData = async () => {
    getBasesAndClassifedAndAcurracy();
  };

  const getBasesAndClassifedAndAcurracy = async () => {
    try {
      setLoadingJurimetria(true);
      const basesRes = await requester.slaJurimetric.getSLABases();
      const classifiedRes = await requester.slaJurimetric.getSLAClassified();
      const acurracyRes = await requester.slaJurimetric.getSLAAccuracy();
      setBases(basesRes);
      setClassified(classifiedRes);
      setAccuracy(acurracyRes);
      setLoadingJurimetria(false);
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const updTour = async () => {
    const check = JSON.parse(localStorage.getItem('checklist'));
    if (check.trail.jurimetric) {
      return;
    } else {
      await updateCheckllist(check.id, 'jurimetric');
    }
  };

  useMemo(async () => {
    if (!results.length) return;
    const resultIds = results?.map((result) => result.id);
    const response =
      await requester.collections.matchResultsThatHaveCollections(
        encodeURIComponent(JSON.stringify(resultIds)),
      );
    const theResponse = [...new Set(response)];
    setTheMatchResultsThatHaveCollections(theResponse);
  }, [theCollections, results]);

  useEffect(() => {
    doSearch(location);
  }, [location]);

  useEffect(() => {
    posthog.capture('$pageview');
    verifyAndGetEntities();
    getEntitiesToMonitoring();
    setIsMob(window.detectMob());
    window.addEventListener('resize', handleResize);

    fetchData();
    fetchCollections();
  }, []);

  return (
    <>
      {/*<SuccessChecklistButton page="jurimetric" />*/}
      <div className="resultpage">
        <div className="resultpage-header">
          <SearchBarResults
            id="search-bar"
            filtersProps={props.filtersRedux}
            segment={props.segment}
          />

          <div className="resultpage-header_sub mt-3">
            <div className="monitoring-term">
              <ToggleMonitoring
                entities={entitiesMonitoring}
                disabled={false}
                filtersProps={props.filtersRedux}
              />
            </div>
            <Nav
              navItems={[
                {
                  id: 1,
                  title: `Resultado da busca ${
                    !!props.countResults ? `(${props.countResults})` : ''
                  }`,
                },
                {
                  id: 2,
                  title: 'Jurimetria',
                },
              ]}
              activeTab={activeIndex}
              handleChange={(index) => {
                handleChangeNavigation(index);
                if (index === 2) {
                  updTour();
                }
              }}
            />
            <div style={{ position: 'absolute', right: '0' }}>
              {' '}
              {loadingJurimetria ? (
                'Carregando...'
              ) : (
                <Button
                  appearance="minimal"
                  size="sm"
                  icon={<TrendUp size="16" color="#84CAFF" />}
                  iconPosition="left"
                  onClick={() => setIsOpen(true)}
                >
                  Jurimetria transparente
                </Button>
              )}{' '}
            </div>
            <SLAJurimetric
              open={isOpen}
              handleClose={() => setIsOpen(false)}
              bases={bases}
              accuracy={accuracy}
              classified={classified}
            />
          </div>
        </div>
        <div className="resultpage-container">
          <FiltersList
            headerTitle="Filtros de Pesquisa"
            AllProps={props}
            tmpFilters={props.tmpFilters}
            filtersProps={props.filtersRedux}
            entities={props.entities}
          />

          <div className="resultpage-container_content">
            <div className="resultpage-content_results mt-4">
              <div className="tv-tabs">
                {requesting ? (
                  <ResultContainerLoader />
                ) : activeIndex === 1 && results.length > 0 && !requesting ? (
                  <>
                    <span className="resultpage-container_content-span">
                      Aproximadamente{' '}
                      <b className="font-semibold">{props.countResults}</b>{' '}
                      decisões e/ou normas encontrados em {resultsTime} segundos
                    </span>

                    <OrderResults
                      filtersProps={props.filtersRedux}
                      context="Results"
                      field_name="sort"
                    />

                    <ResultCardsContainer
                      query={query}
                      results={results}
                      validEntities={props.validEntities}
                      entities={props.entities}
                      loading={requesting}
                      theCollections={theCollections}
                      theMatchResultsThatHaveCollections={
                        theMatchResultsThatHaveCollections
                      }
                    />
                    {hasMore ? (
                      <div className="carregar-mais-button mt-5">
                        <Button
                          appearance="primary"
                          size="sm"
                          icon={<ArrowCircleDown2 size={16} color="#FFFFFF" />}
                          iconPosition="right"
                          onClick={() => loadMore()}
                        >
                          {loadingMore
                            ? 'Buscando mais resultados...'
                            : 'Carregar mais'}
                        </Button>
                      </div>
                    ) : null}
                  </>
                ) : activeIndex === 1 && results.length === 0 && !requesting ? (
                  <NoResultsMessage />
                ) : activeIndex === 2 ? (
                  <JurimetricsCenter
                    searchPlots={searchPlots}
                    requesting={requestingSearchJurimetrics}
                    entities={props.entities}
                    noJurimetricEntities={noJurimetricEntities}
                    countResults={props.countResults}
                    searchPlotsFunc={searchPlotsFunc}
                    dataQuery={dataQuery}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Results);
