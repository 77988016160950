import React from 'react';
import { Nav } from 'rsuite';
import './style.scss';

const CustomNav = ({ active, onSelect, items, ...props }) => {
  return (
    <Nav className="navbar" {...props} activeKey={active} onSelect={onSelect}>
      {items?.map((item) => (
        <Nav.Item eventKey={item.eventKey} active={item.active}>
          {item.title}
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default CustomNav;
