import { Notification } from 'iconsax-react';

const NotificationOpenButton = ({ searchPage, unread, onClick }) => {
  return (
    <div className="notification-wrapper">
      <div
        onClick={onClick}
        data-testid="open-notifications"
        className="notifications-unread"
        style={{ cursor: 'pointer', marginRight: searchPage ? 0 : 10 }}
      >
        {unread ? (
          <div className="badge-unread">
            <span>{unread > 99 ? '+99' : unread}</span>
          </div>
        ) : null}
        <Notification size="24" color="#0069F5" />
      </div>
    </div>
  );
};

export default NotificationOpenButton;
