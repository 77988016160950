import { useState } from 'react';
import { Message, toaster } from 'rsuite';
import Confetti from 'react-dom-confetti';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requester } from '../../../../../requesters';
import { postHubSpotEvent } from '../../../../../services/hubspot';
import { Creators as ProfilesActions } from '../../../../../store/ducks/profiles';
import vars from '../../../../../vars.module.scss';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { AddCircle, TickCircle } from 'iconsax-react';
import { useCollections } from '../../../../../store/ducks/theCollections';
import { toast } from 'react-toastify';

const config = {
  angle: 140,
  spread: 45,
  startVelocity: 45,
  elementCount: 100,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: '4px',
  height: '4px',
  colors: [
    vars.turivius,
    vars.secondary,
    vars.turivius_light,
    vars.secondary_light,
    vars.turivius_dark,
    vars.secondary_dark,
  ],
};

const AddCardToCollectionButton = (props) => {
  const { fetch } = useCollections();
  const [state, setState] = useState({
    isExploding: false,
    successAdd: false,
    loading: false,
    cards: [],
  });

  const verifyAndGetCompanyProfiles = () => {
    const {
      companiesProfiles,
      fetchingCompaniesProfiles,
      toggleFetchingCompanyProfiles,
      setCompanyProfiles,
    } = props;
    const company = localStorage.getItem('company');
    if (!companiesProfiles[company] && !fetchingCompaniesProfiles[company]) {
      toggleFetchingCompanyProfiles({ [company]: true });
      requester.profiles
        .getCompanyUserNames(company, '')
        .then((response) => {
          setCompanyProfiles({ [company]: response });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => toggleFetchingCompanyProfiles({ [company]: false }));
    }
  };

  const addCardToCollectionButton = (cards, collectionId) => {
    const cardIds = cards?.map((card) => card.id);
    setState({
      loading: true,
    });
    requester.collections
      .addCardsToCollection(
        localStorage.getItem('user'),
        collectionId,
        cardIds,
        props.context,
      )
      .then((response) => {
        fetch();
        postHubSpotEvent('app_addcardtocollection', {
          app_cardids: cardIds,
          app_colecaoid: collectionId,
        });
        if (props.companiesProfiles[localStorage.getItem('company')]) {
        } else {
          verifyAndGetCompanyProfiles();
        }
        setState({
          loading: false,
          successAdd: true,
          isExploding: true,
        });
        if (cardIds.length === 1)
          return toast.success(
            <span className="gap-6 flex items-center justify-between font-medium text-base leading-7 text-[#475467]">
              Adicionado à coleção
              <a
                href={`/colecoes/${collectionId}`}
                className="text-base font-medium leading-6 text-[#0069F5]"
              >
                Ver coleção
              </a>
            </span>,
            { style: { width: 390, right: 91 } },
          );
        toast.success('Adicionado às coleções');
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message header="Ocorreu um erro" type="error" showIcon closable>
            Houve um problema de conexão, tente novamente.
          </Message>,
          { duration: 4000 },
        );
      });
  };

  const { cards, collection } = props;
  const { successAdd, loading, isExploding } = state;

  return (
    <>
      <Confetti active={isExploding} config={config} />
      {!successAdd ? (
        loading ? (
          <SpinningLoader size="sm" color="gray" />
        ) : (
          <Button
            size="sm"
            appearance="secondary"
            icon={<AddCircle size={14} color="#475467" />}
            onClick={() => addCardToCollectionButton(cards, collection.id)}
          >
            Adicionar
          </Button>
        )
      ) : (
        <Button
          size="sm"
          appearance="success"
          icon={<TickCircle size={14} color="#FFFFFF" />}
        >
          Adicionado
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, ProfilesActions), dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCardToCollectionButton);
