import { ColorSwatch, InfoCircle } from 'iconsax-react';
import { Tooltip, Whisper } from 'rsuite';
import { ColorResult, SketchPicker } from 'react-color';
import { useCallback, useEffect, useState } from 'react';
import { cn } from '../../../../../../lib/utils';
import { useExportReports } from '../../../../../../store/ducks/exportReports';
import { toast } from 'react-toastify';

type ChooseColorProps = {
  main_color?: string
}

export const ChooseColor = ({ main_color }: ChooseColorProps) => {
  const [color, setColor] = useState(main_color || '');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const { report, setReport, error, setError } = useExportReports()

  const onChangeComplete = (color: ColorResult) => {
    setColor(color.hex);
    setError({main_color: false})
  };

  const handleShowColorPicker = () => {
    setShowColorPicker((previousState) => !previousState);
  };

  const handleCloseColorPicker = useCallback(() => {
    setShowColorPicker(false);
    if(color !== report?.main_color) {
      setReport({ main_color: color })
      toast.success('Cor alterada com sucesso')
    }
  }, [color, report]);

  useEffect(() => {
    if(main_color)
    setColor(main_color) 
  }, [main_color])

  return (
    <div className="flex">
      <div className='flex flex-col relative'>
      
      <div className='flex'>
      <ColorSwatch size={20} color="#0D91FE" />
      <span className="ml-3 mr-1">Escolher cor do relatório</span>
      <Whisper
        trigger="hover"
        placement="top"
        speaker={
          <Tooltip>
            Escolha a cor da capa e das páginas internas do seu relatório
          </Tooltip>
        }
      >
        <InfoCircle size="16" color="#0D91FE" />
      </Whisper>

      </div>
      {error.main_color && <span className='absolute -bottom-4 left-8 text-[10px] text-[#F04438]'>Escolha uma cor</span>}
      </div>

      {color ? (
        <div className="ml-auto gap-2 flex items-center">
          <span
            className="cursor-pointer text-[#0D91FE] font-semibold text-xs"
            onClick={handleShowColorPicker}
          >
            Trocar cor
          </span>

      <Whisper
        trigger="hover"
        placement="top"
        speaker={
          <Tooltip>
          Cor selecionada
          </Tooltip>
        }
      >
          <div
            className={cn(
              'w-[26px] h-[26px] rounded border border-solid border-[#D0D5DD] cursor-pointer',
              'hover:border-blue-300',
            )}
            style={{ backgroundColor: color ? color : 'white' }}
            onClick={handleShowColorPicker}
          />
      </Whisper>

        </div>
      ) : (
        <span
          className="ml-auto cursor-pointer text-[#0D91FE] font-semibold text-xs"
          onClick={handleShowColorPicker}
        >
          Escolher cor
        </span>
      )}

      {showColorPicker && (
        <div className="absolute z-[2]">
          <div className="fixed inset-0" onClick={handleCloseColorPicker} />
          <SketchPicker
            color={color}
            onChangeComplete={onChangeComplete}
            className="absolute"
          />
        </div>
      )}
    </div>
  );
};
