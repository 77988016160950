import React from 'react';
import { Button, Modal } from 'rsuite';
import { Turivius } from '../../../../../ui';
import Comment from './Comments';
import RichTextEditor from 'react-rte';
import { connect } from 'react-redux';
import { requester } from '../../../../../requesters';
import { postHubSpotEvent } from '../../../../../services/hubspot';
import '../../style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Negrito', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Itálico', style: 'ITALIC' },
    { label: 'Sublinhado', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'H1', style: 'header-four' },
    { label: 'H2', style: 'header-five' },
    { label: 'H3', style: 'header-six' },
    { label: 'Citação', style: 'blockquote' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Lista Não Ordenada', style: 'unordered-list-item' },
    { label: 'Lista Ordenada', style: 'ordered-list-item' },
  ],
};

class CommentsCard extends React.Component {
  state = {
    value: RichTextEditor.createEmptyValue(),
    comments: [],
    requesting: false,
    preview: false,
    open: false,
  };

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString('html'));
    }
  };

  addComment = () => {
    this.setState({ requesting: true });
    let text = this.state.value.toString('html');
    requester.collections
      .addCollectionComment(
        localStorage.getItem('user'),
        this.props.collectionId,
        text,
        this.props.card.id,
      )
      .then((response) => {
        this.setState({ value: RichTextEditor.createEmptyValue() });
        // notifyCommentOnCollection(
        //   this.props.collection,
        //   this.props.companiesProfiles[localStorage.getItem("company")]
        // );
        this.props.addComment(this.props.card.id, response.card_comment);
        postHubSpotEvent('app_comentariocolecao', {
          app_comentariocolecao: response.card_comment.text,
          app_comentariocolecao_id: response.card_comment.board,
        });
      })
      .catch(console.error)
      .finally(() => this.setState({ requesting: false }));
  };

  removeComment = (commentId, callback) => {
    this.setState({ requesting: true });
    requester.collections
      .removeCollectionComment(this.props.collectionId, commentId)
      .then((response) => {
        this.props.removeComment(this.props.card.id, commentId);
        if (callback) {
          callback();
        }
      })
      .catch(console.error)
      .finally(() => this.setState({ requesting: false }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { open, value, requesting } = this.state;
    const {
      card,
      isMobile,
      comments,
      requestingComments,
      fetchingCompaniesProfiles,
      companiesProfiles,
    } = this.props;
    return (
      <>
        <Modal open={open} onClose={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Comentários de {card.nome}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="comments-card embedded">
              {requesting ? <Turivius.SimplePreloader /> : null}
              {requestingComments ||
              fetchingCompaniesProfiles[localStorage.getItem('company')] ? (
                <Turivius.SimplePreloader />
              ) : (
                <Comment
                  companiesProfiles={companiesProfiles}
                  comments={comments}
                  removeComment={this.removeComment}
                />
              )}
              <RichTextEditor
                placeholder="Deixe um comentário aqui"
                toolbarConfig={toolbarConfig}
                value={value}
                onChange={this.onChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} appearance="subtle">
              Cancel
            </Button>
            <Button
              appearance="primary"
              onClick={this.addComment}
              loading={requesting}
            >
              Comentar
            </Button>
          </Modal.Footer>
        </Modal>
        {!isMobile ? (
          <div className="comments-card">
            <div
              onClick={this.handleOpen}
              className="comments-card-open-modal-icon"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </div>
            {requesting ? <Turivius.SimplePreloader /> : null}
            {requestingComments ||
            fetchingCompaniesProfiles[localStorage.getItem('company')] ? (
              <Turivius.SimplePreloader />
            ) : (
              <Comment
                companiesProfiles={companiesProfiles}
                comments={comments}
                removeComment={this.removeComment}
              />
            )}
            {!open ? (
              <RichTextEditor
                placeholder="Deixe um comentário aqui"
                toolbarConfig={toolbarConfig}
                value={value}
                onChange={this.onChange}
              />
            ) : (
              <div className="RichTextEditor__root___2QXK-">
                <div className="EditorToolbar__root___3_Aqz">
                  <div className="ButtonGroup__root___3lEAn">
                    <div className="ButtonWrap__root___1EO_R">
                      <button
                        type="button"
                        title="Negrito"
                        role="switch"
                        aria-checked="false"
                        className="custom-css-class IconButton__root___3tqZW Button__root___1gz0c"
                      >
                        <span className="IconButton__icon-bold___2zl9t IconButton__icon___3YgOS"></span>
                      </button>
                    </div>
                    <div className="ButtonWrap__root___1EO_R">
                      <button
                        type="button"
                        title="Itálico"
                        role="switch"
                        aria-checked="false"
                        className="IconButton__root___3tqZW Button__root___1gz0c"
                      >
                        <span className="IconButton__icon-italic___2hHzc IconButton__icon___3YgOS"></span>
                      </button>
                    </div>
                    <div className="ButtonWrap__root___1EO_R">
                      <button
                        type="button"
                        title="Sublinhado"
                        role="switch"
                        aria-checked="false"
                        className="IconButton__root___3tqZW Button__root___1gz0c"
                      >
                        <span className="IconButton__icon-underline___2EmZJ IconButton__icon___3YgOS"></span>
                      </button>
                    </div>
                  </div>
                  <div className="ButtonGroup__root___3lEAn">
                    <div className="ButtonWrap__root___1EO_R">
                      <button
                        type="button"
                        title="Lista Não Ordenada"
                        role="switch"
                        aria-checked="false"
                        className="IconButton__root___3tqZW Button__root___1gz0c"
                      >
                        <span className="IconButton__icon-unordered-list-item___Pvkrr IconButton__icon___3YgOS"></span>
                      </button>
                    </div>
                    <div className="ButtonWrap__root___1EO_R">
                      <button
                        type="button"
                        title="Lista Ordenada"
                        role="switch"
                        aria-checked="false"
                        className="IconButton__root___3tqZW Button__root___1gz0c"
                      >
                        <span className="IconButton__icon-ordered-list-item___2rzD0 IconButton__icon___3YgOS"></span>
                      </button>
                    </div>
                  </div>
                  <div className="ButtonGroup__root___3lEAn">
                    <span className="Dropdown__root___3ALmx" title="Normal">
                      <select aria-label="Block type">
                        <option value="unstyled">Normal</option>
                        <option value="header-four">H1</option>
                        <option value="header-five">H2</option>
                        <option value="header-six">H3</option>
                        <option value="blockquote">Citação</option>
                      </select>
                      <span className="Dropdown__value___34Py9">Normal</span>
                    </span>
                  </div>
                </div>
                <div className="RichTextEditor__editor___1QqIU">
                  <div className="DraftEditor-root">
                    <div className="public-DraftEditorPlaceholder-root">
                      <div
                        className="public-DraftEditorPlaceholder-inner"
                        id="placeholder-2v3hh"
                      >
                        Deixe um comentário aqui
                      </div>
                    </div>
                    <div className="DraftEditor-editorContainer">
                      <div
                        aria-describedby="placeholder-2v3hh"
                        aria-label="Deixe um comentário aqui"
                        className="notranslate public-DraftEditor-content"
                        contenteditable="true"
                        role="textbox"
                        spellcheck="true"
                        style={{
                          outline: 'none',
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word',
                        }}
                      >
                        <div data-contents="true">
                          <div
                            className="RichTextEditor__block___2Vs_D RichTextEditor__paragraph___3NTf9"
                            data-block="true"
                            data-editor="2v3hh"
                            data-offset-key="c9hgc-0-0"
                          >
                            <div
                              data-offset-key="c9hgc-0-0"
                              className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"
                            >
                              <span data-offset-key="c9hgc-0-0"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="comments-card-action-buttons">
              <Button
                appearance="primary"
                onClick={this.addComment}
                loading={requesting}
              >
                Comentar
              </Button>
            </div>
          </div>
        ) : (
          <div
            id={`mobile-open-comment-modal-${card.id}`}
            onClick={this.handleOpen}
            className="comments-card-open-modal-icon"
          ></div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

export default connect(mapStateToProps, null)(CommentsCard);
