import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Turivius } from '../../ui';
import { Col, Grid, Row } from 'rsuite';

export const ResultSkeleton = ({ mobile, mdSize, mdOffset }) => {
  return (
    <div>
      <Col xs={24} sm={24} md={mdSize} mdOffset={mobile || !mdOffset ? 0 : 6}>
        <Turivius.TuriviusPanel
          className={'hoverable result-card'}
          style={{ zIndex: 0 }}
        >
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
        </Turivius.TuriviusPanel>
      </Col>
      <Col xs={24} sm={24} md={mdSize} mdOffset={mobile || !mdOffset ? 0 : 6}>
        <Turivius.TuriviusPanel
          className={'hoverable result-card'}
          style={{ zIndex: 0 }}
        >
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
        </Turivius.TuriviusPanel>
      </Col>
      {Array.from(Array(4).keys())?.map((i) => (
        <Col
          key={i}
          xs={24}
          sm={24}
          md={mdSize}
          mdOffset={mobile || !mdOffset ? 0 : 6}
        >
          <Turivius.TuriviusPanel style={{ height: 336, margin: '10px auto' }}>
            <div
              style={{
                width: '100%',
                height: '110px',
                display: 'flex',
                marginBottom: 10,
              }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={100}
                height={100}
                style={{ margin: '10px' }}
              />
              <div style={{ width: 'calc(100% - 110px)' }}>
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
              </div>
            </div>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: 130, marginRight: '2%' }}
            />
            <div style={{ width: '100%', height: 130, display: 'flex' }}>
              <Skeleton
                animation="wave"
                width="18%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
            </div>
          </Turivius.TuriviusPanel>
        </Col>
      ))}
    </div>
  );
};

export const FiltersSkeleton = () => {
  return (
    <div className="sidebar-filters-list">
      <Grid fluid>
        <Row className="sidebar-filters-list-body">
          <Col xs={24} sm={24}>
            <Skeleton
              animation="wave"
              height={50}
              width="95%"
              style={{ margin: 'auto', marginBottom: 6 }}
            />
          </Col>
          {Array.from(Array(10).keys())?.map((i) => (
            <Col key={i} xs={24} sm={24}>
              <div style={{ width: '95%', height: '100px', margin: 'auto' }}>
                <Skeleton
                  animation="wave"
                  height={20}
                  width="100%"
                  style={{ margin: 'auto', marginBottom: 0 }}
                />
                <Skeleton
                  animation="wave"
                  height={80}
                  width="100%"
                  style={{ margin: 'auto', marginTop: -15 }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <Row className="sidebar-filters-list-footer">
          <Col xs={12} sm={12}>
            <Skeleton
              animation="wave"
              height={50}
              width="100%"
              style={{ margin: 'auto' }}
            />
          </Col>
          <Col xs={12} sm={12}>
            <Skeleton
              animation="wave"
              height={50}
              width="100%"
              style={{ margin: 'auto' }}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export const SingleCardSkeleton = () => {
  return (
    <>
      {Array.from(Array(1).keys())?.map((i) => (
        <Col key={i} xs={24} sm={24}>
          <Turivius.TuriviusPanel style={{ height: 336, margin: '10px auto' }}>
            <div
              style={{
                width: '100%',
                height: '110px',
                display: 'flex',
                marginBottom: 10,
              }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={100}
                height={100}
                style={{ margin: '10px' }}
              />
              <div style={{ width: 'calc(100% - 110px)' }}>
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
              </div>
            </div>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: 130, marginRight: '2%' }}
            />
            <div style={{ width: '100%', height: 130, display: 'flex' }}>
              <Skeleton
                animation="wave"
                width="18%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
            </div>
          </Turivius.TuriviusPanel>
        </Col>
      ))}
    </>
  );
};

export const CollectionSkeleton = () => {
  return (
    <>
      {Array.from(Array(4).keys())?.map((i) => (
        <Col key={i} xs={24} sm={24}>
          <Turivius.TuriviusPanel style={{ height: 336, margin: '10px auto' }}>
            <div
              style={{
                width: '100%',
                height: '110px',
                display: 'flex',
                marginBottom: 10,
              }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={100}
                height={100}
                style={{ margin: '10px' }}
              />
              <div style={{ width: 'calc(100% - 110px)' }}>
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
              </div>
            </div>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: 130, marginRight: '2%' }}
            />
            <div style={{ width: '100%', height: 130, display: 'flex' }}>
              <Skeleton
                animation="wave"
                width="18%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
            </div>
          </Turivius.TuriviusPanel>
        </Col>
      ))}
    </>
  );
};
