import { Button, Card } from '../../../../../ds-components';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { hotTopics } from '../../../../../config/hot-topics';
import { useState, useEffect } from 'react';

const HotTopicsCard = () => {
  const [pagination, setPagination] = useState(1);
  const [topics, setTopics] = useState([]);

  const middleItem = Math.ceil(hotTopics.length / 2);

  useEffect(() => {
    setTopics(hotTopics.slice(0, middleItem));
  }, []);
  return (
    <Card title="Assuntos em alta">
      <div className="hot-topics">
        {topics?.map((topic, index) => {
          return (
            <div
              className="hot-topics_content"
              key={index}
              onClick={() => window.open(topic.url, '_blank')}
            >
              <div className="tv-badge tv-badge-circle_md tv-badge-primary font-semibold badge-hot">
                {topic.id}
              </div>
              <p className="font-semibold ml-2">{topic.name}</p>
            </div>
          );
        })}
        <div className="hot-topics_footer">
          <span>Página {pagination} de 2</span>
          <Button
            appearance="secondary"
            size="sm"
            icon={<ArrowLeft2 color="#667085" size={16} />}
            onClick={() => {
              setTopics(hotTopics.slice(0, middleItem));
              setPagination(1);
            }}
          >
            Anterior
          </Button>
          <Button
            appearance="secondary"
            size="sm"
            icon={<ArrowRight2 color="#667085" size={16} />}
            onClick={() => {
              setTopics(hotTopics.slice(middleItem));
              setPagination(2);
            }}
          >
            Próxima
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default HotTopicsCard;
