import { useEffect } from 'react';
import { ResultCard } from './ResultCard';
import ResultCardLoader from './ResultCardLoader';

const ResultCardsContainer = ({
  entities,
  results,
  loading,
  query,
  monitoring,
  theCollections,
  theMatchResultsThatHaveCollections,
}) => {
  const getEntityById = (entityId) => {
    const entitiesArr = Object.values(entities);
    const entity = entitiesArr.find((entity) => entity.id === entityId);
    return entity?.initials;
  };

  const formatCardsValue = () => {
    const formattedCards = [];

    results?.map((card) => {
      formattedCards.push({
        id: card.id,
        entity: {
          id: card.entity,
          name: getEntityById(Number(card.entity)),
        },
        name: card.nome,
        relator: {
          id: card.relator_id,
          name: card.relator,
        },
        dataPub: card.data_pub,
        dataJul: card.data_jul,
        orgaoJulgador: card.orgao_julgador,
        content: card.content,
        url: card.url,
        chips: card.chips.length === 0 ? null : card.chips,
        timeline: card.timeline,
        sumarizable: card.sumarizable,
      });
    });
    return formattedCards;
  };

  useEffect(() => {
    formatCardsValue();
  }, []);

  return loading ? (
    <div className="result-container">
      <ResultCardLoader />
    </div>
  ) : (
    <div className="gap-4 flex flex-col overflow-y-auto max-h-auto py-2 px-8">
      {formatCardsValue()?.map((card) => {
        return (
          <ResultCard
            card={card}
            key={card.id}
            query={query}
            monitoring={monitoring}
            theCollections={theCollections}
            theMatchResultsThatHaveCollections={
              theMatchResultsThatHaveCollections
            }
          />
        );
      })}
    </div>
  );
};

export default ResultCardsContainer;
