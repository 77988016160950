import React from 'react';

export type SpinningLoaderProps = {
  color:
    | 'primary'
    | 'white'
    | 'gray'
    | 'success'
    | 'danger'
    | 'secondary'
    | 'minimal';
  size: 'sm' | 'md' | 'lg';
  className?: string;
};

export const SpinningLoader: React.FC<SpinningLoaderProps> = ({
  color,
  size,
  className,
}) => {
  return (
    <div className={`spinner-container ${className ? `${className}` : ''}`}>
      <div className="spinner" role="status">
        <div className={`spinner-icon_${color} spinner-icon_${size}`}></div>
      </div>
    </div>
  );
};

export default SpinningLoader;
