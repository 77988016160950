import { useUploadLogoUrl } from '../../../../../../api/reports';
import { useExportReports } from '../../../../../../store/ducks/exportReports';
import { Image, InfoCircle } from 'iconsax-react';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip, Whisper, Popover } from 'rsuite';

type ChooseLogoProps = {
  logo_url?: string
}

const FIVE_MB_MAX_FILE_SIZE = 5 * 1024 * 1024;
const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

export const ChooseLogo = ({logo_url}: ChooseLogoProps) => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const board_id = useMemo(() => Number(collectionId), [collectionId]);
  const {report, setReport, error, setError} = useExportReports()
  const { mutateAsync } = useUploadLogoUrl()

  const fileRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState(logo_url || '');
  const [errorUpload, setErrorUpload] = useState(false);

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setErrorUpload(false)
    try {
      if (event.target.files?.length) {
        const file = event.target.files[0];
        const imagePreview = URL.createObjectURL(file)
        let img = document.createElement('img')
        img.src = imagePreview
        img.onload = async () => {
          if(img.width < 250) {
            event.target.value = ''
            setError({logo_url: !preview || report?.logo_url === ''})
            return toast.error('A imagem precisa ter no mínimo 250px de largura.')
          }
          if(file.size > FIVE_MB_MAX_FILE_SIZE) return toast.error('A imagem precisa ter no máximo 5mb.')
          if(!allowedFileTypes.includes(file.type)) return toast.error('Aceitamos nos formatos: png, jpeg e jpg')
          handleFileChange(file)
          await mutateAsync({ board_id, file })
        }
      }
    } catch (error) {
      setErrorUpload(true)
    }
  };

  const handleFileChange = (file: File) => {
    setReport({file})
    setError({logo_url: false})
    setPreview(URL.createObjectURL(file));
  }

  useEffect(() => {
    if(report?.logo_url) {
      setPreview(report.logo_url)
    }
  }, [report?.logo_url])

  useEffect(() => {
    if(report?.file) {
      handleFileChange(report.file)
    }
  }, [])

  return (
    <div className="flex">
      <div className='flex flex-col relative'>
      
      <div className='flex'>
        <Image size={20} color="#0D91FE" />
        <span className="ml-3 mr-1">Incluir logo do escritório</span>
        <Whisper
          trigger="hover"
          placement="top"
          speaker={
            <Tooltip>
              Você poe enviar imagens em x tamanho nos formatos x. O logo aparece
              na capa e nas páginas internas
            </Tooltip>
          }
        >
          <InfoCircle size="16" color="#0D91FE" />
        </Whisper>
      </div>
      {error.logo_url && <span className='absolute -bottom-4 left-8 text-[10px] text-[#F04438]'>Envie sua logo</span>}
      </div>

      {(preview && !errorUpload) && (
      <div className='gap-2 flex ml-auto'>
        <Whisper
          trigger="hover"
          placement="top"
          speaker={
            <Popover className="w-40 h-40 border border-solid border-gray-300 bg-white">
            <div className='w-full h-32 p-2 flex justify-center items-center'>
              <a href={preview} target="_blank" rel="noreferrer">
                <img
                  className="w-full object-contain"
                  src={preview}
                />
              </a>
            </div>
            </Popover>
          }
        >
          <span
            className="ml-auto cursor-pointer text-[#F79009] font-semibold text-xs"
            onClick={() => fileRef.current?.click()}
          >
            Trocar logo
          </span>

        </Whisper>
          <span
            className="ml-auto cursor-pointer text-[#0D91FE] font-semibold text-xs"
            onClick={() => window.open(preview, '_blank')}
          >
            Ver 
          </span>
      </div>
      )}  

      {(!preview && !errorUpload) && (
        <span
          className="ml-auto cursor-pointer text-[#0D91FE] font-semibold text-xs"
          onClick={() => fileRef.current?.click()}
        >
          Enviar logo
        </span>
      )}

      <input
        ref={fileRef}
        type="file"
        onChange={e => onChange(e)}
        hidden
        accept="image/png, image/jpeg, image/jpg"
        
      />
    </div>
  );
};
